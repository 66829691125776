import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './global/global.css'
// import vuetify from './plugins/vuetify'
import lodash from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import qs from 'qs'
import axiosIns from './global/request'
import Event from './global/event'
import Vuetify from 'vuetify/lib'
Vue.use(Vuetify)
const vuetify = new Vuetify()

import Toast from './components/Toast'
import Loading from './components/Loading'
import Dialog from './components/Dialog'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import 'vue-plyr/dist/vue-plyr.css'
import VuePlyr from 'vue-plyr'
import * as echarts from 'echarts'
import VueEcharts from 'vue-echarts'

Vue.config.productionTip = false

Vue.prototype.$globalData = {
  static_url: process.env.VUE_APP_STATIC_URL,
  api_url: process.env.VUE_APP_API_URL,
  ws_url: process.env.VUE_APP_WS_URL
}

Vue.prototype.$uuid = uuidv4
Vue.prototype.$_ = lodash
moment.locale('zh-cn')
Vue.prototype.$moment = moment
Vue.prototype.$qs = qs
Vue.prototype.$axios = axiosIns
// Vue.prototype.$bus = new Vue()
Vue.prototype.$bus = new Event()
Vue.prototype.$ws = ''


Vue.prototype.$vuetify = vuetify.framework
Vue.use(Toast)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Viewer)
Vue.use(VuePlyr)
Vue.component('v-chart', VueEcharts)

Viewer.setDefaults({
  zIndexInline: 99
})

router.beforeEach((to, from, next) => {
  console.log("beforeEach", from, to);
  if (to.path != '/' && to.path != '/login') {
    localStorage.setItem('routerPath', to.path)
  }
  next()
})

let token = localStorage.getItem('token')
if (token) {
  let tokenObj = JSON.parse(token)
  axiosIns.defaults.headers.common['Authorization'] = `${tokenObj.token_type} ${tokenObj.access_token}`
  store.commit('token', JSON.parse(token))
  router.push({
    path: '/'
  })
}

// let userInfo = localStorage.getItem('userInfo');
// if (userInfo) {
//   store.commit('userInfo', JSON.parse(userInfo));
// }

// let permission = localStorage.getItem('permission');
// if (permission) {
//   store.commit('permission', JSON.parse(permission));
// }

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(`${process.env.VUE_APP_STATIC_URL}/sw2.js`)
    .then(() => { console.log('Service Worker Registered'); 
    })
} else {
  console.log('Service Worker Not Supported');
}

window.addEventListener('beforeinstallprompt', (e) => {
  console.log('beforeinstallprompt', e);
  e.preventDefault()
  deferredPrompt = e
  deferredPrompt.prompt()
  deferredPrompt.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === 'accepted') {
      console.log('点击添加');
    } else {
      console.log('取消添加');
    }
    deferredPrompt = null
  })
})

window.addEventListener('appinstalled', (evt) => {
  console.log('应用安装');
})

console.log(process.env)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
