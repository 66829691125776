<template>
  <v-container class="com-box" fluid>
    <v-dialog v-model="isShow" max-width="300" persistent>
      <v-card class="dialog-box">
        <v-card-text class="content">{{ content }}</v-card-text>
        <v-card-actions class="btn-box">
          <v-btn color="green" @click="onClickBtn('ok')" text>确定</v-btn>
          <v-btn color="orange" @click="onClickBtn('cancel')" text>取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style scoped>
.com-box {
  position: absolute;
}

.dialog-box .content {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-size: 16px;
  color: #212b36;
}

.dialog-box .btn-box {
  display: flex;
  justify-content: center;
}
</style>
<script>
export default {
  name: 'Dialog',
  data: () => ({
    isShow: false,
    content: '',
    callback: ''
  }),
  methods: {
    onClickBtn(e) {
      this.callback && this.callback(e);
      this.close();
    },
    show(opt) {
      this._create();
      opt = opt || {};
      console.log('opt', opt)
      this.content = opt['content'] || '';
      this.callback = opt['callback'] || '';
      // this.isShow = false;
      this.isShow = true;
    },
    close() {
      console.log('关闭事件回调')
      this.isShow = false;
      console.log('isShow',this.isShow)
      this.content = '';
      this._destory();
    }
  }
}
</script>
