<template>
  <v-container class="com-box" fluid>
    <v-overlay :value="isShow">
      <div class="loading-box">
        <v-progress-circular indeterminate size="50"></v-progress-circular>
        <span v-if="content" class="title2">{{ content }}</span>
      </div>
    </v-overlay>
  </v-container>
</template>
<style scoped>
.com-box {
  position: absolute;
  z-index: 990;
}

.loading-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-box .title2 {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
}
</style>
<script>
export default {
  name: 'Loading',
  data: () => ({
    isShow: false,
    content: ''
  }),
  methods: {
    show(opt) {
      opt = opt || {};
      this.content = opt['content'] || '';
      this._create();
      this.isShow = true;
    },
    close() {
      this.isShow = false;
      this._destory();
      this.content = '';
    }
  }
}
</script>
