<template>
  <v-container class="com-box" fluid>
    <v-dialog v-model="isShow" max-width="840">
      <div class="btn_ax">
        <div class="head_tile">设置头像</div>
        <div class="head_item">
          <div class="cp_box">
            <div class="cpar_wk">
              <div v-if="!option.img" class="headMsg">请选择头像图片</div>
              <VueCropper v-if="option.img"
                          ref="cropper"
                          @realTime="autoImgae"
                          :autoCrop="true"
                          :img="option.img"
                          :centerBox="option.centerBox"
                          :outputSize="option.size"
                          :fixed="option.fixed"
                          :fixedBox="option.fixedBox"
                          :fixedNumber="option.fixedNumber"
                          :outputType="option.outputType"></VueCropper>
            </div>
            <div class="file">
              <input class="inmtx" type="file" accept="image/*" @change="changeFile" multiple="false" />
              选择图片
            </div>

          </div>
          <div class="view_box">
            <img v-show="imgSrc" style="width: 100%;height: 100%;" :src="imgSrc" />
          </div>
          <!-- <div class="view_box" v-html="imgSrc"></div> -->

        </div>
        <div class="btn_wk">
          <v-btn color="orange" style="margin-left: auto;" @click="submitImg" text>保存</v-btn>
          <v-btn color="red" style="margin-right: 30px;" @click="close" text>取消</v-btn>
        </div>

      </div>
    </v-dialog>
  </v-container>
</template>
<script>
import { uploadFile } from '../../global/api'
import { VueCropper } from 'vue-cropper'
export default {
  components: {
    VueCropper
  },
  data () {
    return {
      isShow: false,
      imgSrc: '',
      invCap: null,//防抖定时器
      lock: false,
      option: {
        img: '',
        size: 1,
        outputType: 'jpeg',
        fixedNumber: [1, 1],
        centerBox: true,
        fixedBox: false,
        fixed: true
      }
    }
  },
  methods: {
    show () {
      this.isShow = true
      // if (this.$store.state.userInfo.avatar) {
      //   this.option.img = this.$store.state.userInfo.avatar
      //   this.imgSrc = this.$store.state.userInfo.avatar
      // }
    },
    changeFile (e) {
      const file = e.target.files[0]
      this.option.img = URL.createObjectURL(file)
    },
    submitImg () {
      if (this.lock) {
        return
      }
      this.lock = true
      this.$refs.cropper.getCropBlob((data) => {
        uploadFile(data, 'avatar', res => {
          if (res.code == 0) {
            let userObj = JSON.parse(JSON.stringify(this.$store.state.userInfo))
            userObj.avatar = res.data.pic
            this.$store.commit('userInfo', userObj)
            this.$toast().show({
              content: res.msg
            })
          } else {
            this.$toast().show({
              content: res.msg
            })
          }
          this.lock = false
        }, err => {
          this.$toast().show({
            content: '头像上传失败'
          })
          this.lock = false
        })
      })
    },
    autoImgae (e) {
      clearInterval(this.invCap)
      this.invCap = setTimeout(() => {
        this.$refs.cropper.getCropBlob((data) => {
          this.imgSrc = URL.createObjectURL(data)
        })
      }, 1000)
      // this.imgSrc = e.html
    },
    close () {
      this.isShow = false
      this.imgSrc = ''
      this.option = {
        img: '',
        size: 1,
        outputType: 'jpeg',
        fixedNumber: [1, 1],
        centerBox: true,
        fixedBox: false,
        fixed: true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btn_ax {
  width: 840px;
  background-color: #fff;

  .head_tile {
    height: 40px;
    line-height: 40px;
    text-indent: 20px;
    border-bottom: 1px solid #ddd;
  }

  .head_item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 40px;

    .cp_box {
      width: 400px;
      height: 400px;

      .cpar_wk {
        width: 400px;
        height: 400px;
        display: flex;
        align-items: center;

        .headMsg {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ddd;
        }
      }

      .file {
        margin: 20px auto;
        width: 200px;
        height: 40px;
        background-color: #6773df;
        cursor: pointer;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        color: #fff;
        position: relative;

        &:hover {
          background-color: lighten($color: #6773df, $amount: 10%)
        }

        .inmtx {
          // cursor: pointer;
          position: absolute;
          top: 0;
          background-color: red;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }

    .view_box {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ddd;
      height: 200px;
      width: 200px;
    }
  }

  .btn_wk {
    border-top: 1px solid #ddd;
    display: flex;
    width: 100%;
    margin-top: 60px;
    padding: 20px 0;
  }
}
</style>