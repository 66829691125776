<template>
    <v-container class="com-box" fluid>
        <v-row align="center">
            <v-dialog v-model="isShow" max-width="1280">
                <v-card v-if="type == 0" class="dialog-box">
                    <v-card-title class="title2">{{ title }}</v-card-title>
                    <v-container fluid>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">标题</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <v-text-field v-model="ext.title" placeholder="请输入标题" solo flat hide-details outlined
                                    dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">排序</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <v-text-field v-model="ext.sort" type="number" max="255" min="0" placeholder="请输入数字" solo
                                    flat hide-details outlined dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">系统版本</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <v-chip-group active-class="primary--text" v-model="selectVersionIndex" mandatory column>
                                    <v-chip v-for="item in versionList" filter outlined>
                                        {{ item.name }}
                                    </v-chip>
                                </v-chip-group>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">内容</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <quill-editor class="a-editor" ref="aEditor" v-model="content" :options="aEditorOption"
                                    @ready="onReadyAEditor" @blur="onBlurAEditor" />
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions class="btn-box">
                        <v-btn color="green" @click="onClickBtn('ok')" text>确定</v-btn>
                        <v-btn color="orange" @click="onClickBtn('cancel')" text>取消</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card v-else class="dialog-box">
                    <v-card-title class="title2">{{ title }}</v-card-title>
                    <v-container fluid>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">排序</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <v-text-field v-model="ext.sequence" type="number" max="255" min="0" placeholder="请输入数字"
                                    solo flat hide-details outlined dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">图片</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <div v-if="!ext.pic">
                                    <v-btn @click="onClickImg" x-large icon>
                                        <v-icon>mdi-camera-plus</v-icon>
                                    </v-btn>
                                </div>
                                <v-img v-else @click="onClickImg" max-width="200" max-height="200" :src="ext.html"></v-img>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">内容</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <div id="q-toolbar"></div>
                                <quill-editor class="q-editor" ref="qEditor" :options="qEditorOption"
                                    @ready="onReadyQEditor" v-model="content" />
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions class="btn-box">
                        <v-btn color="green" @click="onClickBtn('ok')" text>确定</v-btn>
                        <v-btn color="orange" @click="onClickBtn('cancel')" text>取消</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <input ref="uploadFile" style="display: none;" :accept="accept" @change="onChangeFile" type="file">
    </v-container>
</template>
<style scoped>
.com-box {
    position: absolute;
}

.select-item {
    color: #212b36;
    font-size: 14px;
}

.dialog-box {
    padding: 0 31px;
}

.dialog-box .title2 {
    display: flex;
    justify-content: center;
    font-size: 16px !important;
    color: #212b36 !important;
}

.dialog-box .input-text {
    display: flex;
}

.dialog-box #q-toolbar {
    display: none;
    width: 0;
    height: 0;
}


.dialog-box .a-editor {
    flex: 1;
    font-size: 14px;
    overflow: hidden;
    height: 500px;
    padding-bottom: 66px;
}


.dialog-box .q-editor {
    flex: 1;
    overflow: hidden;
    font-size: 14px;
    height: 500px;
}


::v-deep.dialog-box .input-text .v-input {
    font-size: 14px !important;
    color: #212b36 !important;
}

::v-deep.dialog-box .input-text input::placeholder {
    color: #637381 !important;
    font-size: 14px !important;
}

.dialog-box .btn-box {
    display: flex;
    justify-content: center;
}


::v-deep.ql-upload {
    background: url("../../../public/q_upload.png") !important;
    background-size: 16px 16px !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
}
</style>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'

import Delta from 'quill-delta'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'

import { getArticleInfo, updateArticleInfo, updateQuickInfo, uploadFile, SYS_VERSION_LIST } from '../../global/api'
Quill.register('modules/ImageExtend', ImageExtend)
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/ImageResize', ImageResize)

export default {
    name: 'DialogDataEdit',
    components: {
        quillEditor
    },
    data: () => ({
        accept: '',
        content: '',
        versionList: [...SYS_VERSION_LIST],
        selectVersionIndex: -1,
        articleInfo: {
            article: {}
        },
        aEditorOption: '',
        qEditorOption: {
            placeholder: '请在这里输入内容',
            modules: {
                clipboard: {
                    matchers: [
                        [
                            Node.ELEMENT_NODE,
                            (node, delta) => {
                                delta.ops = delta.ops.map(op => {
                                    return { insert: op.insert };
                                });
                                return delta;
                            }
                        ]
                    ]
                },
                toolbar: {
                    container: '#q-toolbar'
                }
            }
        },
        title: '',
        ext: {},
        type: '',
        isShow: false,
        callback: ''
    }),
    computed: {
        aEditor() {
            return this.$refs.aEditor.quill;
        },
        qEditor() {
            return this.$refs.qEditor.quill;
        }
    },
    created() {
        this.aEditorOption = {
            placeholder: '请在这里输入内容',
            modules: {
                toolbar: {
                    container: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ "header": 1 }, { "header": 2 }],
                        [{ "list": "ordered" }, { "list": "bullet" }],
                        [{ "script": "sub" }, { "script": "super" }],
                        [{ "indent": "-1" }, { "indent": "+1" }],
                        [{ "direction": "rtl" }],
                        [{ "size": ["small", false, "large", "huge"] }],
                        [{ "header": [1, 2, 3, 4, 5, 6, false] }],
                        [{ "color": [] }, { "background": [] }],
                        [{ "font": [] }], [{ "align": [] }],
                        ["clean"],
                        ["link", "image", "upload"]
                    ],
                    handlers: {
                        'image': function () {
                            QuillWatch.emit(this.quill.id)
                        },
                        'upload': () => {
                            this.accept = '';
                            this.$refs.uploadFile.click();
                        }
                    }
                },
                ImageExtend: {
                    loading: false,
                    name: 'file',
                    action: process.env.VUE_APP_API_URL + '/backend/upload',
                    change: (xhr, formData) => {
                        xhr.setRequestHeader('Authorization', this.$axios.defaults.headers.common['Authorization']);
                    },
                    response: (res) => {
                        return this.$globalData.file_url + res.data.pic;
                    }
                },
                imageDrop: false,
                ImageResize: {}
            }
        }
    },
    methods: {
        textToHtml(text) {
            let delta = new Delta().insert(text);
            let converter = new QuillDeltaToHtmlConverter(delta.ops);
            return converter.convert();
        },
        getEditorText(editor) {
            let text = editor.getText();
            return text.trimEnd()
        },
        onChangeFile(e) {
            let files = e.target.files;
            if (!files || files.length <= 0) {
                return;
            }
            let file = files[0];
            if (this.type == 0) {
                uploadFile(file,'',(ok) => {
                    if (ok.code == 0) {
                        let range = this.aEditor.selection.savedRange;
                        if (range.length > 0) {
                            this.aEditor.deleteText(range.index, range.length);
                        }
                        // this.aEditor.insertEmbed(range.index, 'image', this.$globalData.file_url + ok.data.pic);
                        // this.aEditor.insertEmbed(range.index, 'link', { href: this.$globalData.file_url + ok.data.pic, innerText: file.name }, "api");
                        this.aEditor.insertText(range.index, file.name, 'link', this.$globalData.file_url + ok.data.pic);
                    } else {
                        this.$toast().show({ content: ok.msg });
                    }
                }, (err) => {
                    console.log(err);
                    this.$toast().show({ content: '上传文件失败,请稍后再试' });
                })
            } else {
                uploadFile(file,'', (ok) => {
                    if (ok.code == 0) {
                        this.$set(this.ext, 'pic', ok.data.pic);
                        this.$set(this.ext, 'html', this.$globalData.file_url + ok.data.pic);
                    } else {
                        this.$toast().show({ content: ok.msg });
                    }
                }, (err) => {
                    console.log(err);
                    this.$toast().show({ content: '上传图片失败,请稍后再试' });
                })
            }
            this.$refs.uploadFile.value = '';
        },
        onClickImg() {
            this.accept = 'image/jpeg,image/png';
            this.$refs.uploadFile.click();
        },
        onClickBtn(e) {
            if (e == 'ok') {
                this.$dialog().show({
                    content: `是否确认保存?`, callback: (r) => {
                        if (r == 'ok') {
                            // this.$dialog().close();
                            if (this.type == 0) {
                                if (this.ext['sort'] < 0 || this.ext['sort'] > 65535) {
                                    this.$toast().show({ content: '排序值范围必须是0-65535' });
                                    return;
                                }
                                if (!this.ext['title']) {
                                    this.$toast().show({ content: '请输入标题' });
                                    return;
                                }
                                // let text = this.getEditorText(this.aEditor);
                                let text = this.content;
                                if (!text) {
                                    this.$toast().show({ content: '请输入内容' });
                                    return;
                                }
                                this.ext.version_info = this.versionList[this.selectVersionIndex];
                                this.ext.version_id = this.ext.version_info.id;
                                let info = {
                                    id: this.ext.id,
                                    sort: this.ext.sort,
                                    title: this.ext.title,
                                    content: text,
                                    version_id: this.ext.version_id
                                }
                                this.$loading.show();
                                updateArticleInfo(info.id, info.sort, info.title, info.content, info.version_id, (ok) => {
                                    if (ok.code == 0) {
                                        this.ext['id'] = ok.data.id;
                                        this.$loading.close();
                                        this.callback && this.callback(e, this.ext);
                                    } else {
                                        this.$loading.close();
                                        this.$toast().show({ content: ok.msg });
                                    }
                                }, (err) => {
                                    console.log(err);
                                    this.$loading.close();
                                    this.$toast().show({ content: '保存文章信息失败,请稍后再试' });
                                })
                            } else {
                                if (this.ext['sequence'] < 0 || this.ext['sequence'] > 255) {
                                    this.$toast().show({ content: '排序值范围必须是0-255' });
                                    return;
                                }
                                let text = this.getEditorText(this.qEditor);
                                if (!text) {
                                    this.$toast().show({ content: '请输入内容' });
                                    return;
                                }
                                this.ext.content = text;
                                let info = {
                                    id: this.ext.id,
                                    sequence: this.ext.sequence,
                                    content: text
                                }
                                if (!this.ext['pic']) {
                                    this.ext['type'] = 1;
                                } else {
                                    this.ext['type'] = 2;
                                    info['pic'] = this.ext.pic;
                                }
                                info['type'] = this.ext.type;
                                this.$loading.show();
                                updateQuickInfo(info.id, info.type, info.pic, info.content, info.sequence, (ok) => {
                                    if (ok.code == 0) {
                                        this.ext['id'] = ok.data.id;
                                        this.$loading.close();
                                        this.callback && this.callback(e, this.ext);
                                    } else {
                                        this.$loading.close();
                                        this.$toast().show({ content: ok.msg });
                                    }
                                }, (err) => {
                                    console.log(err);
                                    this.$loading.close();
                                    this.$toast().show({ content: '保存回复信息失败,请稍后再试' });
                                })
                            }
                        } else {
                            // this.$dialog().close();
                        }
                    }
                })
            } else {
                this.callback && this.callback(e, this.ext);
            }
        },
        onBlurAEditor(quill) {
            // console.log('editor blur!', quill);
            let e = document.querySelector('.ql-tooltip,.ql-editing');
            if (e) {
                let left = e.style.left;
                if (left.indexOf('-') === 0) {
                    e.style.left = 0;
                }
            }
        },
        onReadyAEditor(quill) {
            // console.log(quill, this.type, JSON.stringify(this.ext));
            // if (this.type == 0) {
            //     if (this.ext.hasOwnProperty('content')) {
            //         this.content = this.textToHtml(this.ext.content);
            //     } else {
            //         this.content='';
            //     }
            // }
        },
        onReadyQEditor(quill) {
            // console.log(quill, this.type, JSON.stringify(this.ext));
            // if (this.type == 1) {
            //     if (this.ext.hasOwnProperty('content')) {
            //         this.content = this.textToHtml(this.ext.content);
            //     } else {
            //         this.content='';
            //     }
            // }
        },
        show(opt) {
            opt = opt || {};
            this.callback = opt['callback'] || '';
            this.type = opt['type'];
            if (opt.hasOwnProperty('ext')) {
                this.ext = opt['ext'];
            } else {
                this.ext = {};
            }
            if (this.type == 0) {
                if (this.ext.hasOwnProperty('title')) {
                    this.title = '编辑文章';
                } else {
                    this.title = '新增文章';
                }
                this.selectVersionIndex = 0;
                this.articleInfo = {
                    article: {}
                };
                if (this.ext.hasOwnProperty('title')) {
                    this.$loading.show({ content: '加载中' });
                    getArticleInfo(this.ext.id, (ok) => {
                        if (ok.code == 0) {
                            this.articleInfo = ok.data;
                            // this.aEditor.setText(this.articleInfo.article['content']);
                            this.content = this.articleInfo.article['content'];
                            if (this.ext['version_id']) {
                                let versionIndex = this.versionList.findIndex((item) => item.id == this.ext.version_id)
                                if (versionIndex != -1) {
                                    this.selectVersionIndex = versionIndex;
                                }
                            }
                            this.$loading.close();
                        } else {
                            this.$loading.close();
                            this.$toast().show({ content: ok.msg });
                        }
                    }, (err) => {
                        console.log(err);
                        this.$loading.close();
                        this.$toast().show({ content: '获取文章信息失败,请稍后再试' });
                    })
                }
            } else {
                if (this.ext.hasOwnProperty('content')) {
                    this.title = '编辑回复';
                    this.content = this.textToHtml(this.ext.content);
                } else {
                    this.title = '新增回复';
                }
            }
            this.isShow = true;
        },
        close() {
            this.isShow = false;
            this.title = '';
            this.type = '';
            this.ext = {};
            this.articleInfo = {
                article: {}
            };
            this.content = '';
            this.selectVersionIndex = 0;
        }
    }
}
</script>
  