import Vue from 'vue';
import DialogVue from './Dialog.vue';
import { createComponent } from '..';

let DialogConstructor = Vue.extend(DialogVue);
let DialogIndex = 991;
let Dialog = () => {
  DialogIndex++;
  return createComponent(DialogConstructor, 'Dialog' + DialogIndex, DialogIndex, () => {
    DialogIndex--;
    console.log('Dialog', DialogIndex);
  })._vm;
}

export default {
  install: Vue => {
    Vue.prototype.$dialog = Dialog;
  }
}