import router from '../router'
import store from '../store'
import axios from 'axios'
import qs from 'qs'

const axiosIns = axios.create();

axiosIns.interceptors.response.use((response) => {
    if (response.data.code == '-201') {
        delete axiosIns.defaults.headers.common['Authorization'];
        localStorage.clear();
        store.commit('token', '');
        store.commit('userInfo', '');
        store.commit('permission', '');
        if (response.config.url.indexOf('/backend/user/check') == -1) {
            router.push({
                path: '/login'
            });
        }
    }
    return response;
}, (error) => {
    return Promise.reject(error);
});

axiosIns.interceptors.request.use((config) => {
    let type = Object.prototype.toString.call(config.data);
    if (config.method === 'post' && type != '[object FormData]') {
        config.data = qs.stringify(config.data);
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosIns