<template>
  <v-container class="container-box" fluid>
    <v-row class="body-box d-flex align-center justify-center flex-column">
      <!-- <div class="ma-5">
        <v-img max-width="50" max-height="44" :src="`${$globalData.static_url}/logo.png`"></v-img>
      </div> -->
      <v-card class="pa-4" min-width="400" flat>
        <v-card-title class="title-box">
          <!-- <v-icon left>
            mdi-account
          </v-icon> -->
          <v-img max-width="50" max-height="44" :src="`${$globalData.static_url}/logo.png`"></v-img>
          <!-- <span class="title2">客服中心</span> -->
        </v-card-title>
        <v-form class="form-box" ref="form" v-model="valid">
          <v-text-field v-model="username" :rules="rulesUsername" label="用户名" required></v-text-field>
          <v-text-field v-model="password" type="password" :rules="rulesPassword" label="密码" @keyup.enter="onClickLogin"
            required></v-text-field>
          <v-btn class="submit" @click="onClickLogin" block>登录</v-btn>
        </v-form>
      </v-card>
    </v-row>

  </v-container>
</template>
<style scoped>
::v-deep.v-card {
  border-radius: 14px !important;
}


.container-box {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-image: url('../../public/bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.container-box .body-box {
  margin-bottom: 50px;
}

.title-box {
  justify-content: center;
  margin-bottom: 20px;
}

.title-box .title2 {
  font-size: 14px;
  color: #333d46;
}

.form-box .submit {
  margin-top: 16px;
  background-color: #333d46;
  color: #fff;
  font-size: 14px;
}
</style>
<script>
import { login, getRoleInfo } from '../global/api'

export default {
  name: 'Login',
  data: () => ({
    valid: true,
    username: '',
    rulesUsername: [
      v => !!v || '请输入用户名',
      v => (v.length >= 4 && v.length <= 16) || '用户名长度错误(6~16位)',
    ],
    password: '',
    rulesPassword: [
      v => !!v || '请输入密码',
      v => (v.length >= 4 && v.length <= 16) || '密码长度错误(6~16位)',
    ],
  }),
  methods: {
    onClickLogin() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$loading.show({ content: '登录中' });
      login(this.username, this.password, (ok) => {
        if (ok.code == 0) {
          this.$axios.defaults.headers.common['Authorization'] = `${ok.data.token.token_type} ${ok.data.token.access_token}`;
          this.$store.commit('token', ok.data.token);
          // getRoleInfo(ok.data.user.role_id, (ok2) => {
          //   if (ok2.code == 0) {
          //     ok.data.user.role_name = ok2.data.role.name;
          //     this.$store.commit('userInfo', ok.data.user);
          //     let permission = this.$_.keyBy(ok2.data.permissions, 'id');
          //     console.log('权限', permission);
          //     this.$store.commit('permission', permission);
          //     this.$loading.close();
          //     this.$router.push({
          //       path: '/home'
          //     });
          //   } else {
          //     this.$loading.close();
          //     this.$toast().show({ content: ok2.msg });
          //   }
          // }, (err) => {
          //   console.log(err);
          //   this.$loading.close();
          //   this.$toast().show({ content: '获取角色信息失败,请稍后再试' });
          // });
          this.$loading.close();
          this.$router.push({
            path: '/'
          });
        } else {
          this.$loading.close();
          this.$toast().show({ content: ok.msg });
        }
      }, (err) => {
        console.log(err);
        this.$loading.close();
        this.$toast().show({ content: '登录失败,请稍后再试' });
      });
    }
  }
};
</script>