import Vue from 'vue';
import ToastVue from './Toast.vue';
import { createComponent } from '../';

let ToastConstructor = Vue.extend(ToastVue);
let ToastIndex = 992;
let Toast = () => {
  ToastIndex++;
  return createComponent(ToastConstructor, 'Toast' + ToastIndex, ToastIndex, () => {
    ToastIndex--;
    console.log('Toast', ToastIndex);
  })._vm;
}

export default {
  install: Vue => {
    Vue.prototype.$toast = Toast;
  }
}