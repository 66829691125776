<template>
  <v-app class="app-box">
    <router-view name="leftBar" />
    <router-view name="topBar" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<style scoped>
.app-box {
  width: 100%;
  height: 100%;
  background-color: #f5f7fa;
  /* background-color: red; */
  overflow: hidden;
}
</style>
<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
