class Event {
    constructor() {
        this.eventList = {};
    }
    on(obj, event, callback) {
        let events = this.eventList[event];
        if (!events) {
            this.eventList[event] = [{
                obj: obj,
                callback: callback
            }];
        } else {
            this.eventList[event].push({
                obj: obj,
                callback: callback
            });
        }
    }
    off(obj, event) {
        let events = this.eventList[event];
        if (!events || events.length <= 0) {
            // console.error(`event ${event} not found`);
            return;
        }
        for (let i = events.length - 1; i >= 0; i--) {
            let item = events[i];
            if (item['obj'] == obj) {
                events.splice(i, 1);
                break;
            }
        }
    }
    emit(event, ...args) {
        let events = this.eventList[event];
        if (!events || events.length <= 0) {
            // console.error(`event ${event} not found`);
            return;
        }
        events.forEach((item) => {
            item['callback'] && item.callback(...args);
        });
    }
}

export default Event;