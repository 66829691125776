<template>
    <v-container class="com-box" fluid>
        <v-row align="center">
            <v-dialog v-model="isShow" max-width="350">
                <v-card class="dialog-box">
                    <v-card-title class="title2">{{ title }}</v-card-title>
                    <v-container fluid>
                        <v-row align="center">
                            <v-col v-if="type == 'select'" class="input-text">
                                <v-combobox :readonly="readonly" v-model="selectItem" :items="selectList" item-text="name"
                                    item-value="item" :placeholder="placeholder" solo flat hide-details outlined
                                    dense></v-combobox>
                            </v-col>
                            <v-col v-else-if="type == 'remark'" class="input-text">
                                <v-textarea :readonly="readonly" rows="6" v-model="ext.value" :placeholder="placeholder"
                                    solo flat hide-details outlined dense></v-textarea>
                            </v-col>
                            <v-col v-else class="input-text">
                                <v-text-field :readonly="readonly" v-model="ext.value" :placeholder="placeholder" solo flat
                                    hide-details outlined dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions class="btn-box">
                        <v-btn color="green" @click="onClickBtn('ok')" text>确定</v-btn>
                        <v-btn color="orange" @click="onClickBtn('cancel')" text>取消</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<style scoped>
.com-box {
    position: absolute;
}

.select-item {
    color: #212b36;
    font-size: 14px;
}

.dialog-box {
    padding: 0 31px;
}

.dialog-box .title2 {
    display: flex;
    justify-content: center;
    font-size: 16px !important;
    color: #212b36 !important;
}

.dialog-box .input-text {
    display: flex;
}

::v-deep.dialog-box .input-text .v-input {
    font-size: 14px !important;
    color: #212b36 !important;
}

::v-deep.dialog-box .input-text input::placeholder {
    color: #637381 !important;
    font-size: 14px !important;
}

.dialog-box .btn-box {
    display: flex;
    justify-content: center;
}
</style>
<script>
import { getChainList, getBarList } from '../../global/api'

export default {
    name: 'DialogEdit',
    data: () => ({
        selectItem: '',
        selectList: [],
        title: '',
        readonly: false,
        placeholder: '',
        type: '',
        _type: '',
        ext: {},
        isShow: false,
        callback: ''
    }),
    methods: {
        onClickBtn(e) {
            if (e == 'ok') {
                switch (this.type) {
                    case 'select':
                        this.ext = this.selectItem;
                        break;
                    default:
                        break;
                }
            }
            this.callback && this.callback(e, this.ext);
        },
        show(opt) {
            opt = opt || {};
            this.callback = opt['callback'] || '';
            this.title = opt['title'] || '';
            this.placeholder = opt['placeholder'] || '';
            this.type = opt['type'] || '';
            this._type = opt['_type'] || '';
            this.readonly = opt['readonly'] || false;
            if (opt.hasOwnProperty('ext')) {
                this.ext = opt['ext'];
            } else {
                this.ext = {};
            }
            switch (this.type) {
                case 'select':
                    switch (this._type) {
                        case 'chain':
                            this.$loading.show({ content: '加载中' });
                            getChainList((ok) => {
                                if (ok.code == 0) {
                                    this.selectList = [{ id: -1, name: "银杏版本", chain_id: -1 }, ...ok.data];
                                    if (this.ext['id']) {
                                        this.selectItem = this.selectList.find((item => item.chain_id == this.ext['id']));
                                    }
                                    this.$loading.close();
                                } else {
                                    this.$loading.close();
                                    this.$toast().show({ content: ok.msg });
                                }
                            }, (err) => {
                                console.log(err);
                                this.$loading.close();
                                this.$toast().show({ content: '获取连锁列表失败,请稍后再试' });
                            });
                            break;
                        case 'bar':
                            this.$loading.show({ content: '加载中' });
                            getBarList(this.ext.chain_id, (ok) => {
                                if (ok.code == 0) {
                                    this.selectList = ok.data;
                                    if (this.ext['id']) {
                                        this.selectItem = this.selectList.find((item => item.id == this.ext['id']));
                                    }
                                    this.$loading.close();
                                } else {
                                    this.$loading.close();
                                    this.$toast().show({ content: ok.msg });
                                }
                            }, (err) => {
                                console.log(err);
                                this.$loading.close();
                                this.$toast().show({ content: '获取门店列表失败,请稍后再试' });
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
            this.isShow = true;
        },
        close() {
            this.isShow = false;
            this.callback = '';
            this.ext = {};
            this.type = '';
            this._type = '';
            this.title = '';
            this.placeholder = '';
            this.selectItem = '';
            this.selectIndex = 0;
            this.selectList = [];
        }
    }
}
</script>
  