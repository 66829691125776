<template>
  <v-container class="container-box" fluid>
  </v-container>
</template>
<style scoped>
.container-box {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
</style>
<script>
import { mapState } from 'vuex'
import { checkToken, getRoleInfo } from '../global/api'

export default {
  name: 'Enter',
  data: () => ({
  }),
  computed: {
    ...mapState([
      'token',
      'userInfo',
      'permission'
    ])
  },
  mounted() {
    this.$loading.show({ content: '检测中' });
    checkToken((ok) => {
      if (ok.code == 0) {
        getRoleInfo(ok.data.role_id, (ok2) => {
          if (ok2.code == 0) {
            ok.data.role_name = ok2.data.role.name;
            this.$store.commit('userInfo', ok.data);
            let permission = this.$_.keyBy(ok2.data.permissions, 'id');
            console.log('权限', permission);
            this.$store.commit('permission', permission);
            this.$loading.close();
            let routerPath = localStorage.getItem('routerPath');
            this.$router.push({
              path: (routerPath && routerPath != '/') ? routerPath : '/home'
            });
          } else {
            this.$loading.close();
            this.$toast().show({ content: ok2.msg });
          }
        }, (err) => {
          console.log(err);
          this.$loading.close();
          this.$toast().show({ content: '获取角色信息失败,请稍后再试' });
        });
      } else {
        this.$loading.close();
        this.$router.push({
          path: '/login'
        });
      }
    }, (err) => {
      console.log(err);
      this.$loading.close();
      this.$toast().show({ content: '检测token失败,请稍后再试' });
    });
  }
};
</script>