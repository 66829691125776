<template>
    <v-container class="com-box" fluid>
        <v-row align="center">
            <v-dialog v-model="isShow" max-width="840" @click:outside="close">
                <div class="info_mode">
                    <div class="tie">交班</div>
                    <div class="user_set">
                        <div class="item_set">
                            <div class="label">交班人</div>
                            <div class="tex_box">
                                {{ isDisabled ? hoursUser : $store.state.userInfo.name }}
                            </div>
                        </div>
                        <div class="item_set">
                            <div class="label">接班人</div>
                            <div class="tex_box">
                                <v-select v-model="userSelect" :disabled="isDisabled" :menu-props="{ offsetY: true }"
                                          :items="userList"
                                          item-text="name" item-value="id" placeholder="请选择" solo flat hide-details
                                          outlined
                                          dense>
                                    <template v-slot:item="{ item }">
                                        <span class="select-item">{{ item.name }}</span>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="card_list">
                        <div class="card_item" v-for="(item, index) in cardList" :key="index">
                            <div class="label">{{ item.label }}</div>
                            <div class="num">{{ item.num }}</div>
                        </div>
                    </div>
                    <div class="wait_wk">
                        <div class="tile">待跟踪会话</div>
                        <template v-if="waitList.length > 0">
                            <div class="list_wk">
                                <v-simple-table fixed-header height="400px">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th v-if="!isDisabled" class="text-center" style="width: 50px;"><v-checkbox
                                                                v-model="checkboxAll" @change="selectAll"></v-checkbox>
                                                </th>
                                                <th class="text-center" style="font-size: 16px;;width: 100px;"
                                                    v-for="(item, index) in headers" :key="index">{{
                                                        item }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(item, index) in waitList" :key="index">
                                                <td v-if="!isDisabled"><v-checkbox v-model="item.check"
                                                                @change="selectItem"></v-checkbox></td>
                                                <td style="text-align: center">{{ item.chain_name }}</td>
                                                <td style="text-align: center">{{ item.name }}</td>
                                                <td style="text-align: center">{{ item.mobile }}</td>
                                                <td style="text-align: center">{{ item.remark }}</td>
                                            </tr>

                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </template>
                        <div v-else class="err_msg">暂无待跟踪会话</div>
                    </div>
                    <div class="btn_all">
                        <!-- <v-btn color="error" text @click="aceSubmit">批量标记处理</v-btn> -->
                        <v-btn color="green" v-if="!isDisabled" style="margin-left: auto;" text
                               @click="submitFrom">确定</v-btn>
                        <v-btn color="orange" :style="{ marginLeft: isDisabled ? 'auto' : '' }" text
                               @click="close">取消</v-btn>
                    </div>
                </div>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<script>
import { swapData, swapDataSubmit, getItemSwapInfo } from '@/global/api.js'
export default {
    name: 'DialogSwap',
    data: () => ({
        hoursUser: '',
        isDisabled: false, //是否禁用选择框
        isShow: false,
        userList: [],
        userSelect: null,
        checkboxAll: false,
        waitList: [],//待跟踪列表
        headers: [
            // '最后会话时间', 
            '所属连锁',
            '姓名',
            '电话',
            '备注'],
        cardList: [
            { label: '本班接入会话数', num: 0 },
            { label: '超时回复会话数', num: 0 },
            { label: '本班会话评分', num: 0 },
            { label: '差评会话数', num: 0 },
            { label: '待完成跟踪数', num: 0 },
            { label: '新增待跟踪数', num: 0 },
            { label: '完成跟踪数', num: 0 }
        ]
    }),
    mounted () {
        // console.log('this.$store.state', this.$store.state)
    },
    methods: {

        selectAll () {
            this.waitList.forEach((item) => {
                item['check'] = Boolean(this.checkboxAll)
            })
        },
        selectItem () {
            this.checkboxAll = this.waitList.every(item => item.check)
        },
        submitFrom () {
            console.log('交班弹窗触发提交事件', this.userSelect)
            if (!this.userSelect) {
                this.$toast().show({
                    content: '请选择接班人'
                })
                return
            }
            let ids = this.waitList.filter(item => item.check).map(item => item.id)
            swapDataSubmit(this.userSelect, ids.join(','), (res) => {
                if (res.code == 0) {
                    this.$toast().show({
                        content: '交班成功'
                    })
                    this.close()
                } else {
                    this.$toast().show({
                        content: res.msg
                    })
                }
            }, (err) => {
                console.log(err)
                this.$toast().show({
                    content: '交班失败'
                })
            })
        },
        getData () {
            swapData((res) => {
                if (res.code == 0) {
                    this.userList = res.data.users
                    this.cardList[0]['num'] = res.data.meeting_total || 0
                    this.cardList[1]['num'] = res.data.timeout_num || 0
                    this.cardList[2]['num'] = res.data.score || 0
                    this.cardList[3]['num'] = res.data.negative_num || 0
                    this.cardList[4]['num'] = res.data.stay_track_num || 0
                    this.cardList[5]['num'] = res.data.use_track_num || 0
                    this.waitList = res.data.list || []
                    this.waitList.forEach((item) => {
                        item['check'] = false
                    })
                } else {
                    this.$toast().show({
                        content: res.msg
                    })
                }
            }, (err) => {
                console.log(err)
                this.$toast().show({
                    content: '查询交班信息失败'
                })
            })
        },
        show (opt) {
            if (!opt) {
                this.getData()
                this.isShow = true
            } else {
                this.isDisabled = true
                getItemSwapInfo(opt.id, (res) => {
                    this.waitList = res.data || []
                }, (err) => {
                    console.log(err)
                    this.$toast().show({
                        content: '查询交班信息失败'
                    })
                })
                console.log('申请', opt)
                this.userSelect = opt.join_uid
                this.hoursUser = opt.name.name
                this.userList = opt.users
                this.cardList[0]['num'] = opt.meeting_total || 0
                this.cardList[1]['num'] = opt.timeout_num || 0
                this.cardList[2]['num'] = opt.score || 0
                this.cardList[3]['num'] = opt.negative_num || 0
                this.cardList[4]['num'] = opt.stay_track_num || 0
                this.cardList[5]['num'] = opt.use_track_num || 0
                this.isShow = true
            }

        },

        close () {
            console.log('交班弹窗触发关闭事件')
            this.isShow = false
            this.userList = []
            this.userSelect = null
            this.hoursUser = ''
            this.waitList = []
            this.cardList = [
                { label: '本班接入会话数', num: 0 },
                { label: '超时回复会话数', num: 0 },
                { label: '本班会话评分', num: 0 },
                { label: '差评会话数', num: 0 },
                { label: '待完成跟踪数', num: 0 },
                { label: '新增待跟踪数', num: 0 },
                { label: '完成跟踪数', num: 0 }
            ]
        }
    }
}
</script>
<style scoped lang="scss">
.com-box {
    position: absolute;
}

.info_mode {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 0;

    .wait_wk {
        background-color: #f5f7fa;

        .tile {
            height: 40px;
            text-align: center;
            line-height: 40px;
        }

        .list_wk {
            height: 400px;
            // overflow: auto;
        }

        .err_msg {
            background-color: #fff;
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .card_list {
        display: flex;

        .card_item {
            padding: 10px;
            text-align: center;
            flex-shrink: 0;
            border-bottom: 1px solid #ccc;

            &+.card_item {
                border-left: 1px solid #ccc;
            }

            &:last-child {
                flex: 1;
            }

            .num {
                color: #e4730a;
            }
        }

    }

    .user_set {
        width: 100%;
        height: 100%;
        display: flex;

        .item_set {
            width: 50%;
            padding: 10px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ccc;

            &+.item_set {
                border-left: 1px solid #ccc;
            }

            .label {
                margin-left: 20px;
                width: 50px;
                text-align: right;
                margin-right: 20px;
            }
        }
    }

    .btn_all {
        border-top: 1px solid #ccc;
        display: flex;
        padding: 10px 20px;
    }

    .tie {
        width: 100%;
        height: 40px;
        font-size: 20px;
        padding: 0 20px;
        border-bottom: 1px solid #ccc;
    }
}
</style>