<template>
  <v-container class="com-box" fluid>
    <v-snackbar v-model="isShow" timeout="-1" top>
      {{ content }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="close">
          关闭
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<style scoped>
.com-box {
  position: absolute;
  z-index: 991;
}
</style>
<script>
export default {
  name: 'Toast',
  data: () => ({
    isShow: false,
    content: '',
    hideTimer: ''
  }),
  methods: {
    show(opt) {
      console.log('opt', opt)
      opt = opt || {};
      this.content = opt['content'] || '';
      this._create();
      this.isShow = true;
      this.hideTimer = setTimeout(() => {
        this.close();
      }, 2000)
    },
    close() {
      if (this.hideTimer) {
        clearTimeout(this.hideTimer);
        this.hideTimer = '';
      }
      this.isShow = false;
      this._destory();
      this.content = '';
    }
  }
}
</script>
