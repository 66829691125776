<template>
  <v-container class="com-box" fluid>
    <v-row align="center">
      <v-dialog v-model="isShow" max-width="840" @click:outside="close">
        <div class="msg_box">
          <div class="tilie">{{ this.title }}</div>
          <div class="msg_itme_box">
            <div class="msg_item" :class="{ 'mz': index % 2 == 1 }" v-for="(item, index) in list" :key="index">
              <div class="head_msg">
                <div class="name">{{ item.sender_name }}</div>
                <div class="time">{{ item.time }}</div>
              </div>
              <div class="ms_item">
                <template v-if="item.msgtype == 'text' || level > 1">
                  <div class="wb">{{ item.content }}</div>
                </template>
                <template v-if="item.msgtype == 'image' && level == 1">
                  <viewer :images="[item.content]">
                    <img class="img_box" :src="item.content" />
                  </viewer>
                </template>
                <template v-if="item.msgtype == 'video' && level == 1">
                  <div class="msz_wk">
                    <vue-plyr :options="plyrOptions">
                      <video controls crossorigin playsinline>
                        <source :src="item.content" :key="item.content" />
                      </video>
                    </vue-plyr>
                  </div>

                </template>
                <template v-if="item.msgtype == 'merged_msg'">
                  <div class="btn_msg" @click="showMsgList(item)">
                    <div class="t_head">{{ item.content }}</div>
                    <div class="t_msg">点击查看详情</div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="btn_close">
            <v-btn text color="pink" @click="close()">
              关闭
            </v-btn>
          </div>
        </div>
      </v-dialog>
      <DialogWxMsg v-if="isShowMsg" :itemObj="setObj" :level="level + 1" @close="isShowMsg = false"></DialogWxMsg>
    </v-row>
  </v-container>
</template>
<script>
import { MSG_TYPE_TEXT, MSG_TYPE_IMAGE, MSG_TYPE_VOICE, MSG_TYPE_VIDEO, MSG_TYPE_FILE, MSG_TYPE_MERGED } from '../../global/api'
import DialogWxMsg from './DialogWxMsg'
export default {
  name: 'DialogWxMsg',
  props: {
    itemObj: {
      type: Object,
      default: () => { }
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      isShow: false,
      isShowMsg: false,
      setObj: {},
      list: [],
      plyrOptions: {
        controls: [, 'play-large', 'progress', 'current-time', 'mute', 'volume', 'pip', 'fullscreen']
      },
      title: '消息',
      token: ''
    }
  },
  components: {
    DialogWxMsg
  },
  mounted () {
    console.log('第三方消息记录', this.itemObj)
    this.$nextTick(() => {
      this.show(this.itemObj)
    })
  },
  methods: {
    close () {
      this.isShow = false
      this.list = []
      this.title = '消息'
      this.token = ''
      this.$emit('close')
    },

    show (opt) {
      this.token = JSON.parse(localStorage.getItem('token')).access_token
      console.log('第三方消息记录1', opt)
      this.title = opt.title || '消息'
      this.list = opt.item.map(item => {
        let msgObj = JSON.parse(item.msg_content)
        item['content'] = this.getType(msgObj)
        item['time'] = this.$moment(+new Date(item.send_time * 1000)).format('YYYY-MM-DD HH:mm:ss')
        return item
      })
      console.log('第三方消息记录2', opt, this.list, localStorage.getItem('token'))
      this.isShow = true

    },
    showMsgList (item) {
      console.log('二级合并消息', JSON.parse(item.msg_content).merged_msg)
      this.setObj = JSON.parse(item.msg_content).merged_msg
      this.isShowMsg = true
      // this.$refs.xza.show(item)
    },
    getType (item) {
      switch (item.msgtype) {
        case MSG_TYPE_TEXT:
          return item.text.content
        case MSG_TYPE_IMAGE:
          if (this.level > 1) {
            return `[暂不支持${this.level}级消息]`
          } else {
            return process.env.VUE_APP_API_URL + '/backend/media/info?media_id=' + item.image.media_id + '&access_token=' + this.token
          }
        case MSG_TYPE_VOICE:
          return '语音'
        case MSG_TYPE_VIDEO:
          if (this.level > 1) {
            return `[暂不支持${this.level}级消息]`
          } else {
            return process.env.VUE_APP_API_URL + '/backend/media/info?media_id=' + item.video.media_id + '&access_token=' + this.token
          }
        case MSG_TYPE_FILE:
          return '[文件]'
        case MSG_TYPE_MERGED:
          return item.merged_msg.title
      }
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep.ms_item .plyr {
  max-width: 350px;
  /* max-height: 350px; */
}

.msg_box {
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;

  background-color: #fff;

  .tilie {
    text-align: center;
    padding: 20px 0;
    border-bottom: #ededed solid 1px;
  }

  .btn_close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
  }

  .msg_itme_box {
    width: 100%;
    height: 600px;
    overflow-y: auto;

    .msg_item {

      background-color: #d9f4ff;
      padding: 20px;

      &.mz {
        background-color: #ededed
      }

      .head_msg {
        font-size: 14px;
        display: flex;
        padding-bottom: 10px;
        color: #333;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
      }

      .ms_item {
        .btn_msg {
          cursor: pointer;
          user-select: none;
          background: #32c3e7;
          color: #fff;
          border-radius: 4px;
          padding: 10px;
          margin-top: 5px;

          .t_head {
            padding-bottom: 5px;
          }

          .t_msg {
            border-top: 1px solid #ffffff;
            padding-top: 5px;
          }
        }

        .wb {
          font-size: 14px;
          color: #333;
          padding: 10px 0;
        }
        .msz_wk{
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
        .img_box {
          padding: 10px 0;
          max-width: 100%;
          max-height: 180px;
        }
      }

    }
  }

}
</style>