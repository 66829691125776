<template>
  <v-navigation-drawer class="nav-bar" mini-variant-width="90" :mini-variant="mini" floating app>
    <div class="arrow-left rounded-circle pa-1 white" @click="onClickArrowLeft">
      <v-icon>{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
    </div>
    <v-list :class="mini && 'px-0'" nav>
      <v-list-item :class="mini && 'px-0'">
        <v-list-item-avatar width="50" height="44">
          <v-img max-width="50" max-height="44" :src="`${$globalData.static_url}/logo.png`"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-img max-width="140" max-height="31" :src="`${$globalData.static_url}/left-banner.png`"></v-img>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="user-box">
        <v-list-item-avatar width="40" height="40">
          <v-img max-width="40" max-height="40" :src="userInfo.avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="name" v-text="userInfo.name"></v-list-item-title>
          <v-list-item-title class="group" v-text="userInfo.role_name"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item-group class="none-color" active-class="active-color" v-model="menuIndex" @change="onChangeMenu"
        mandatory>
        <v-list-item class="menu-item" v-for="item, index in menuList" :key="index" v-if="item.show">
          <v-list-item-icon class="mr-0">
            <v-img max-width="22" max-height="22"
              :src="`${$globalData.static_url}/${item.icon}-${menuIndex == index ? 1 : 0}.png`"></v-img>
          </v-list-item-icon>
          <v-list-item-content class="ml-5">
            <v-list-item-title class="title2" v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<style scoped>
.nav-bar {
  overflow: unset;
}

.arrow-left {
  position: absolute;
  top: 23px;
  right: -12px;
  z-index: 99;
}

.user-box {
  margin: 26px 20px 26px 20px;
  background-color: #f5f7fa;
  border-radius: 14px;
}

.user-box .name {
  color: #333d46;
  font-size: 14px;
}

.user-box .group {
  color: #717f8c;
  font-size: 12px;
}

.none-color {
  color: #677784;
}

.active-color {
  color: #14b163 !important;
}

.menu-item .title2 {
  font-size: 14px !important;
}
</style>
<script>
import { mapState } from 'vuex'
export default {
  name: 'LeftBar',
  data: () => ({
    menuIndex: 0,
    menuList: [
      { id: 1, title: '会话页面', icon: 'left-menu-item-session', to: '/home', show: 0 },
      // { title: '未接入', icon: 'left-menu-item-wait' },
      { id: 2, title: '客户档案', icon: 'left-menu-item-user', to: '/record', show: 0 },
      { id: 3, title: '会话报告', icon: 'left-menu-item-report', to: '/report', show: 0 },
      { id: 4, title: '客服分析', icon: 'left-menu-item-analysis', to: '/analysis', show: 0 },
      { id: 5, title: '系统管理', icon: 'left-menu-item-manage', to: '/manage', show: 0 },
      { id: 6, title: '交班明细表', icon: 'left-menu-item-handover', to: '/handoverRecord', show: 0 }
    ],
    mini: true
  }),
  computed: {
    ...mapState([
      'userInfo',
      'permission'
    ])
  },
  mounted() {
    this.menuList = this.menuList.map((item) => {
      item.show = this.permission.hasOwnProperty(item.id) ? 1 : 0;
      return item;
    });
    let path = this.$router.currentRoute.path;
    this.menuIndex = this.menuList.findIndex((item) => {
      return path.indexOf(item.to) != -1;
    });
  },
  methods: {
    onChangeMenu(e) {
      if (e == undefined) {
        return;
      }
      this.$router.push({
        path: this.menuList[e].to
      });
    },
    onClickArrowLeft() {
      this.mini = !this.mini;
    }
  }
};
</script>