<template>
    <v-container class="com-box" fluid>
        <v-row align="center">
            <v-dialog v-model="isShow" max-width="700" persistent>
                <v-card class="dialog-box">
                    <v-card-title class="title2">会话上报</v-card-title>
                    <v-container fluid>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">客户姓名</v-col>
                            <v-col class="input-text" cols="12" sm="4">
                                <v-text-field v-model="ext.name" placeholder="请输入客户姓名" solo flat hide-details outlined
                                    dense></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="2">电话号码</v-col>
                            <v-col class="input-text" cols="12" sm="4">
                                <v-text-field v-model="ext.mobile" placeholder="请输入电话号码" solo flat hide-details outlined
                                    dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">所属连锁</v-col>
                            <v-col class="input-text" cols="12" sm="4">
                                <v-combobox v-model="selectChain" :items="chainList" placeholder="请选择所属连锁" item-text="name"
                                    item-value="item" @change="onChangeChain" solo flat hide-details outlined
                                    dense></v-combobox>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="2">所属门店</v-col>
                            <v-col class="input-text" cols="12" sm="4">
                                <v-combobox v-model="selectBar" :items="barList" placeholder="请选择所属门店" item-text="name"
                                    item-value="item" solo flat hide-details outlined dense></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">系统版本</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <v-chip-group active-class="primary--text" v-model="selectVersionIndex" mandatory column>
                                    <v-chip v-for="item in versionList" filter outlined>
                                        {{ item.name }}
                                    </v-chip>
                                </v-chip-group>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">咨询分类</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <v-chip-group active-class="primary--text" v-model="selectQTypeIndex" mandatory column>
                                    <v-chip v-for="item in qTypeList" filter outlined>
                                        {{ item.name }}
                                    </v-chip>
                                </v-chip-group>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">是否跟踪</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <v-radio-group v-model="ext.is_track" mandatory row>
                                    <v-radio label="待跟踪" :value="1"></v-radio>
                                    <v-radio label="不跟踪" :value="0"></v-radio>
                                    <v-radio v-if="this.type != 'add'" label="已跟踪" :value="2"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="d-flex" cols="12" sm="2">备注</v-col>
                            <v-col class="input-text" cols="12" sm="10">
                                <v-textarea rows="6" v-model="ext.remark" placeholder="请在这里输入内容" solo flat
                                    hide-details></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions class="btn-box">
                        <v-btn color="green" @click="onClickBtn('ok')" text>确定</v-btn>
                        <v-btn color="orange" @click="onClickBtn('cancel')" text>取消</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<style scoped>
.com-box {
    position: absolute;
}

.select-item {
    color: #212b36;
    font-size: 14px;
}

.dialog-box {
    padding: 0 31px;
}

.dialog-box .title2 {
    display: flex;
    justify-content: center;
    font-size: 16px !important;
    color: #212b36 !important;
}

.dialog-box .input-text {
    display: flex;
}

::v-deep.dialog-box .input-text .v-input {
    font-size: 14px !important;
    color: #212b36 !important;
}

::v-deep.dialog-box .input-text input::placeholder {
    color: #637381 !important;
    font-size: 14px !important;
}

.dialog-box .btn-box {
    display: flex;
    justify-content: center;
}
</style>
<script>
import { getChainList, getBarList, SYS_VERSION_LIST, updateSessionReport, getTagList } from '../../global/api'

export default {
    name: 'DialogSessionReport',
    data: () => ({
        type: '',
        selectVersionIndex: -1,
        versionList: SYS_VERSION_LIST,
        selectQTypeIndex: -1,
        selectChain: '',
        selectBar: '',
        qTypeList: [],
        chainList: [],
        barList: [],
        ext: {},
        isShow: false,
        callback: ''
    }),
    methods: {
        onChangeChain(e) {
            if (e.chain_id == -1) {
                this.barList = [];
                if (this.ext['mch_name']) {
                    this.selectBar = { id: -1, name: this.ext['mch_name'], status: "1" }
                }
            } else {
                this.$loading.show({ content: '加载中' });
                getBarList(e.chain_id, (ok) => {
                    if (ok.code == 0) {
                        this.barList = ok.data;
                        if (this.ext['mch_id']) {
                            this.selectBar = this.barList.find((item => item.id == this.ext['mch_id']));
                        }
                        this.$loading.close();
                    } else {
                        this.$loading.close();
                        this.$toast().show({ content: ok.msg });
                    }
                }, (err) => {
                    console.log(err);
                    this.$loading.close();
                    this.$toast().show({ content: '获取门店列表失败,请稍后再试' });
                });
            }

        },
        onClickBtn(e) {
            if (e == 'ok') {
                if(!this.selectBar){
                    this.$toast().show({ content: '请输入所属门店名称' });
                    return
                }else if (typeof (this.selectBar) == 'string') {
                    this.selectBar = { id: -1, name: this.selectBar, status: "1" }
                }
                this.$loading.show();
                this.ext.tag = this.qTypeList[this.selectQTypeIndex];
                this.ext.tag_id = this.ext.tag.id;
                this.ext.version_id = this.versionList[this.selectVersionIndex].id;
                this.ext.chain_id = this.selectChain.chain_id;
                this.ext.chain_name = this.selectChain.name;
                this.ext.mch_id = this.selectBar.id;
                this.ext.mch_name = this.selectBar.name;
                let info = {
                    meeting_id: this.ext.meeting_id,
                    name: this.ext.name,
                    mobile: this.ext.mobile,
                    tag_id: this.ext.tag_id,
                    version_id: this.ext.version_id,
                    chain_id: this.ext.chain_id,
                    chain_name: this.ext.chain_name,
                    mch_id: this.ext.mch_id,
                    mch_name: this.ext.mch_name,
                    is_track: this.ext.is_track,
                    remark: this.ext.remark
                }
                if (this.type == 'edit') {
                    info['id'] = this.ext.id;
                }
                // console.log(info);
                // return;
                updateSessionReport(info, (ok) => {
                    if (ok.code == 0) {
                        this.$loading.close();
                        this.callback && this.callback(e, this.ext);
                    } else {
                        this.$loading.close();
                        this.$toast().show({ content: ok.msg });
                    }
                }, (err) => {
                    console.log(err);
                    this.$loading.close();
                    this.$toast().show({ content: '会话保存失败,请稍后再试' });
                });
            } else {
                this.callback && this.callback(e, this.ext);
            }
        },
        show(opt) {
            opt = opt || {};
            this.callback = opt['callback'] || '';
            this.type = opt['type'] || '';
            if (opt.hasOwnProperty('ext')) {
                this.ext = opt['ext'];
            } else {
                this.ext = {};
            }
            this.$loading.show({ content: '加载中' });
            getChainList((ok) => {
                if (ok.code == 0) {
                    console.log(this.ext);
                    this.chainList = [{ id: -1, name: "银杏版本", chain_id: -1 }, ...ok.data];
                    if (this.ext['chain_id']) {
                        this.selectChain = this.chainList.find((item => item.chain_id == this.ext['chain_id']));
                        this.onChangeChain(this.selectChain);
                    }
                    getTagList(0, (ok2) => {
                        if (ok2.code == 0) {
                            this.qTypeList = ok2.data.tags;
                            this.selectVersionIndex = this.versionList.findIndex((item) => item.id == this.ext['version_id']);
                            this.selectQTypeIndex = this.qTypeList.findIndex((item) => item.id == this.ext['tag_id']);
                            this.$loading.close();
                        } else {
                            this.$loading.close();
                            this.$toast().show({ content: ok2.msg });
                        }
                    }, (err) => {
                        console.log(err);
                        this.$loading.close();
                        this.$toast().show({ content: '获取类型列表失败,请稍后再试' });
                    });
                } else {
                    this.$loading.close();
                    this.$toast().show({ content: ok.msg });
                }
            }, (err) => {
                console.log(err);
                this.$loading.close();
                this.$toast().show({ content: '获取连锁列表失败,请稍后再试' });
            });
            this.isShow = true;
        },
        close() {
            this.isShow = false;
            this.callback = '';
            this.ext = {};
            this.type = '';
            this.selectVersionIndex = 0;
            this.selectQTypeIndex = 0;
            this.selectChain = '';
            this.selectBar = '';
            this.qTypeList = [];
            this.chainList = [];
            this.barList = [];
        }
    }
}
</script>
  