<template>
  <v-container v-resize="onResizeWindow" class="container-box" fluid>
    <v-row class="main-box">
      <v-card class="list-box hidden-xs-and-down" flat>
        <div class="tab-box">
          <v-tabs color="#00ab55" v-model="sessionTab" @change="onChangeTab">
            <v-tab class="tab-item tab-item-one" :key="'cur'">当前 {{ curSessionList.length }}</v-tab>
            <v-tab class="tab-item" :key="'wait'">排队 {{ waitSessionList.length }}</v-tab>
            <v-tab class="tab-item" :key="'global'">全局 {{ globalSessionList.length }}</v-tab>
          </v-tabs>
        </div>
        <v-divider></v-divider>
        <div class="search-box">
          <!-- <v-select class="select-type" :menu-props="{ offsetY: true }" v-model="sessionKeywordType"
            :items="sessionKeywordTypes" item-text="name" item-value="value" placeholder="请选择" solo flat hide-details>
            <template v-slot:item="{ item }">
              <div class="select-item">{{ item.name }}</div>
            </template>
          </v-select>
          <v-divider class="divider-v" vertical></v-divider> -->
          <!-- <v-text-field class="input-name" v-model="sessionKeyword" placeholder="搜索昵称/门店名称" solo flat
            hide-details></v-text-field> -->


          <v-combobox class="input-name" v-model="selectSearchSession" :filter="filterSearchSession"
                      :items="searchSessionList" placeholder="搜索关键字" item-text="customer.nickname" item-value="item"
                      @change="onChangeSearchSession" solo flat hide-details>
            <template v-slot:item="{ index, item }">
              <v-list-item-avatar width="48" height="48">
                <v-img max-width="48" max-height="48" :src="item.customer.avatar"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="search-title">
                <v-list-item-title class="title99" v-text="item.customer.nickname"></v-list-item-title>
                <v-list-item-subtitle class="title98" v-text="item['search_text']"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-combobox>


          <!-- <v-img max-width="22" max-height="22" :src="`${$globalData.static_url}/home-search.png`"
            @click="onClickSessionSearch" @keyup.enter="onClickSessionSearch"></v-img> -->
        </div>
        <v-tabs-items class="session-box" :style="`height: ${sessionBoxHeight - 39}px;`" v-model="sessionTab">
          <v-tab-item :key="'cur'">
            <v-list>
              <v-list-item-group v-model="curSessionIndex" @change="onChangeSession">
                <v-list-item :ref="`cur_se_${item.id}`" class="session-item" v-for="item in  curSessionList ">
                  <v-badge :class="`session-type ${item.status == 2 ? ' is-offline' : ''}`" :color="item.tag_info.bg"
                           :content="item.tag_info.name" offset-x="32" offset-y="22" avatar overlap left>
                    <v-list-item-avatar width="48" height="48">
                      <v-img max-width="48" max-height="48" :src="item.customer.avatar"></v-img>
                    </v-list-item-avatar>
                  </v-badge>
                  <v-list-item-content class="session-title">
                    <v-list-item-title class="title2">
                      <v-chip class="version" :color="item.customer.version_info.bg"
                              :text-color="item.customer.version_info.text" label>{{
                                item.customer.version_info.name }}</v-chip> <v-chip v-if="item.pause_status"
                              style="margin-left:4px" class="version" color="#f37171" text-color="#fff" label>挂起</v-chip>
                      <span class="name">{{ item.customer.nickname }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="message" v-if="item['dialogue_new']"
                                          v-text="item['dialogue_new'] ? item.dialogue_new.msg.content : ''"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="session-time">
                    <v-list-item-action-text>
                      <!-- <span class="time">{{ item.send_time.split(' ')[1] }}</span> -->
                      <span class="time">{{ item.send_time }}</span>
                      <v-icon v-if="item.status == 0 && item.send_time_diff > 5"
                              class="clock">mdi-clock-time-five</v-icon>
                    </v-list-item-action-text>
                    <v-icon class="dot"
                            v-if="item['dialogue_new'] && !item['dialogue_new']['is_read']">mdi-circle-medium</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>
          <v-tab-item :key="'wait'">
            <v-list>
              <v-list-item-group v-model="waitSessionIndex" @change="onChangeSession">
                <v-list-item :ref="`wait_se_${item.id}`" class="session-item" v-for=" item  in  waitSessionList ">
                  <v-hover v-slot="{ hover }">
                    <div class="hover-box">
                      <v-badge :class="`session-type ${item.status == 2 ? ' is-offline' : ''}`" :color="item.tag_info.bg"
                               :content="item.tag_info.name" offset-x="32" offset-y="22" avatar overlap left>
                        <v-list-item-avatar width="48" height="48">
                          <v-img max-width="48" max-height="48" :src="item.customer.avatar"></v-img>
                        </v-list-item-avatar>
                      </v-badge>
                      <v-list-item-content class="session-title">
                        <v-list-item-title class="title2">
                          <v-chip class="version" :color="item.customer.version_info.bg"
                                  :text-color="item.customer.version_info.text" label>{{
                                    item.customer.version_info.name }}</v-chip>
                          <span class="name">{{ item.customer.nickname }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="message" v-if="item['dialogue_new']"
                                              v-text="item['dialogue_new'] ? item.dialogue_new.msg.content : ''"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="session-time">
                        <v-list-item-action-text>
                          <span class="time">{{ `${$moment(item.created_at).from(myNowTime, true)} ` }}</span>
                          <v-icon v-if="item.status == 0 && $moment(myNowTime).diff(item.created_at, 'minutes') > 5"
                                  class="clock">mdi-clock-time-five</v-icon>
                        </v-list-item-action-text>
                        <v-icon class="dot"
                                v-if="item['dialogue_new'] && !item['dialogue_new']['is_read']">mdi-circle-medium</v-icon>
                      </v-list-item-action>
                      <div class="enter" v-if="userInfo.is_online == 1 && hover">
                        <v-btn color="primary" @click.stop="onClickEnter(item)">接入</v-btn>
                      </div>
                    </div>
                  </v-hover>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>
          <v-tab-item :key="'global'">
            <v-list>
              <v-list-item-group v-model="globalSessionIndex" @change="onChangeSession">
                <v-list-item :ref="`global_se_${item.id}`" class="session-item" v-for=" item  in  globalSessionList ">
                  <v-badge :class="`session-type ${item.status == 2 ? ' is-offline' : ''}`" :color="item.tag_info.bg"
                           :content="item.tag_info.name" avatar overlap left>
                    <v-list-item-avatar width="48" height="48">
                      <v-img max-width="48" max-height="48" :src="item.customer.avatar"></v-img>
                    </v-list-item-avatar>
                  </v-badge>
                  <v-list-item-content class="session-title">
                    <v-list-item-title class="title2">
                      <v-chip class="version" :color="item.customer.version_info.bg"
                              :text-color="item.customer.version_info.text" label>{{
                                item.customer.version_info.name }}</v-chip>
                      <v-chip v-if="item.pause_status" style="margin-left:4px" class="version" color="#f37171"
                              text-color="#fff" label>挂起</v-chip>
                      <span class="name">{{ item.customer.nickname }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="message" v-if="item['dialogue_new']"
                                          v-text="item['dialogue_new'] ? item.dialogue_new.msg.content : ''"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="session-time">
                    <v-list-item-action-text>
                      <span class="time">{{ item.send_time }}</span>
                      <v-icon v-if="item.status == 0 && item.send_time_diff > 5"
                              class="clock">mdi-clock-time-five</v-icon>
                    </v-list-item-action-text>
                    <v-icon class="dot"
                            v-if="item['dialogue_new'] && !item['dialogue_new']['is_read']">mdi-circle-medium</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
        <div class="tip-box">
          <span class="key-tip">按 Ctrl+Alt+1 跳转到最后回复的会话</span>
        </div>
      </v-card>
      <v-card class="chat-box hidden-md-and-down" flat>
        <div v-if="!curSession" class="no-seesion">
          暂无会话
        </div>
        <div v-else>
          <div class="tool-box">
            <v-toolbar flat>
              <v-avatar :class="`avatar-box ${curSession.status == 2 ? ' is-offline' : ''}`">
                <img :src="curSession.customer.avatar">
              </v-avatar>
              <v-toolbar-title class="tool-title">
                <v-chip class="type" :color="curSession.tag_info.bg" label>{{ curSession.tag_info.name }}</v-chip>
                <span class="name">{{ curSession.customer.nickname }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom v-if="curSession.pause_status && curSession.session_tab == 0 && curSession.status == 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="setMsgLock(0)" v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-play-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>取消暂停</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!curSession.pause_status && curSession.session_tab == 0 && curSession.status == 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="setMsgLock(1)" v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-stop-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>暂停会话</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="onClickTool" v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <span>工具</span>
              </v-tooltip>
              <v-tooltip v-if="curSession.status == 1 && curSession.session_tab == 0" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="onClickShare" v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-share</v-icon>
                  </v-btn>
                </template>
                <span>转交</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="onClickClose" v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ curSession.status == 1 && curSession.session_tab == 0 ? '结束会话' : '关闭窗口' }}</span>
              </v-tooltip>
            </v-toolbar>
          </div>
          <v-divider></v-divider>
          <div class="msg-box" ref="MsgBox" v-scroll.self="onScrollMsg" :style="`height: ${msgBoxHeight - 13}px;`">
            <v-list color="transparent" flat>
              <v-list-item :ref="`msg_${item.id}`" :class="`msg-item${item['msg_origin'] != 3 ? ' right' : ''}`"
                           v-for=" item  in  curSession.msg_list ">
                <v-list-item-avatar v-if="item['msg_origin'] == 3" class="avatar" width="32" height="32">
                  <v-img max-width="32" max-height="32" :src="curSession.customer.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="msg-title">
                  <v-list-item-title class="name"
                                     v-text="`${item['msg_origin'] != 3 ? (item['user_id'] == userInfo.id ? '我' : item['name']) : curSession.customer.nickname},${$moment(item.created_at).from(myNowTime)}`"></v-list-item-title>
                  <div class="msg-load">
                    <v-progress-circular v-if="item['msg_origin'] != 3 && !item['is_send']" class="load" size="15"
                                         width="2" color="green" indeterminate></v-progress-circular>
                    <v-icon class="load" v-else-if="item['msg_origin'] != 3 && item['is_send'] == 2"
                            color="red">mdi-exclamation-thick</v-icon>
                    <v-list-item-subtitle class="message" v-if="item.msg_type == 'text'" v-html="item.msg.html">

                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.msg_type == 'merged_msg'">
                      <div class="btn_msg" @click="showMsgList(item)">
                        <div class="t_head">{{ item.msg.title }}</div>
                        <div class="t_msg">点击查看详情</div>
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="message" v-else-if="item.msg_type == 'image'">
                      <viewer :images="item.msg.htmls">
                        <img class="msg-img" :src="item.msg.html" @load="onLoadImg(item)" />
                      </viewer>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="message" v-else-if="item.msg_type == 'voice'">
                      <div v-if="curRecPlay != item.id">
                        <v-btn @click="onClickRecPlay(item)" small icon>
                          <v-icon>mdi-play-circle</v-icon>
                        </v-btn>
                      </div>
                      <div v-else class="rec-play">
                        <v-btn @click="onClickRecStop" small icon>
                          <v-icon color="primary">mdi-stop-circle</v-icon>
                        </v-btn>
                        <v-progress-linear :value="curRecPlayPos"></v-progress-linear>
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="message" v-else-if="item.msg_type == 'video'">
                      <div>
                        <vue-plyr :options="plyrOptions">
                          <video controls crossorigin playsinline>
                            <source :src="item.msg.html" :key="item.msg.html" />
                          </video>
                        </vue-plyr>
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="message" v-else-if="item.msg_type == 'file'">
                      <div class="file-down" @click="onClickDown(item)">
                        <v-icon color="primary" small>mdi-file-document</v-icon>
                        <span>{{ item.msg.name }}</span>
                      </div>
                    </v-list-item-subtitle>
                    <v-progress-circular v-if="item['msg_origin'] == 3 && !item['is_recv']" class="load" size="15"
                                         width="2" color="green" indeterminate></v-progress-circular>
                    <v-icon class="load" v-else-if="item['msg_origin'] == 3 && item['is_recv'] == 2"
                            color="red">mdi-exclamation-thick</v-icon>
                  </div>
                </v-list-item-content>
                <v-list-item-avatar v-if="item['msg_origin'] != 3" class="avatar" width="32" height="32">
                  <v-img max-width="32" max-height="32"
                         :src="item.avatar.length == 1 ? `${$globalData.static_url}/${item.avatar}.jpg` : item.avatar"></v-img>
                </v-list-item-avatar>
              </v-list-item>
              <div ref="BottomBox"></div>
            </v-list>
          </div>
          <div class="divider-resize" @mousedown="onMDDivder"></div>
          <div class="input-box"
               v-if="debug || userInfo.is_online != 0 && curSession && curSession.status == 1 && curSession.session_tab == 0"
               :style="`height: ${inputBoxHeight}px;`">
            <v-toolbar class="quick" flat>
              <v-btn @click="onClickQuick" small icon>
                <v-icon>mdi-chevron-up-circle-outline</v-icon>
              </v-btn>
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small icon>
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-toolbar>
            <div class="send-box">
              <v-menu v-model="emojiMenu" top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="menu-item" v-bind="attrs" v-on="on" icon>
                    <v-img max-width="18" max-height="18" :src="`${$globalData.static_url}/home-emoji.png`"></v-img>
                  </v-btn>
                </template>
                <v-card class="emoji-box" flat>
                  <v-btn class="emoji-item" v-for=" item  in  emojiList " @click="onClickEmoji" icon>{{ item }}</v-btn>
                </v-card>
              </v-menu>
              <div id="toolbar"></div>
              <quill-editor class="editor" :style="`height: ${inputBoxHeight - 40}px;`" v-model="myContent"
                            ref="myQuillEditor" :options="editorOption" @change="onChangeEditor" @blur="onBlurEditor"
                            @focus="onFocusEditor" @ready="onReadyEditor" />
              <input style="display: none;" ref="uploadImg" @change="onChangeImg" accept="image/jpeg,image/png,video/mp4"
                     type="file">
              <input style="display: none;" ref="uploadFile" @change="onChangeFile" type="file">
              <v-btn class="menu-item" @click="onClickImg" icon>
                <v-img max-width="18" max-height="18" :src="`${$globalData.static_url}/home-pic.png`"></v-img>
              </v-btn>
              <v-btn class="menu-item" @click="onClickFile" icon>
                <v-img max-width="18" max-height="18" :src="`${$globalData.static_url}/home-file.png`"></v-img>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
      <div v-if="curSession" class="action-box hidden-lg-and-down">
        <v-card class="info-box" flat>
          <div class="tab-box">
            <v-tabs color="#00ab55" v-model="actionTab">
              <v-tab class="tab-item tab-item-one" :key="'info'">客户信息</v-tab>
              <v-tab class="tab-item" :key="'data'">资料库</v-tab>
            </v-tabs>
          </div>
          <v-divider></v-divider>
          <v-tabs-items class="user-box" v-model="actionTab">
            <v-tab-item :key="'info'">
              <div class="item-box">
                <div class="info-item">
                  <div class="r">
                    <div>
                      <v-img max-width="16" max-height="16" :src="`${$globalData.static_url}/home-user.png`"></v-img>
                    </div>
                    <span class="title2">客户姓名</span>
                  </div>
                  <v-hover v-slot="{ hover }">
                    <div :class="`r right${hover ? ' pl-2' : ''}`">
                      <v-btn v-if="hover" @click="onClickEdit('name')" x-small icon>
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <span class="text2">{{ curUserInfo.name }}</span>





                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip class="version" :color="curSession.customer.version_info.bg"
                                  :text-color="curSession.customer.version_info.text" v-bind="attrs" v-on="on" label>{{
                                    curSession.customer.version_info.name
                                  }}</v-chip>
                        </template>
                        <v-list>
                          <v-list-item-group v-model="versionIndex" @change="onChangeVersion" mandatory>
                            <v-list-item v-for=" item  in  versionList ">
                              <v-chip class="version" :color="item.bg" :text-color="item.text" label>{{ item.name
                              }}</v-chip>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>






                    </div>
                  </v-hover>
                </div>
                <div class="info-item">
                  <div class="r">
                    <div>
                      <v-img max-width="16" max-height="16" :src="`${$globalData.static_url}/home-phone.png`"></v-img>
                    </div>
                    <span class="title2">电话号码</span>
                  </div>
                  <v-hover v-slot="{ hover }">
                    <div :class="`r right${hover ? ' pl-2' : ''}`">
                      <v-btn v-if="hover" @click="onClickEdit('phone')" x-small icon>
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <span class="text2">{{ curUserInfo.mobile }}</span>
                    </div>
                  </v-hover>
                </div>
                <div class="info-item">
                  <div class="r">
                    <div>
                      <v-img max-width="16" max-height="16" :src="`${$globalData.static_url}/home-home.png`"></v-img>
                    </div>
                    <span class="title2">所属连锁</span>
                  </div>
                  <v-hover v-slot="{ hover }">
                    <div :class="`r right${hover ? ' pl-2' : ''}`">
                      <v-btn v-if="hover" @click="onClickEdit('chain')" x-small icon>
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <span class="text2">{{ curUserInfo.chain_name }}</span>
                    </div>
                  </v-hover>
                </div>
                <div class="info-item">
                  <div class="r">
                    <div>
                      <v-img max-width="16" max-height="16" :src="`${$globalData.static_url}/home-computer.png`"></v-img>
                    </div>
                    <span class="title2">所属门店</span>
                  </div>
                  <v-hover v-slot="{ hover }">
                    <div :class="`r right${hover ? ' pl-2' : ''}`">
                      <v-btn v-if="hover" @click="onClickEdit('bar')" x-small icon>
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <span class="text2">{{ curUserInfo.mch_name }}</span>
                    </div>
                  </v-hover>
                </div>
                <div class="info-item">
                  <div class="r">
                    <div>
                      <v-icon style="font-size: 18px;color: #c0cad2;">mdi-account-box</v-icon>
                    </div>
                    <span class="title2">职位</span>
                  </div>
                  <v-hover v-slot="{ hover }">
                    <div class="r right">
                      <span class="text2">{{ jobObj[curUserInfo.job_id] }}</span>
                    </div>
                  </v-hover>
                </div>
                <div class="info-item" style="width: 100%;">
                  <div class="r">
                    <div>
                      <v-img max-width="16" max-height="16" :src="`${$globalData.static_url}/home-pen.png`"></v-img>
                    </div>
                    <span class="title2">备注信息</span>
                  </div>
                  <div class="r right right2">
                    <div class="mark-box">
                      <v-textarea class="mark" rows="3" placeholder="请在这里输入备注信息" v-model="userRemark" @blur="onBlurRemark"
                                  solo flat hide-details auto-grow></v-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item :key="'data'">
              <div class="search-box">
                <v-text-field class="input-name" v-model="dataKeyword" @keyup.enter="onClickDataSearch"
                              placeholder="搜索关键字" solo flat hide-details></v-text-field>
                <v-img max-width="22" max-height="22" :src="`${$globalData.static_url}/home-search.png`"
                       @click="onClickDataSearch"></v-img>
              </div>
              <v-list class="list-box" v-scroll.self="onScrollData">
                <v-list-item-group mandatory>
                  <v-list-item v-for=" item  in  dataList " @click="onClickData(item)">
                    <v-list-item-content>
                      <v-list-item-title class="title2" v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <div class="history-box" :style="`height: ${historyBoxHeight - 20}px;`">
          <v-card class="history-item" v-for=" item  in  curFiveHistoryList " flat>
            <div class="item-box">
              <div class="r">
                <div>
                  <v-img v-if="item['comment']" max-width="18" max-height="18"
                         :src="`${$globalData.static_url}/home-star.png`">{{ item['comment']['score']
                         }}</v-img>
                </div>
                <span class="time">{{ item.created_at }}</span>
                <v-spacer></v-spacer>
                <v-chip class="type" color="#cafdf5" label>客服</v-chip>
                <span class="name">{{ item.user.name }}</span>
              </div>
              <div class="history">
                <ul class="msg-box">
                  <li v-for=" msg  in  item.dialogue " @click="onClickHistory(msg)">{{ msg.msg.content }}</li>
                </ul>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </v-row>
    <DialogQuickList ref="DialogQuickList"></DialogQuickList>
    <DialogEdit ref="DialogEdit"></DialogEdit>
    <DialogSessionReport ref="DialogSessionReport"></DialogSessionReport>
    <DialogOnlineService ref="DialogOnlineService"></DialogOnlineService>
    <DialogDataEdit ref="DialogDataView"></DialogDataEdit>
    <DialogWxMsg v-if="dialogWxMsg" :itemObj="itemObj" @close="dialogWxMsg = false"></DialogWxMsg>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
import { ImageDrop } from 'quill-image-drop-module'
import Delta from 'quill-delta'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
// import { convertHtmlToDelta } from 'node-quill-converter'
// const { convertHtmlToDelta } = require('node-quill-converter');

import BenzAMRRecorder from "benz-amr-recorder"
import quillExt from '../global/quillExt'

import DialogWxMsg from '../components/DialogWxMsg/DialogWxMsg'
import DialogQuickList from '../components/DialogQuickList/DialogQuickList'
import DialogEdit from '../components/DialogEdit/DialogEdit'
import DialogSessionReport from '../components/DialogSessionReport/DialogSessionReport'
import DialogOnlineService from '../components/DialogOnlineService/DialogOnlineService'
import DialogDataEdit from '../components/DialogDataEdit/DialogDataEdit'
import {
  getUserInfo,
  updateUserInfo,
  MSG_TYPE_TEXT, MSG_TYPE_IMAGE, MSG_TYPE_VOICE, MSG_TYPE_VIDEO, MSG_TYPE_FILE, MSG_TYPE_MERGED,
  SESSION_STATUS_WAIT, SESSION_STATUS_IN, SESSION_STATUS_END,
  getArticleList,
  uploadMedia,
  getFiveHistoryList,
  SYS_VERSION_LIST,
  QA_TYPE_LIST,
  getFileFromUrl,
  uploadQuickFile,
  getFileFromBase64,
  getUrlFromFile,
  setMsgLock
} from '../global/api'

Quill.register('modules/ImageExtend', ImageExtend)
Quill.register('modules/imageDrop', ImageDrop)
Quill.register(quillExt.VideoBlot)
Quill.register(quillExt.ImgBlot)
Quill.register(quillExt.FileBlot)
export default {
  name: 'Home',
  components: {
    quillEditor,
    DialogQuickList,
    DialogEdit,
    DialogSessionReport,
    DialogOnlineService,
    DialogDataEdit,
    DialogWxMsg
  },
  data: () => ({
    jobObj: {
      0: '暂无',
      1: '银杏经理',
      2: '总经理',
      3: '大区经理',
      4: '店长',
      5: '店长助理',
      6: '领班',
      7: '收银员',
      8: '服务员',
      9: '保洁员',
      10: '厨房厨师',
      11: '水吧厨师',
      12: '管理员',
      13: '财务',
      14: '业主',
      15: '督导员',
      16: '库管员',
      17: '技术员'
    },
    dialogWxMsg: false,
    itemObj: {},//合并消息对象 
    debug: process.env.NODE_ENV == 'development',
    clipHandle: '',
    myQuill: '',
    myNowTime: '',
    myTimer: '',
    myText: '',
    myContent: '',
    myHtml: '',
    myContents: '',
    selectSearchSession: '',
    searchSessionList: [],
    plyrOptions: {
      controls: [, 'play-large', 'progress', 'current-time', 'mute', 'volume', 'pip', 'fullscreen']
    },
    oldCurSession: '',
    curRecPlayPos: 0,
    curRecPlay: '',
    recPlayer: '',
    recPlayerTimer: '',
    dataPage: 1,
    dataKeyword: '',
    dataTotal: 0,
    dataList: [],
    msgBoxScrollHeight: 0,
    tempSession: '',
    limitMsg: 20,
    userRemark: '',
    windowSize: {
      w: 0,
      h: 0
    },
    actionTab: 0,
    emojiList: [
      "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍", "🥰", "😘", "😗", "😙", "😚", "😋", "😛", "😝", "😜", "🤪", "🤨", "🧐", "🤓", "😎", "🤩", "🥳", "😏", "😒", "😞", "😔", "😟", "😕", "🙁", "😣", "😖", "😫", "😩", "🥺", "😢", "😭", "😤", "😠", "😡", "🤬", "🤯", "😳", "🥵", "🥶", "😱", "😨", "😰", "😥", "😓", "😲", "😮", "😧", "😦", "😯", "🙄", "😬", "😑", "😐", "😶", "🤥", "🤫", "🤭", "🤔", "🤗", "😴", "🤤", "😪", "😵", "🤐", "🥴", "🤢", "🤮", "🤧", "😷", "🤒", "🤕", "🤑", "🤠", "😈", "😹", "😸", "😺", "🎃", "🤖", "👾", "👽", "☠", "💀", "👻", "💩", "🤡", "👺", "👹", "👿", "😻", "😼", "😽", "🙀", "😿", "😾", "🤲", "👐", "🙌", "👏", "🤝", "👍", "👎", "👊", "✊", "🤛", "🤜", "🤞", "✌", "🤟", "🤘", "✍", "🖕", "💪", "🤙", "👋", "🖖", "🖐", "🤚", "✋", "☝", "👇", "👆", "👉", "👈", "👌", "🙏", "🦶", "🦵", "💄", "💋", "👄", "🦷", "👅", "👂", "👃", "👣", "👁", "👀", "🧠", "🗣"
    ],
    emojiMenu: '',
    historyBoxDiffHeight: 450,
    historyMaxBoxHeight: 0,
    sessionBoxDiffHeight: 317,
    sessionMaxBoxHeight: 0,
    chatBoxDiffHeight: 218,
    chatMaxBoxHeight: 0,
    msgBoxRatio: 0.78,
    inputBoxRatio: 0.22,
    boxHeight: '',
    resizeY: '',
    editorOption: '',
    // messageIndex: '',
    curFiveHistoryList: [],
    curSession: '',
    curUserInfo: '',
    curHistory: '',
    sessionTab: 0,
    globalSessionIndex: -1,
    globalSessionList: [],
    waitSessionIndex: -1,
    waitSessionList: [],
    curSessionIndex: -1,
    curSessionList: [],
    versionIndex: 0,
    versionList: [...SYS_VERSION_LIST],
    qaList: [...QA_TYPE_LIST],
    sessionKeyword: '',
    sessionKeywordType: 'all',
    sessionKeywordTypes: [
      {
        name: '全部类型',
        value: 'all'
      },
      {
        name: '未知',
        value: 'none'
      },
      {
        name: '售前咨询',
        value: 'before'
      },
      {
        name: '售后咨询',
        value: 'after'
      },
      {
        name: '需求反馈',
        value: 'demand'
      },
      {
        name: '投诉',
        value: 'complaint'
      },
      {
        name: '已结束',
        value: 'end'
      }
    ],
  }),
  computed: {
    ...mapState([
      'userInfo'
    ]),
    historyBoxHeight () {
      return this.historyMaxBoxHeight * 1
    },
    sessionBoxHeight () {
      return this.sessionMaxBoxHeight * 1
    },
    msgBoxHeight () {
      return this.chatMaxBoxHeight * this.msgBoxRatio
    },
    inputBoxHeight () {
      return this.chatMaxBoxHeight * this.inputBoxRatio
    },
    editor () {
      return this.myQuill
      // return this.$refs.myQuillEditor.quill;
    }
  },
  beforeDestroy () {
    this.$bus.off(this, '$deliver')
    this.$bus.off(this, 'msg')
    this.$bus.off(this, 'finish')
    this.$bus.off(this, 'file')
    this.$bus.off(this, 'new_msg')
    this.$bus.off(this, 'connect')
    this.$bus.off(this, 'new_meeting')
    this.$bus.off(this, 'meeting_pause')
    this.$bus.off(this, 'meeting')
    this.$bus.off(this, 'history')
    this.$bus.off(this, '$onclose')
    this.$bus.off(this, '$onopen')
    this.bindHotKey(false)
    this.bindClip(false)
    if (this.myTimer) {
      clearInterval(this.myTimer)
    }
  },
  created () {
    this.myNowTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
    this.editorOption = {
      placeholder: '请在这里输入要发送的消息,按 Ctrl+Enter 键发送',
      modules: {
        keyboard: {
          bindings: {
            sendMsg: {
              key: 13,
              ctrlKey: true,
              handler: (range, context) => {
                this.enterMsg(range, context)
              }
            }
          }
        },
        clipboard: {
          matchers: [
            [
              Node.ELEMENT_NODE,
              (node, delta) => {
                // console.log('clipboard',delta);
                delta.ops = delta.ops.map((op) => {
                  if (op.insert['image']) {
                  } else if (op.insert['video']) {
                  } else if (op.insert['file']) {
                  } else {
                    op = { insert: op.insert }
                  }
                  return op
                })
                delta.ops = delta.ops.filter((op) => {
                  if (op.insert['image']) {
                    if (op.insert.image.indexOf(';base64,') != -1) {
                      return false
                    } else if (op.insert.image.indexOf(this.$globalData.file_url) != -1) {
                      return false
                    } else if (op.insert.image.indexOf('http') != -1) {
                      return false
                    } else {
                      return false
                    }
                  } else if (op.insert['video']) {
                    if (op.insert.video.indexOf(';base64,') != -1) {
                      return false
                    } else if (op.insert.video.indexOf(this.$globalData.file_url) != -1) {
                      return false
                    } else if (op.insert.video.indexOf('http') != -1) {
                      return false
                    } else {
                      return false
                    }
                  } else if (op.insert['file']) {
                    if (op.insert.file.indexOf(';base64,') != -1) {
                      return false
                    } else if (op.insert.file.indexOf(this.$globalData.file_url) != -1) {
                      return false
                    } else if (op.insert.file.indexOf('http') != -1) {
                      return false
                    } else {
                      return false
                    }
                  } else {
                    op = { insert: op.insert }
                  }
                  return true
                })
                return delta
              }
            ]
          ]
        },
        toolbar: {
          container: '#toolbar'
        },
        ImageExtend: {
          loading: false,
          name: 'file',
          // action: process.env.VUE_APP_API_URL + '/backend/upload',
          action: 'local',
          local: (file) => {
            // console.log('local',file);
            this.insertFile(file)
          },
          change: (xhr, formData) => {
            xhr.setRequestHeader('Authorization', this.$axios.defaults.headers.common['Authorization'])
          },
          response: (res) => {
            return this.$globalData.file_url + res.data.pic
          }
        },
        imageDrop: false,
      }
    }
  },
  mounted () {

    this.onResizeWindow()

    this.$bus.on(this, '$deliver', () => {
      this.curSessionList.forEach((item) => {
        item['status'] = SESSION_STATUS_END
        this.formatStatus(item)
      })
    })

    this.$bus.on(this, 'msg', (data, ext, status) => {
      if (!data || status == 0) {
        return
      }
      if (this.curSession && this.curSession.external_userid == data.external_userid) {
        if (this.curSession['is_pull']) {
          if (data['user_id'] == this.userInfo.id && data['uuid']) {
            let msgIndex = this.curSession.msg_list.findIndex((item) => item['uuid'] == data['uuid'])
            if (msgIndex != -1) {
              let msg = this.curSession.msg_list[msgIndex]
              msg['is_send'] = 2
              this.$set(this.curSession.msg_list, msgIndex, msg)
            }
          }
        }
        console.log('msg curSession', this.curSession)
      } else {
        let session = this.curSessionList.find((item) => item.external_userid == data.external_userid)
        if (session) {
          if (session['is_pull']) {
            if (data['user_id'] == this.userInfo.id && data['uuid']) {
              let msgIndex = session.msg_list.findIndex((item) => item['uuid'] == data['uuid'])
              if (msg != -1) {
                let msg = session.msg_list[msgIndex]
                msg['is_send'] = 2
                this.$set(session.msg_list, msgIndex, msg)
              }
            }
          }
          console.log('msg session', session)
        }
      }
    })


    this.$bus.on(this, 'finish', (data, ext, status) => {
      if (!data) {
        return
      }
      
      // let sessionIndex = this.curSessionList.findIndex(item => item.id == data.meeting_id)
      // if(sessionIndex != -1){
      //   this.curSessionList[sessionIndex].pause_status=0;
      // }

      // sessionIndex = this.globalSessionList.findIndex(item => item.id == data.meeting_id)
      // if(sessionIndex != -1){
      //   this.globalSessionList[sessionIndex].pause_status=0;
      // }


      let cur = -1
      if (this.curSession && this.curSession.id == data.meeting_id && this.curSession.session_tab != 1) {
        cur = this.curSession.session_tab
        if(cur == 0 || cur == 2){
          this.curSession.pause_status = 0;
        }
        this.curSession['status'] = SESSION_STATUS_END
        this.formatStatus(this.curSession)
        let session = this.curSession;
        this.curSessionList.splice(this.curSessionIndex, 1)
        this.curSessionList.push (session);
        this.curSession = ''
        this.curSessionIndex = -1
        console.log('finish curSession', session)
      }

      if (cur != 2) {
        let session = this.globalSessionList.find((item) => item.id == data.meeting_id)
        if (session) {
          session.pause_status = 0;
          session['status'] = SESSION_STATUS_END
          this.formatStatus(session)
          console.log('finish session', session)
        }
      }

      if (cur != 0) {
        let session = this.curSessionList.find((item) => item.id == data.meeting_id)
        if (session) {
          session.pause_status = 0;
          session['status'] = SESSION_STATUS_END
          this.formatStatus(session)
          console.log('finish session', session)
        }
      }
    })

    this.$bus.on(this, 'file', (data, ext, status) => {
      if (!data) {
        return
      }
      this.formatMsgData(data)

      let cur = -1
      if (this.curSession && this.curSession.external_userid == data.external_userid) {
        cur = this.curSession.session_tab
        if (this.curSession['is_pull']) {
          let msgIndex = this.curSession.msg_list.findIndex((item) => item['id'] == data['id'])
          if (msgIndex != -1) {
            let msg = this.curSession.msg_list[msgIndex]
            msg['msg'] = data['msg']
            msg['msg_content'] = data['msg_content']
            msg['is_recv'] = 1
            this.$set(this.curSession.msg_list, msgIndex, msg)
            this.scrollMsgBoxBottom(false, 1000)
          }
        }
      }

      if (cur != 0) {
        let session = this.curSessionList.find((item) => item.external_userid == data.external_userid)
        if (session) {
          if (session['is_pull']) {
            let msgIndex = session.msg_list.findIndex((item) => item['id'] == data['id'])
            if (msgIndex != -1) {
              let msg = session.msg_list[msgIndex]
              msg['msg'] = data['msg']
              msg['msg_content'] = data['msg_content']
              msg['is_recv'] = 1
              this.$set(session.msg_list, msgIndex, msg)
            }
          }
        }
      }

      if (cur != 1) {
        let session = this.waitSessionList.find((item) => item.external_userid == data.external_userid)
        if (session) {
          if (session['is_pull']) {
            let msgIndex = session.msg_list.findIndex((item) => item['id'] == data['id'])
            if (msgIndex != -1) {
              let msg = session.msg_list[msgIndex]
              msg['msg'] = data['msg']
              msg['msg_content'] = data['msg_content']
              msg['is_recv'] = 1
              this.$set(session.msg_list, msgIndex, msg)
            }
          }
        }
      }

      if (cur != 2) {
        let session = this.globalSessionList.find((item) => item.external_userid == data.external_userid)
        if (session) {
          if (session['is_pull']) {
            let msgIndex = session.msg_list.findIndex((item) => item['id'] == data['id'])
            if (msgIndex != -1) {
              let msg = session.msg_list[msgIndex]
              msg['msg'] = data['msg']
              msg['msg_content'] = data['msg_content']
              msg['is_recv'] = 1
              this.$set(session.msg_list, msgIndex, msg)
            }
          }
        }
      }

    })


    this.$bus.on(this, 'new_msg', (data, ext, status) => {
      if (!data) {
        return
      }
      data['is_read'] = 0
      this.formatMsgData(data)
      let cur = -1
      if (this.curSession && this.curSession.external_userid == data.external_userid) {
        cur = this.curSession.session_tab
        data['is_read'] = 1
        this.curSession.dialogue_new = data

        this.formatSessionTime(this.curSession, this.curSession.dialogue_new)

        if (this.curSession['is_pull']) {
          if (data['user_id'] == this.userInfo.id && data['uuid']) {
            let msgIndex = this.curSession.msg_list.findIndex((item) => item['uuid'] == data['uuid'])
            if (msgIndex != -1) {
              let msg = this.curSession.msg_list[msgIndex]
              msg['is_send'] = 1
              this.$set(this.curSession.msg_list, msgIndex, msg)
            }
          } else {
            data['is_send'] = 1
            this.curSession.msg_list.push(data)
          }
          console.log('new_msg curSession', this.curSession.msg_list)
          if (cur == 0 || cur == 2) {
            // this.curSessionList[this.curSessionIndex].pause_status = 0
            this.curSession.pause_status = 0
          }
          this.scrollMsgBoxBottom(false, data.msg_type != MSG_TYPE_TEXT ? 1000 : 500)
        }
      }

      console.log(`new_msg cur:${cur}`)

      if (cur != 1) {
        let session = this.waitSessionList.find((item) => item.external_userid == data.external_userid)
        if (session) {
          session.dialogue_new = data
          this.formatSessionTime(session, session.dialogue_new)

          if (session['is_pull']) {
            if (data['user_id'] == this.userInfo.id && data['uuid']) {
              let msgIndex = session.msg_list.findIndex((item) => item['uuid'] == data['uuid'])
              if (msgIndex != -1) {
                let msg = session.msg_list[msgIndex]
                msg['is_send'] = 1
                this.$set(session.msg_list, msgIndex, msg)
              }
            } else {
              data['is_send'] = 1
              session.msg_list.push(data)
            }
            console.log('new_msg session wait', session.msg_list)
          }
          return
        }
      }

      if (cur != 0) {
        let session = this.curSessionList.find((item) => item.external_userid == data.external_userid)
        if (session) {
          session.dialogue_new = data
          session.pause_status = 0;
          this.formatSessionTime(session, session.dialogue_new)

          if (session['is_pull']) {
            if (data['user_id'] == this.userInfo.id && data['uuid']) {
              let msgIndex = session.msg_list.findIndex((item) => item['uuid'] == data['uuid'])
              if (msgIndex != -1) {
                let msg = session.msg_list[msgIndex]
                msg['is_send'] = 1
                this.$set(session.msg_list, msgIndex, msg)
              }
            } else {
              data['is_send'] = 1
              session.msg_list.push(data)
            }
            console.log('new_msg session cur', session.msg_list)
          }
        }
      }

      if (cur != 2) {
        let session = this.globalSessionList.find((item) => item.external_userid == data.external_userid)
        if (session) {
          session.dialogue_new = data
          session.pause_status = 0;
          this.formatSessionTime(session, session.dialogue_new)

          if (session['is_pull']) {
            if (data['user_id'] == this.userInfo.id && data['uuid']) {
              let msgIndex = session.msg_list.findIndex((item) => item['uuid'] == data['uuid'])
              if (msgIndex != -1) {
                let msg = session.msg_list[msgIndex]
                msg['is_send'] = 1
                this.$set(session.msg_list, msgIndex, msg)
              }
            } else {
              data['is_send'] = 1
              session.msg_list.push(data)
            }
            console.log('new_msg session global', session.msg_list)
          }
        }
      }

    })

    this.$bus.on(this, 'connect', (data, ext, status) => {
      let sessionIndex = this.waitSessionList.findIndex((item) => item.id == data.meeting_id)
      if (sessionIndex != -1) {
        let moveSession = this.$_.cloneDeep(this.waitSessionList[sessionIndex])
        if (this.curSession && this.curSession['session_tab'] == 1) {
          if (this.curSession.external_userid == moveSession.external_userid) {
            this.curSession['is_bottom'] = 0
            this.curSession = ''
            this.waitSessionIndex = -1
          }
        }
        this.waitSessionList.splice(sessionIndex, 1)
        console.log('connect wait', this.tempSession, this.waitSessionList)
        moveSession.status = SESSION_STATUS_IN
        sessionIndex = this.globalSessionList.findIndex((item) => item.external_userid == moveSession.external_userid)
        if (sessionIndex != -1) {
          this.globalSessionList.splice(sessionIndex, 1)
        }
        this.globalSessionList.unshift(moveSession)
        if (this.curSession && this.curSession['session_tab'] == 2) {
          if (this.curSession.external_userid == moveSession.external_userid) {
            this.curSession = moveSession
            this.curSession['session_tab'] = 2
            this.globalSessionIndex = 0
            this.scrollMsgBoxBottom(true)
          }
        }
      }
      if (!this.tempSession) {
        return
      }
      if (this.tempSession.id != data.meeting_id) {
        return
      }
      this.tempSession.status = SESSION_STATUS_IN
      sessionIndex = this.curSessionList.findIndex((item) => item.external_userid == this.tempSession.external_userid)
      if (sessionIndex != -1) {
        this.curSessionList.splice(sessionIndex, 1)
      }
      this.curSessionList.unshift(this.tempSession)
      if (this.curSession && this.curSession['session_tab'] == 0) {
        this.saveOldSend()
        if (this.curSession.external_userid == this.tempSession.external_userid) {
          this.curSession = this.tempSession
          this.curSession['session_tab'] = 0
          this.curSessionIndex = 0
          this.scrollMsgBoxBottom(true)
        }
      }
      if (this.sessionTab != 0) {
        this.sessionTab = 0
        this.onChangeTab(this.sessionTab)
        console.log('sessionTab', this.sessionTab)
      }
      console.log('connect cur', this.curSessionList)
    })

    this.$bus.on(this, 'new_meeting', (data, ext, status) => {
      if (data['await']) {
        this.formatSessionData(data.await)
        data.await.forEach((item) => {
          let sessionIndex = this.waitSessionList.findIndex((item2) => item2.external_userid == item.external_userid)
          if (sessionIndex != -1) {
            this.waitSessionList.splice(sessionIndex, 1)
            if (this.curSession && this.curSession['session_tab'] == 1) {
              if (this.curSession.external_userid == item.external_userid) {
                this.curSession['is_pull'] = 0
                this.curSession['is_bottom'] = 0
                this.curSession = ''
                this.waitSessionIndex = -1
              }
            }
          }
        })
        this.waitSessionList.push(...data.await)
        console.log("new_meeting waitSessionList", this.waitSessionList)
      }

      if (data['history']) {
        this.formatSessionData(data.history)
        data.history.forEach((item) => {
          let sessionIndex = this.curSessionList.findIndex((item2) => item2.external_userid == item.external_userid)
          if (sessionIndex != -1) {
            this.curSessionList.splice(sessionIndex, 1)
            if (this.curSession && this.curSession['session_tab'] == 0) {
              if (this.curSession.external_userid == item.external_userid) {
                this.curSession['is_pull'] = 0
                this.curSession['is_bottom'] = 0
                this.curSession = ''
                this.curSessionIndex = -1
              }
            }
          }
        })
        this.curSessionList.unshift(...data.history)
        console.log("new_meeting curSessionList", this.curSessionList)
      }
    })

    this.$bus.on(this, 'meeting_pause', (data, ext, status) => {
      console.log('meeting_pause', data, ext, status)

      // this.curSessionList.forEach(item => {
      //   if (item.id == data.meeting_id) {
      //     item.pause_status = status
      //   }
      // })

      // this.globalSessionList.forEach(item => {
      //   if (item.id == data.meeting_id) {
      //     item.pause_status = status
      //   }
      // })

      let sessionIndex = this.curSessionList.findIndex(item => item.id == data.meeting_id)
      if(sessionIndex != -1){
        this.curSessionList[sessionIndex].pause_status=status;
      }

      sessionIndex = this.globalSessionList.findIndex(item => item.id == data.meeting_id)
      if(sessionIndex != -1){
        this.globalSessionList[sessionIndex].pause_status=status;
      }

    })

    this.$bus.on(this, 'meeting', (data, ext, status) => {
      if (data['await']) {
        this.formatSessionData(data.await, 1)
        this.waitSessionList = data.await
        console.log('meeting waitSessionList', this.waitSessionList)
      }
      if (data['history']) {
        this.formatSessionData(data.history, 1)
        this.curSessionList = data.history
        console.log('meeting curSessionList', this.curSessionList)
      }
      if (data['global']) {
        this.formatSessionData(data.global, 1)
        this.globalSessionList = data.global
        console.log('meeting globalSessionList', this.globalSessionList)
      }
      if (!this.curSession) {
        if (this.sessionTab == 0) {
          this.onChangeSession(this.curSessionIndex)
        } else if (this.sessionTab == 1) {
          this.onChangeSession(this.waitSessionIndex)
        } else if (this.sessionTab == 2) {
          this.onChangeSession(this.globalSessionIndex)
        }
      } else {
        if (this.curSession && !this.curSession['is_pull']) {
          this.$bus.emit('$ws_send', 'history', { external_userid: this.curSession.external_userid, limit: this.limitMsg })
        }
      }

      this.$nextTick(() => {
        let curSession = localStorage.getItem('curSession')
        if (curSession) {
          let curSessionObj = JSON.parse(curSession)
          this.gotoSession(curSessionObj)
        } else {
          this.onChangeTab(this.sessionTab)
        }
      })

      this.myTimer = setInterval(() => {
        this.myNowTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      }, 1 * 60 * 1000)

    })

    this.$bus.on(this, 'history', (data, ext, status) => {
      data.forEach((item) => {
        item['is_read'] = 1
        item['is_send'] = 1
        this.formatMsgData(item)
      })
      data = data.sort((a, b) => a.id - b.id)
      let cur = -1
      if (this.curSession && this.curSession.external_userid == ext.external_userid) {
        cur = this.curSession.session_tab
        console.log('history curSession')
        if (!this.curSession['is_pull']) {
          this.curSession['is_pull'] = 1
          this.curSession.msg_list = []
        }
        this.curSession.msg_list.unshift(...data)
        if (ext['end_id']) {
          this.scrollMsgTarget(ext['end_id'])
        } else {
          if (!this.curSession['is_bottom']) {
            this.curSession['is_bottom'] = 1
            this.scrollMsgBoxBottom(true)
          } else {
            this.scrollMsgBoxBottom()
          }
        }
        if (this.curSession.msg_list.length > 0) {
          this.curSession.dialogue_new = this.curSession.msg_list[this.curSession.msg_list.length - 1]
          this.formatSessionTime(this.curSession, this.curSession.dialogue_new)
        }
      }

      if (cur != 0) {
        let session = this.curSessionList.find((item) => item.external_userid == ext.external_userid)
        if (session) {
          console.log('history session cur')
          if (!session['is_pull']) {
            session['is_pull'] = 1
            session.msg_list = []
          }
          session.msg_list.unshift(...data)
          if (!session['is_bottom']) {
            session['is_bottom'] = 1
          }
          if (session.msg_list.length > 0) {
            session.dialogue_new = session.msg_list[session.msg_list.length - 1]
            this.formatSessionTime(session, session.dialogue_new)
          }
        }
      }

      if (cur != 1) {
        let session = this.waitSessionList.find((item) => item.external_userid == ext.external_userid)
        if (session) {
          console.log('history session wait')
          if (!session['is_pull']) {
            session['is_pull'] = 1
            session.msg_list = []
          }
          session.msg_list.unshift(...data)
          if (!session['is_bottom']) {
            session['is_bottom'] = 1
          }
          if (session.msg_list.length > 0) {
            session.dialogue_new = session.msg_list[session.msg_list.length - 1]
            this.formatSessionTime(session, session.dialogue_new)
          }
        }
      }


      if (cur != 2) {
        let session = this.globalSessionList.find((item) => item.external_userid == ext.external_userid)
        if (session) {
          console.log('history session global')
          if (!session['is_pull']) {
            session['is_pull'] = 1
            session.msg_list = []
          }
          session.msg_list.unshift(...data)
          if (!session['is_bottom']) {
            session['is_bottom'] = 1
          }
          if (session.msg_list.length > 0) {
            session.dialogue_new = session.msg_list[session.msg_list.length - 1]
            this.formatSessionTime(session, session.dialogue_new)
          }
        }
      }

    })

    this.$bus.on(this, '$onclose', () => {
      console.log('$onclose')
      if (this.curSession) {
        this.curSession['is_pull'] = 0
        this.curSession['is_bottom'] = 0
      }
    })

    this.$bus.on(this, '$onopen', () => {
      console.log('$onopen')
      this.$bus.emit('$ws_send', 'meeting')
    })
    if (this.$globalData['ws']) {
      this.$bus.emit('$ws_send', 'meeting')
    }

    this.onClickDataSearch()

    this.bindHotKey(true)

    this.clipHandle = async (e) => {
      let clip = event.clipboardData
      if (clip) {

        console.log(clip.items, clip.types)
        // let items = clip.items
        // if (items || items.length > 0) {
        //   for (let i = 0; i < items.length; i++) {
        //     let item = items[i];
        //     if (item.kind == 'file') {
        //       let file = items[i].getAsFile();
        //       this.insertFile(file);
        //       break;
        //     }
        //   }
        // }
      }
    }
  },
  methods: {
    insertFile (file) {
      let url = getUrlFromFile(file)
      if (file.type.indexOf(MSG_TYPE_IMAGE) != -1) {
        this.editorInsertEmbed('img', { url: url, name: file.name })
        // this.editor.updateContents(new Delta([{
        //   insert: 'Hello'
        // }, {
        //   insert: 'World',
        //   attributes: { bold: true }
        // }, {
        //   insert: {
        //     image: url
        //   },
        //   attributes: { width: '100' }
        // }]));
      } else if (file.type.indexOf(MSG_TYPE_VIDEO) != -1) {
        this.editorInsertEmbed('video', { url: url, name: file.name })
      } else {
        this.editorInsertEmbed('file', { url: url, name: file.name, file: file })
      }
    },
    bindClip (bind) {
      if (bind) {
        console.log('bindClip', bind)
        document.addEventListener(
          'paste',
          this.clipHandle
        )
      } else {
        document.removeEventListener(
          'paste',
          this.clipHandle
        )
      }
    },
    onChangeVersion () {
      if (!this.curSession) {
        return
      }
      let version = this.versionList[this.versionIndex]
      if (this.curSession.customer.version_id != version.id) {
        this._updateUserInfo({
          external_userid: this.curSession.external_userid,
          version_id: version.id
        }, () => {
          this.curSession.customer.version_info = version
          this.curSession.customer.version_id = version.id
        })
      }
    },
    filterSearchSession (item, queryText, itemText) {
      if (itemText.indexOf(queryText) != -1) {
        item['search_text'] = itemText
        return itemText
      } else if (item.customer['name'] && item.customer.name.indexOf(queryText) != -1) {
        item['search_text'] = item.customer.name
        return itemText
      } else if (item.customer['chain_name'] && item.customer.chain_name.indexOf(queryText) != -1) {
        item['search_text'] = item.customer.chain_name
        return itemText
      } else if (item.customer['mch_name'] && item.customer.mch_name.indexOf(queryText) != -1) {
        item['search_text'] = item.customer.mch_name
        return itemText
      } else if (item.customer['version_info'] && item.customer.version_info.name.indexOf(queryText) != -1) {
        item['search_text'] = item.customer.version_info.name
        return itemText
      } else if (item['tag_info'] && item.tag_info.name.indexOf(queryText) != -1) {
        item['search_text'] = item.tag_info.name
        return itemText
      }
      return false
    },
    gotoSession (session) {
      console.log('跳转', session)
      if (!session) {
        return
      }
      switch (session.session_tab) {
        case 0:
          console.log('跳转 当前')
          this.curSessionIndex = this.curSessionList.findIndex((item) => item.id == session.id)
          break
        case 1:
          console.log('跳转 排队')
          this.waitSessionIndex = this.waitSessionList.findIndex((item) => item.id == session.id)
          break
        case 2:
          console.log('跳转 全局')
          this.globalSessionIndex = this.globalSessionList.findIndex((item) => item.id == session.id)
          break
        default:
          break
      }
      this.sessionTab = session.session_tab
      this.onChangeTab(this.sessionTab)
    },
    bindHotKey (bind) {
      if (bind) {
        console.log('bindHotKey', bind)
        document.onkeydown = (event) => {
          console.log('热键', event.ctrlKey, event.shiftKey, event.altKey, event.keyCode)
          if (event.ctrlKey && event.altKey && (event.keyCode == 49 || event.keyCode == 97)) {
            this.gotoSession(this.oldCurSession)
          }
          event.preventDefault
        }
      } else {
        document.onkeydown = null
      }
    },
    onClickHistory (e) {
      console.log('onClickHistory', e)
      let pullInfo = {
        external_userid: this.curSession.external_userid,
        end_id: e.id
      }
      if (this.curSession.msg_list.length > 0) {
        let msgIndex = this.curSession.msg_list.findIndex((item) => item.id == e.id)
        if (msgIndex != -1) {
          let com = this.$refs[`msg_${e.id}`]
          if (com) {
            com[0].$el.scrollIntoViewIfNeeded()
          }
          return
        } else {
          pullInfo['id'] = this.curSession.msg_list[0].id
        }
      }
      this.$bus.emit('$ws_send', 'history', pullInfo)
    },
    scrollSeTarget (type, id, sessions) {
      this.$nextTick(() => {
        let seIndex = sessions.findIndex((item) => item.id == id)
        if (seIndex != -1) {
          let com = this.$refs[`${type}_se_${id}`]
          if (com) {
            com[0].$el.scrollIntoViewIfNeeded()
          }
        }
      })
    },
    scrollMsgTarget (id) {
      this.$nextTick(() => {
        let msgIndex = this.curSession.msg_list.findIndex((item) => item.id == id)
        if (msgIndex != -1) {
          let com = this.$refs[`msg_${id}`]
          if (com) {
            com[0].$el.scrollIntoViewIfNeeded()
          }
        }
      })
    },
    onClickDown (e) {
      window.open(e.msg.html)
    },
    onClickRecPlay (e) {
      console.log('onClickRecPlay', e)
      this.recPlayer = new BenzAMRRecorder()
      this.recPlayer.initWithUrl(e.msg.html).then(() => {
        this.curRecPlay = e.id
        this.recPlayer.play()
        this.recPlayerTimer = setInterval(() => {
          this.curRecPlayPos = (this.recPlayer.getCurrentPosition() / this.recPlayer.getDuration()).toFixed(2) * 100
        }, 500)
      })
      this.recPlayer.onEnded(this.onClickRecStop)
    },
    onClickRecStop () {
      console.log('onClickRecStop')
      clearInterval(this.recPlayerTimer)
      this.recPlayer.stop()
      this.curRecPlay = ''
      this.curRecPlayPos = 0
    },
    onChangeImg (e) {
      let files = e.target.files
      if (!files || files.length <= 0) {
        return
      }
      let file = files[0]
      this.sendAny(file)
      this.$refs.uploadImg.value = ''
    },
    sendAny (file) {
      if (file.type.indexOf(MSG_TYPE_VIDEO) != -1) {
        this.sendVideo(file)
      } else if (file.type.indexOf(MSG_TYPE_IMAGE) != -1) {
        this.sendImage(file)
      } else {
        this.sendFile(file)
      }
    },
    onChangeFile (e) {
      let files = e.target.files
      if (!files || files.length <= 0) {
        return
      }
      let file = files[0]
      this.sendAny(file)
      this.$refs.uploadFile.value = ''
    },
    onClickDataSearch () {
      this.dataPage = 1
      this._getArticleList()
    },
    _getArticleList () {
      this.$loading.show({ content: '加载中' })
      getArticleList('', 'title', this.dataKeyword, this.dataPage, (ok) => {
        if (ok.code == 0) {
          if (this.dataPage == 1) {
            this.dataList = ok.data.data
            this.dataTotal = ok.data.total
          } else {
            this.dataList.push(...ok.data.data)
          }
          this.$loading.close()
        } else {
          this.$loading.close()
          this.$toast().show({ content: ok.msg })
        }
      }, (err) => {
        console.log(err)
        this.$loading.close()
        this.$toast().show({ content: '获取文章列表失败,请稍后再试' })
      })
    },
    onClickData (e) {
      if (!e) {
        return
      }
      this.$refs.DialogDataView.show({
        type: 0,
        ext: this.$_.cloneDeep(e),
        callback: (r, ext) => {
          if (r == 'ok') {
            let dataIndex = this.dataList.findIndex((item) => {
              return item.id == ext.id
            })
            this.$set(this.dataList, dataIndex, ext)
            this.onClickDataSearch()
          }
          this.$refs.DialogDataView.close()
        }
      })
    },
    onChangeSearchSession () {
      console.log('onChangeSearchSession', this.selectSearchSession)
      if (!this.selectSearchSession) {
        return
      }
      if (this.sessionTab == 0) {
        this.scrollSeTarget('cur', this.selectSearchSession.id, this.curSessionList)
      } else if (this.sessionTab == 1) {
        this.scrollSeTarget('wait', this.selectSearchSession.id, this.waitSessionList)
      } else if (this.sessionTab == 2) {
        this.scrollSeTarget('global', this.selectSearchSession.id, this.globalSessionList)
      }
    },
    onLoadImg (e) {
      if (!this.curSession) {
        return
      }
      if (this.curSession.msg_list.length <= 0) {
        return
      }
      console.log('onLoadImg', this.curSession.msg_list[this.curSession.msg_list.length - 1].id, e.id)
      // if (this.curSession.msg_list[this.curSession.msg_list.length - 1].id == e.id) {
      //   this.scrollMsgBoxBottom(true);
      // }
      this.scrollMsgBoxBottom()
    },
    formatStatus (data) {
      // if (data.status == SESSION_STATUS_END) {
      //   data.tag_info.bg = '#aaaaaa';
      // }
    },
    formatMsgData (data, is_read) {
      if (is_read) {
        data['is_read'] = 1
      }
      switch (data.msg_type) {
        case MSG_TYPE_MERGED:
          //  data['msg'] = JSON.parse(data.msg_content)
          //   if(typeof data['msg']['content'] == 'object'){
          //     data['msg']['content'] = JSON.stringify(data['msg']['content'])
          //   }
          data['msg'] = JSON.parse(data.msg_content)
          data['is_recv'] = 1
          break
        case MSG_TYPE_TEXT:
          data['msg'] = JSON.parse(data.msg_content)
          data['msg']['html'] = this.textToHtml(data['msg']['content'])
          data['is_recv'] = 1
          break
        case MSG_TYPE_IMAGE:
          data['msg'] = JSON.parse(data.msg_content)
          if (data['msg']['pic']) {
            data['msg']['html'] = this.$globalData.file_url + data['msg']['pic']
            data['msg']['htmls'] = [data['msg']['html']]
            data['is_recv'] = 1
          } else {
            data['msg']['html'] = `${this.$globalData.static_url}/logo.png`
            data['msg']['htmls'] = [data['msg']['html']]
            data['is_recv'] = 0
          }
          data['msg']['content'] = '[图片]'
          break
        case MSG_TYPE_VOICE:
          data['msg'] = JSON.parse(data.msg_content)
          if (data['msg']['pic']) {
            data['msg']['html'] = this.$globalData.file_url + data['msg']['pic']
            data['is_recv'] = 1
          } else {
            data['msg']['html'] = `${this.$globalData.static_url}/logo.png`
            data['is_recv'] = 0
          }
          data['msg']['content'] = '[语音]'
          break
        case MSG_TYPE_VIDEO:
          data['msg'] = JSON.parse(data.msg_content)
          if (data['msg']['pic']) {
            data['msg']['html'] = this.$globalData.file_url + data['msg']['pic']
            data['is_recv'] = 1
          } else {
            data['msg']['html'] = `${this.$globalData.static_url}/logo.png`
            data['is_recv'] = 0
          }
          data['msg']['content'] = '[视频]'
          break
        case MSG_TYPE_FILE:
          data['msg'] = JSON.parse(data.msg_content)
          if (data['msg']['pic']) {
            data['msg']['html'] = this.$globalData.file_url + data['msg']['pic']
            data['is_recv'] = 1
          } else {
            data['msg']['html'] = `${this.$globalData.static_url}/logo.png`
            data['is_recv'] = 0
          }
          data['msg']['content'] = '[文件]'
          break
        default:
          data['msg'] = JSON.parse(data.msg_content)
          data['msg']['html'] = '未知消息数据类型：' + data.msg_type + '，请联系管理员'
          data['is_recv'] = 1
          break
      }
      return data.msg_type
    },
    onClickEnter (item) {
      if (!item) {
        return
      }
      console.log('onClickEnter1', this.tempSession)
      this.tempSession = item
      this.$bus.emit('$ws_send', 'connect', { meeting_id: item.id })
      console.log('onClickEnter2', this.tempSession)
    },
    sendMsg (id, uuid, type, content, ext) {
      this.$bus.emit('$ws_send', 'msg', { meeting_id: id, uuid: uuid, type: type, content: content }, ext)
    },
    createMsgData (msg_type, external_userid, text, content, name) {
      let uuid = this.$uuid()
      let msg = { content: text, html: content, name: name }
      let msgx_content = JSON.stringify(msg)
      return { "id": 0, "uuid": uuid, "user_id": this.userInfo.id, "name": this.userInfo.name, "avatar": this.userInfo.avatar, "external_userid": external_userid, "msg_type": msg_type, "msg_content": msgx_content, "msg": msg, "status": SESSION_STATUS_IN, "msg_origin": 5, "created_at": this.$moment().format("YYYY-MM-DD HH:mm:ss") }
    },
    textToHtml (text) {
      let delta = new Delta().insert(text)
      let converter = new QuillDeltaToHtmlConverter(delta.ops)
      return converter.convert()
    },
    textToContents (html) {
      // let delta = convertHtmlToDelta(html);
      // return delta;
      return this.editor.clipboard.convert(html)
    },
    async enterMsg (range, context) {
      console.log('enterMsg', range, context)
      if (!this.curSession) {
        console.log('enterMsg no session')
        return
      }
      let contents = this.editor.getContents()
      if (this.debug) {
        console.log('enterMsg', contents)
      }
      // console.log('enterMsg', this.editor, 'contents', contents, 'html', this.myHtml, 'contents', this.myContents, 'focus', this.editor.hasFocus());
      for (let i = 0; i < contents.ops.length; i++) {
        let op = contents.ops[i]
        let type = typeof (op.insert)
        // console.log('enterMsg', type);
        if (type == 'string') {
          let text = op.insert
          if (i == contents.ops.length - 1) {
            text = text.trimEnd()
          }
          if (text == "") {
            continue
          }
          let content = this.textToHtml(text)
          let msg = this.createMsgData(MSG_TYPE_TEXT, this.curSession.external_userid, text, content, '')
          this.curSession.msg_list.push(msg)
          this.scrollMsgBoxBottom(true, 500)
          this.sendMsg(this.curSession.id, msg.uuid, MSG_TYPE_TEXT, text, { external_userid: this.curSession.external_userid })
        } else if (type == 'object') {
          // console.log('enterMsg image', op.insert['image'], op.insert.image, this.$globalData.file_url, op.insert.image.indexOf(this.$globalData.file_url));
          if (op.insert['image'] || op.insert['img']) {
            let image = op.insert['image'] || op.insert['img']
            if (typeof (image) == 'string') {
              if (image.indexOf(';base64,') != -1) {
                let file = getFileFromBase64(image, new Date().getTime() + '.png')
                this.sendImage(file)
              } else if (image.indexOf(this.$globalData.file_url) != -1) {
                this.sendImage(image.replace(this.$globalData.file_url, ''), new URL(image).pathname.split('/').pop())
              } else {
                this.sendImage(image)
              }
            } else {
              if (image['url']) {
                let file = await getFileFromUrl(image.url, image.name)
                this.sendImage(file)
              } else {
                this.sendImage(image)
              }
            }
          } else if (op.insert['video']) {
            let video = op.insert['video']
            if (typeof (video) == 'string') {
              if (video.indexOf(';base64,') != -1) {
                let file = getFileFromBase64(video, new Date().getTime() + '.png')
                this.sendVideo(file)
              } else if (video.indexOf(this.$globalData.file_url) != -1) {
                this.sendVideo(video.replace(this.$globalData.file_url, ''), new URL(video).pathname.split('/').pop())
              } else {
                this.sendVideo(video)
              }
            } else {
              if (video['url']) {
                let file = await getFileFromUrl(video.url, video.name)
                this.sendVideo(file)
              } else {
                this.sendVideo(video)
              }
            }
          } else if (op.insert['file']) {
            let cfile = op.insert['file']
            if (typeof (cfile) == 'string') {
              if (cfile.indexOf(';base64,') != -1) {
                let file = getFileFromBase64(cfile, new Date().getTime() + '.png')
                this.sendFile(file)
              } else if (cfile.indexOf(this.$globalData.file_url) != -1) {
                this.sendFile(cfile.replace(this.$globalData.file_url, ''), new URL(cfile).pathname.split('/').pop())
              } else {
                this.sendFile(cfile)
              }
            } else {
              if (cfile['url']) {
                let file = await getFileFromUrl(cfile.url, cfile.name)
                this.sendFile(file)
              } else {
                this.sendFile(cfile)
              }
            }
          }
        }
      }
      this.editor.setText('')
      this.oldCurSession = this.$_.cloneDeep(this.curSession)
      return
    },
    sendImage (file, name) {
      if (!this.curSession) {
        return
      }
      let userObj = this.curSessionList.find(item => this.curSession.id == item.id)
      if (typeof (file) == 'string') {
        let imgUrl = file.indexOf('http') == -1 ? this.$globalData.file_url + file : file
        if (!name) {
          name = new URL(imgUrl).pathname.split('/').pop()
        }
        let msg = this.createMsgData(MSG_TYPE_IMAGE, userObj.external_userid, '', imgUrl, name)
        userObj.msg_list.push(msg)
        this.scrollMsgBoxBottom(true, 1000)
        uploadQuickFile(file, (ok) => {
          if (ok.code == 0) {
            this.sendMsg(userObj.id, msg.uuid, MSG_TYPE_IMAGE, { name: name, url: ok.data.url, media_id: ok.data.media_id }, { external_userid: userObj.external_userid })
          } else {
            this.$toast().show({ content: ok.msg })
          }
        }, (err) => {
          console.log(err)
          this.$toast().show({ content: '上传图片失败,请稍后再试' })
        })
      } else {
        let imgUrl = getUrlFromFile(file)
        let msg = this.createMsgData(MSG_TYPE_IMAGE, userObj.external_userid, '', imgUrl, file.name)
        userObj.msg_list.push(msg)
        this.scrollMsgBoxBottom(true, 1000)
        let type = MSG_TYPE_IMAGE
        // if (file.type == 'image/gif') {
        //   type = MSG_TYPE_FILE;
        // }
        uploadMedia(type, file, (ok) => {
          if (ok.code == 0) {
            this.sendMsg(userObj.id, msg.uuid, type, { name: file.name, url: ok.data.url, media_id: ok.data.media_id }, { external_userid: userObj.external_userid })
          } else {
            this.$toast().show({ content: ok.msg })
          }
        }, (err) => {
          console.log(err)
          this.$toast().show({ content: '上传图片失败,请稍后再试' })
        })
      }
    },
    sendVideo (file, name) {
      if (!this.curSession) {
        return
      }
      let userObj = this.curSessionList.find(item => this.curSession.id == item.id)
      if (typeof (file) == 'string') {
        let imgUrl = file.indexOf('http') == -1 ? this.$globalData.file_url + file : file
        if (!name) {
          name = new URL(imgUrl).pathname.split('/').pop()
        }
        let msg = this.createMsgData(MSG_TYPE_VIDEO, userObj.external_userid, '', imgUrl, name)
        userObj.msg_list.push(msg)
        this.scrollMsgBoxBottom(true, 1000)
        uploadQuickFile(file, (ok) => {
          if (ok.code == 0) {
            this.sendMsg(userObj.id, msg.uuid, MSG_TYPE_VIDEO, { name: name, url: ok.data.url, media_id: ok.data.media_id }, { external_userid: userObj.external_userid })
          } else {
            this.$toast().show({ content: ok.msg })
          }
        }, (err) => {
          console.log(err)
          this.$toast().show({ content: '上传视频失败,请稍后再试' })
        })
      } else {
        let imgUrl = getUrlFromFile(file)
        let msg = this.createMsgData(MSG_TYPE_VIDEO, userObj.external_userid, '', imgUrl, file.name)
        userObj.msg_list.push(msg)
        this.scrollMsgBoxBottom(true, 1000)
        uploadMedia(MSG_TYPE_VIDEO, file, (ok) => {
          if (ok.code == 0) {
            this.sendMsg(userObj.id, msg.uuid, MSG_TYPE_VIDEO, { name: file.name, url: ok.data.url, media_id: ok.data.media_id }, { external_userid: userObj.external_userid })
          } else {
            this.$toast().show({ content: ok.msg })
          }
        }, (err) => {
          console.log(err)
          this.$toast().show({ content: '上传视频失败,请稍后再试' })
        })
      }
    },
    sendFile (file, name) {
      if (!this.curSession) {
        return
      }
      let userObj = this.curSessionList.find(item => this.curSession.id == item.id)
      if (typeof (file) == 'string') {
        let imgUrl = file.indexOf('http') == -1 ? this.$globalData.file_url + file : file
        if (!name) {
          name = new URL(imgUrl).pathname.split('/').pop()
        }
        let msg = this.createMsgData(MSG_TYPE_FILE, userObj.external_userid, '', imgUrl, name)
        userObj.msg_list.push(msg)
        this.scrollMsgBoxBottom(true, 1000)
        uploadQuickFile(file, (ok) => {
          if (ok.code == 0) {
            this.sendMsg(userObj.id, msg.uuid, MSG_TYPE_FILE, { name: name, url: ok.data.url, media_id: ok.data.media_id }, { external_userid: userObj.external_userid })
          } else {
            this.$toast().show({ content: ok.msg })
          }
        }, (err) => {
          console.log(err)
          this.$toast().show({ content: '上传文件失败,请稍后再试' })
        })
      } else {
        let fileUrl = getUrlFromFile(file)
        let msg = this.createMsgData(MSG_TYPE_FILE, userObj.external_userid, '', fileUrl, file.name)
        userObj.msg_list.push(msg)
        this.scrollMsgBoxBottom(true, 1000)
        uploadMedia('file', file, (ok) => {
          if (ok.code == 0) {
            this.sendMsg(userObj.id, msg.uuid, MSG_TYPE_FILE, { name: file.name, url: ok.data.url, media_id: ok.data.media_id }, { external_userid: userObj.external_userid })
          } else {
            this.$toast().show({ content: ok.msg })
          }
        }, (err) => {
          console.log(err)
          this.$toast().show({ content: '上传文件失败,请稍后再试' })
        })
      }
    },
    scrollMsgBoxBottom (bottom, timeout) {
      timeout = timeout || 500
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.MsgBox) {
            console.log('scrollMsgBoxBottom', this.$refs.MsgBox.clientHeight, bottom, this.$refs.MsgBox.scrollTop, this.msgBoxScrollHeight)
            if (this.$refs.MsgBox.scrollHeight >= this.$refs.MsgBox.clientHeight && (bottom || this.msgBoxScrollHeight <= 5)) {
              // this.$refs.MsgBox.scrollTop = height;
              if (this.$refs.BottomBox) {
                console.log('auto scroll')
                this.$refs.BottomBox.scrollIntoViewIfNeeded()
                // setTimeout(() => {
                //   console.log('scrollMsgBoxBottom scrollTop', this.$refs.MsgBox.scrollTop, this.$refs.MsgBox.scrollHeight);
                //   this.msgBoxScrollHeight = Math.abs(this.$refs.MsgBox.scrollHeight - this.$refs.MsgBox.clientHeight - this.$refs.MsgBox.scrollTop);
                // }, 100)
              }
            }
          }
        }, timeout)
      })
    },
    formatSessionTime (session, time) {
      session.send_time = this.$moment(time.created_at).calendar(this.myNowTime, {
        sameDay: 'HH:mm',
        nextDay: '[明天] HH:mm',
        nextWeek: 'dddd HH:mm',
        lastDay: '[昨天] HH:mm',
        lastWeek: 'dddd HH:mm',
        sameElse: 'YY-M-D HH:mm'
      })
      session.send_time_from = this.$moment(time.created_at).from(this.myNowTime, true)
      session.send_time_diff = this.$moment(this.myNowTime).diff(time.created_at, 'minutes')
    },
    formatSessionData (data, is_read) {
      data.forEach((item) => {
        item.tag_info = item.tag ? this.$_.cloneDeep(this.qaList[item.tag.id]) : this.$_.cloneDeep(this.qaList[0])
        this.formatStatus(item)
        item.customer.version_info = this.versionList[item.customer.version_id]
        if (item['dialogue_new']) {
          this.formatMsgData(item.dialogue_new, is_read)
          this.formatSessionTime(item, item.dialogue_new)
          console.log('dialogue_new', item)
        } else {
          this.formatSessionTime(item, item)
          console.log('session_new', item)
        }
        item['msg_list'] = []
      })
    },
    onBlurRemark () {
      if (this.userRemark != this.curUserInfo.remark) {
        this._updateUserInfo({
          external_userid: this.curSession.external_userid,
          remark: this.userRemark
        }, () => {
          this.curUserInfo.remark = this.userRemark
        })
      }
    },
    saveOldSend () {
      if (!this.curSession || !this.editor) {
        return
      }
      let contents = this.editor.getContents()

      for (let i = 0; i < contents.ops.length; i++) {
        let op = contents.ops[i]
        let type = typeof (op.insert)
        if (type == 'string') {
          let text = op.insert
          if (i == contents.ops.length - 1) {
            text = text.trimEnd()
          }
          if (text == "") {
            continue
          }
        }
      }
      contents.ops = contents.ops.filter((item) => {
        return typeof (item.insert) == 'string'
      })
      // console.log('saveOldSend',contents);
      this.curSession['oldSend'] = contents
      this.editor.setText('')
    },
    readOldSend () {
      if (!this.curSession || !this.editor) {
        return
      }
      if (this.curSession['oldSend']) {
        // console.log('readOldSend',this.curSession['oldSend']);
        this.editor.setContents(this.curSession['oldSend'])
      }
    },
    onChangeSession (e) {
      if (this.sessionTab == 0) {
        console.log('onChangeSession', 'curSessionList', e)
        if (e == undefined || e == -1) {
          if (this.curSession && this.curSession['session_tab'] == 0) {
            this.curSession['is_bottom'] = 0
            this.curSession = ''
            this.curSessionIndex = -1
          }
        } else {
          if (this.curSessionList.length > 0) {
            if (this.curSession && this.curSession.session_tab == 0 && this.curSession.id == this.curSessionList[e].id) {
              return
            } else {
              this.saveOldSend()
              let curSession = this.curSessionList[e]
              curSession['session_tab'] = 0
              if (curSession['dialogue_new']) {
                curSession['dialogue_new']['is_read'] = 1
              }
              this.curSession = curSession
            }
          }
        }
      } else if (this.sessionTab == 1) {
        console.log('onChangeSession', 'waitSessionList', e)
        if (e == undefined || e == -1) {
          if (this.curSession && this.curSession['session_tab'] == 1) {
            this.curSession['is_bottom'] = 0
            this.curSession = ''
            this.waitSessionIndex = -1
          }
        } else {
          if (this.waitSessionList.length > 0) {
            if (this.curSession && this.curSession.session_tab == 1 && this.curSession.id == this.waitSessionList[e].id) {
              return
            } else {
              let curSession = this.waitSessionList[e]
              curSession['session_tab'] = 1
              if (curSession['dialogue_new']) {
                curSession['dialogue_new']['is_read'] = 1
              }
              this.curSession = curSession
            }
          }
        }
      } else if (this.sessionTab == 2) {
        console.log('onChangeSession', 'globalSessionList', e)
        if (e == undefined || e == -1) {
          if (this.curSession && this.curSession['session_tab'] == 2) {
            this.curSession['is_bottom'] = 0
            this.curSession = ''
            this.globalSessionIndex = -1
          }
        } else {
          if (this.globalSessionList.length > 0) {
            if (this.curSession && this.curSession.session_tab == 2 && this.curSession.id == this.globalSessionList[e].id) {
              return
            } else {
              let curSession = this.globalSessionList[e]
              curSession['session_tab'] = 2
              if (curSession['dialogue_new']) {
                curSession['dialogue_new']['is_read'] = 1
              }
              this.curSession = curSession
            }
          }
        }
      }
      if (!this.curSession) {
        return
      }
      console.log('onChangeSession', this.curSession)
      localStorage.setItem('curSession', JSON.stringify(this.curSession))
      this._getUserInfo()
      this._getFiveHistoryList()
      if (!this.curSession['is_pull']) {
        this.$bus.emit('$ws_send', 'history', { external_userid: this.curSession.external_userid, limit: this.limitMsg })
      } else {
        this.scrollMsgBoxBottom(true)
      }
      this.readOldSend()
    },
    _updateUserInfo (info, callback) {
      updateUserInfo(info, (ok) => {
        if (ok.code == 0) {
          callback && callback()
        } else {
          this.$toast().show({ content: ok.msg })
        }
      }, (err) => {
        console.log(err)
        this.$toast().show({ content: '保存客户信息失败,请稍后再试' })
      })
    },
    _getFiveHistoryList () {
      getFiveHistoryList(this.curSession.external_userid, (ok) => {
        if (ok.code == 0) {
          let data = ok.data
          data.forEach((item) => {
            if (item['dialogue']) {
              let dialogue = item['dialogue']
              dialogue.forEach((item2) => {
                this.formatMsgData(item2)
              })
            }
          })
          this.curFiveHistoryList = data
        } else {
          this.$toast().show({ content: ok.msg })
        }
      }, (err) => {
        console.log(err)
        this.$toast().show({ content: '获取历史会话列表失败,请稍后再试' })
      })
    },
    _getUserInfo () {
      getUserInfo(this.curSession.external_userid, (ok) => {
        if (ok.code == 0) {
          this.curUserInfo = ok.data
          this.userRemark = this.curUserInfo.remark
        } else {
          this.$toast().show({ content: ok.msg })
        }
      }, (err) => {
        console.log(err)
        this.$toast().show({ content: '获取客户信息失败,请稍后再试' })
      })
    },
    onChangeTab (e) {
      console.log('改变选项卡', e)
      if (e == 0) {
        this.searchSessionList = this.curSessionList
        this.onChangeSession(this.curSessionIndex)
      } else if (e == 1) {
        this.searchSessionList = this.waitSessionList
        this.onChangeSession(this.waitSessionIndex)
      } else if (e == 2) {
        this.searchSessionList = this.globalSessionList
        this.onChangeSession(this.globalSessionIndex)
      }
    },
    onClickTool () {
      if (!this.curUserInfo) {
        window.open('yxlogtools://')
      } else {
        window.open('yxlogtools://搜索:' + this.curUserInfo.mch_id)
      }
    },
    onClickShare () {
      if (!this.curSession) {
        return
      }
      this.$refs.DialogOnlineService.show({
        ext: {
          meeting_id: this.curSession.id
        },
        callback: (r, ext) => {
          if (r == 'ok') {
            let session = this.curSessionList.find((item) => item.id == ext.meeting_id)
            console.log('onClickShare', session)
            if (session) {
              session['status'] = SESSION_STATUS_END
              this.formatStatus(session)
            }
          }
          this.$refs.DialogOnlineService.close()
        }
      })
    },
    onClickClose () {
      if (!this.curSession) {
        return
      }
      if (this.curSession.session_tab != 0) {
        if (this.curSession.session_tab == 1) {
          this.curSession = ''
          this.waitSessionIndex = -1
        } else if (this.curSession.session_tab == 2) {
          this.curSession = ''
          this.globalSessionIndex = -1
        }
        return
      } else if (this.curSession.status != 1) {
        this.curSession = ''
        this.curSessionIndex = -1
        return
      }
      let opt = {
        type: 'add',
        ext: this.$_.cloneDeep(this.curUserInfo)
      }
      opt.ext['is_track'] = 1
      // opt.ext['remark'] = '';
      opt.ext['meeting_id'] = this.curSession.id
      if (this.curSession['tag']) {
        opt.ext['tag_id'] = this.curSession['tag'].id
      }
      this.$refs.DialogSessionReport.show({
        ...opt,
        callback: (r, ext) => {
          this.$refs.DialogSessionReport.close()
        }
      })
    },
    onClickEdit (type) {
      let opt = { _type: type }
      switch (type) {
        case 'name':
          opt['type'] = 'text'
          opt['title'] = '客户姓名编辑'
          opt['placeholder'] = '请输入客户姓名'
          opt['ext'] = {
            value: this.curUserInfo.name
          }
          break
        case 'phone':
          opt['type'] = 'text'
          opt['title'] = '电话号码编辑'
          opt['placeholder'] = '请输入电话号码'
          opt['ext'] = {
            value: this.curUserInfo.mobile
          }
          break
        case 'chain':
          opt['type'] = 'select'
          opt['title'] = '所属连锁选择'
          opt['placeholder'] = '请选择所属连锁'
          opt['ext'] = {
            id: this.curUserInfo.chain_id,
            name: this.curUserInfo.chain_name
          }
          break
        case 'bar':
          if (this.curUserInfo.chain_id == -1) {
            opt['type'] = 'text'
            opt['title'] = '所属门店编辑'
            opt['placeholder'] = '请输入所属门店'
            opt['ext'] = {
              value: this.curUserInfo.mch_name
            }
          } else {
            opt['type'] = 'select'
            opt['title'] = '所属门店选择'
            opt['placeholder'] = '请选择所属门店'
            opt['ext'] = {
              chain_id: this.curUserInfo.chain_id,
              id: this.curUserInfo.mch_id,
              name: this.curUserInfo.mch_name
            }
          }
          break
        default:
          break
      }
      let info = {
        external_userid: this.curSession.external_userid
      }
      this.$refs.DialogEdit.show({
        ...opt,
        callback: (r, ext) => {
          if (r == 'ok') {
            switch (type) {
              case 'name':
                if (this.curUserInfo.name != ext.value) {
                  info['name'] = ext.value
                  this._updateUserInfo(info, () => {
                    this.curUserInfo.name = ext.value
                  })
                }
                break
              case 'phone':
                if (this.curUserInfo.mobile != ext.value) {
                  info['mobile'] = ext.value
                  this._updateUserInfo(info, () => {
                    this.curUserInfo.mobile = ext.value
                  })
                }
                break
              case 'chain':
                if (this.curUserInfo.chain_id != ext.chain_id) {
                  info['chain_id'] = ext.chain_id
                  info['chain_name'] = ext.name
                  this._updateUserInfo(info, () => {
                    this.curUserInfo.chain_id = ext.chain_id
                    this.curUserInfo.chain_name = ext.name
                  })
                }
                break
              case 'bar':
                if (this.curUserInfo.chain_id == -1) {
                  if (this.curUserInfo.mch_name != ext.value) {
                    info['mch_id'] = -1
                    info['mch_name'] = ext.value
                    this._updateUserInfo(info, () => {
                      this.curUserInfo.mch_id = -1
                      this.curUserInfo.mch_name = ext.value
                    })
                  }
                } else {
                  if (this.curUserInfo.mch_id != ext.id) {
                    info['mch_id'] = ext.id
                    info['mch_name'] = ext.name
                    this._updateUserInfo(info, () => {
                      this.curUserInfo.mch_id = ext.id
                      this.curUserInfo.mch_name = ext.name
                    })
                  }
                }
                break
              default:
                break
            }
          }
          this.$refs.DialogEdit.close()
        }
      })
    },
    onClickQuick () {
      this.$refs.DialogQuickList.show({
        callback: (r, ext) => {
          if (r == 'ok') {
            if (ext.type == 1) {
              this.editorInsertText(ext.content)
            } else if (ext.type == 2) {
              this.sendImage(ext.pic, ext.file_name)
            }
          }
          this.$refs.DialogQuickList.close()
        }
      })
    },
    onResizeWindow () {
      this.windowSize = { w: window.innerWidth, h: window.innerHeight }
      this.chatMaxBoxHeight = window.innerHeight - this.chatBoxDiffHeight
      this.sessionMaxBoxHeight = window.innerHeight - this.sessionBoxDiffHeight
      this.historyMaxBoxHeight = window.innerHeight - this.historyBoxDiffHeight
      // this.scrollMsgBoxBottom(true);
    },
    onBlurEditor (quill) {
      // console.log('editor blur!', quill);
    },
    onFocusEditor (quill) {
      // console.log('editor focus!', quill);
    },
    onReadyEditor (quill) {
      // console.log('editor ready!', quill);
      this.myQuill = quill
      // this.bindClip(true);
    },
    onChangeEditor ({ quill, html, text }) {
      // console.log('editor change!', quill, quill.getContents(), html, text);
      // this.myContents = quill.getContents();
      // this.myHtml = html;
      // this.myText = text;
    },
    onClickImg () {
      this.$refs.uploadImg.click()
    },
    onClickFile () {
      this.$refs.uploadFile.click()
    },
    editorInsertEmbed (type, embed) {
      let range = this.editor.selection.savedRange
      if (range.length > 0) {
        this.editor.deleteText(range.index, range.length)
      }
      this.editor.insertEmbed(range.index, type, embed)
      this.$nextTick(() => {
        setTimeout(() => {
          this.editor.setSelection(range.index + 1)
        }, 100)
      })
    },
    editorInsertText (text, type, embed) {
      let range = this.editor.selection.savedRange
      if (range.length > 0) {
        this.editor.deleteText(range.index, range.length)
      }
      if (type) {
        this.editor.insertText(range.index, text)
      } else {
        this.editor.insertText(range.index, text, type, embed)
      }
    },
    onClickEmoji (e) {
      this.editorInsertText(e.target.innerText)
    },
    onScrollData (e) {
      console.log('onScrollData', e.target.scrollHeight, e.target.scrollTop, e.target.clientHeight, e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop)
      if (Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) == 0) {
        if (this.dataList.length < this.dataTotal) {
          this.dataPage++
          this._getArticleList()
        }
      }
    },
    onScrollMsg (e) {
      // console.log('onScrollMsg', e.target.scrollTop, e.target.scrollHeight);
      if (!this.curSession || !this.curSession['is_pull']) {
        return
      }
      this.msgBoxScrollHeight = Math.abs(this.$refs.MsgBox.scrollHeight - this.$refs.MsgBox.clientHeight - this.$refs.MsgBox.scrollTop)
      if (e.target.scrollTop <= 0) {
        let pullInfo = {
          external_userid: this.curSession.external_userid,
          limit: this.limitMsg
        }
        if (this.curSession.msg_list.length > 0) {
          pullInfo['id'] = this.curSession.msg_list[0].id
        }
        this.$bus.emit('$ws_send', 'history', pullInfo)
      }
    },
    onMDDivder (e) {
      this.boxHeight = parseFloat(this.msgBoxHeight)
      this.resizeY = e.clientY
      document.onmousemove = (e) => {
        let change = this.boxHeight - (this.resizeY - e.clientY)
        if (change < 115 || change > this.chatMaxBoxHeight - 95) {
          return false
        }
        this.msgBoxRatio = change / this.chatMaxBoxHeight
        this.inputBoxRatio = 1 - this.msgBoxRatio
      }
      document.onmouseup = () => {
        document.onmousemove = null
      }
    },
    setMsgLock (status) {
      //设置会话挂起状态
      let obj = {
        meeting_id: this.curSession.id,
        status: status
      }
      setMsgLock(obj, (res => {
        this.$toast().show({ content: res.msg })
        if (res.code == 0) {
          this.curSession.pause_status = status
          // this.curSessionList[this.curSessionIndex].pause_status = status
        }
      }), err => {
        this.$toast().show({ content: err })
      }
      )
    },
    //显示合并会话列表
    showMsgList (item) {
      console.log('第三方会话列表显示触发')
      this.itemObj = item.msg
      this.dialogWxMsg = true
    }
  }
}
</script>
<style scoped lang="scss">
.btn_msg {
  cursor: pointer;
  user-select: none;
  background: #32c3e7;
  color: #fff;
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;

  .t_head {
    padding-bottom: 5px;
  }

  .t_msg {
    border-top: 1px solid #ffffff;
    padding-top: 5px;
  }
}

::v-deep.v-card {
  border-radius: 14px !important;
}

.is-offline {
  filter: grayscale(100%);
}

.container-box {
  padding: 12px 50px 50px 50px;
  height: calc(100vh - 64px) !important;
  overflow-y: auto;
}

.search-title .title99 {
  font-size: 14px;
  color: #333d46;
}

.search-title .title98 {
  font-size: 12px;
  color: #717f8c;
}

.select-item {
  font-size: 14px;
}

.main-box {
  height: 100%
}

/* 列表框 */
.list-box {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 320px;
  max-width: 320px;
  height: 100%;
}

.list-box .tab-box {
  height: 91px;
  padding: 43px 31px 0 31px;
}

.list-box .tab-box .tab-item {
  margin-left: 40px;
  padding: 0 0 30px 0;
  min-width: unset;
}

.list-box .tab-box .tab-item-one {
  margin-left: 0;
}

.list-box .search-box {
  display: flex;
  align-items: center;
  border: #dce3e9 solid 1px;
  border-radius: 8px !important;
  margin: 21px 21px 0 21px;
  padding: 6px;
}

::v-deep.list-box .search-box .v-input__control {
  min-height: 10px !important;
}

::v-deep.list-box .search-box input {
  padding: 0 !important;
}

::v-deep.list-box .search-box .v-select__selection {
  margin: 0 !important;
}

::v-deep.list-box .search-box .v-select__selection--comma {
  text-overflow: unset !important;
}

::v-deep.list-box .search-box .v-input__slot {
  padding: 0 0 0 11px !important;
}

.list-box .search-box .select-type {
  font-size: 14px;
  width: 126px;
}

::v-deep.list-box .search-box .select-type input::placeholder {
  color: #637381 !important;
  font-size: 14px !important;
}

.list-box .search-box .input-name {
  font-size: 14px;
}


::v-deep.list-box .search-box .input-name input::placeholder {
  color: #a1aebc !important;
  font-size: 14px !important;
}

.list-box .search-box .divider-v {
  margin-left: 1px;
  margin-right: 1px;
}

.list-box .session-box {
  /* height: calc(100vh - 360px); */
  margin-top: 13px;
  overflow-y: auto;
}

/* ::v-deep.list-box .session-box .session-item .session-title .v-list-item__title {
  margin-bottom: 4px !important;
} */

.list-box .session-box .session-item {
  height: 65px;
}


.list-box .session-box .session-item .hover-box {
  height: 100%;
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  position: relative;
}

.list-box .session-box .session-item .hover-box .enter {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.list-box .session-box .session-item .session-title {
  height: 100%;
  display: unset;
}

.list-box .session-box .session-item .session-title .title2 {
  margin-bottom: 0;
}

::v-deep.list-box .session-box .session-item .session-type .v-badge__wrapper {
  display: flex;
  justify-content: center;
}

::v-deep.list-box .session-box .session-item .session-type .v-badge__badge {
  /* width: 18px !important; */
  min-width: 18px !important;
  height: 18px !important;
  font-size: 12px !important;
  /* padding: 0 !important; */
  padding: 0 5px !important;
  line-height: 1.4 !important;
  transform: scale(0.9) !important;
  position: unset !important;
  margin-top: 2px !important;
}

/* ::v-deep.list-box .session-box .session-item .session-title .v-size--default {
  height: 18px !important;
} */

.list-box .session-box .session-item .session-title .version {
  font-size: 12px;
  padding: 3px 5px 3px 5px;
  height: 18px;
}


.list-box .session-box .session-item .session-title .name {
  margin-left: 6px;
  font-size: 14px;
  color: #333d46;
  width: 130px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  word-break: keep-all;
  white-space: nowrap;
}

.list-box .session-box .session-item .session-title .message {
  font-size: 12px;
  color: #717f8c;
  margin-top: 12px;
  width: 130px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  word-break: keep-all;
  white-space: nowrap;
}


.list-box .session-box .session-item .session-time {
  height: 100%;
  margin: 0px !important;
  display: unset;
  padding: 12px 0;
}

::v-deep.list-box .session-box .session-item .session-time .v-list-item__action-text {
  display: flex !important;
  align-items: center !important;
}

.list-box .session-box .session-item .session-time .time {
  font-size: 12px;
  color: #a1acb7;
}

.list-box .session-box .session-item .session-time .clock {
  margin-left: 6px;
  font-size: 12px;
  color: #ffab00;
}


.list-box .session-box .session-item .session-time .dot {
  color: #00b8d9;
  transform: translate(6px, 5px);
  width: 100% !important;
  justify-content: flex-end
}

.list-box .tip-box {
  position: absolute;
  bottom: 0;
  padding: 22px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
}

.list-box .tip-box .key-tip {
  font-size: 12px;
  color: #a4adb7;
}

/* 聊天框 */
.chat-box {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 860px;
  max-width: 860px;
  height: 100%;
  margin-left: 20px;
}

.chat-box .no-seesion {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: #a1aebc;
}

.chat-box .tool-box {
  padding: 14px 20px 14px 20px;
}

.chat-box .tool-box .tool-title {
  margin-left: 12px;
}

/* ::v-deep.chat-box .tool-box .tool-title .v-size--default {
  height: 18px !important;
} */

.chat-box .tool-box .tool-title .type {
  font-size: 12px;
  padding: 3px 5px 3px 5px;
  color: #fff;
  height: 18px;
}

.chat-box .tool-box .tool-title .name {
  margin-left: 6px;
  font-size: 14px;
  color: #333d46;
}

.chat-box .msg-box {
  /* height: calc(100vh - 360px); */
  margin-top: 13px;
  overflow-y: auto;
}

.chat-box .msg-box .right {
  justify-content: flex-end;
}

.chat-box .msg-box .right .msg-title {
  align-items: flex-end !important;
}

.chat-box .msg-box .msg-item .avatar {
  align-self: flex-start;
  margin-top: 10px;
  min-width: unset !important;
}

.chat-box .msg-box .msg-item .msg-title {
  flex: unset !important;
  align-items: unset;
  align-self: unset !important;
  /* display: unset !important; */
  flex-wrap: unset !important;
  flex-direction: column !important;
}


.chat-box .msg-box .msg-item .msg-title .msg-load {
  display: flex;
  align-items: center;
}

.chat-box .msg-box .msg-item .msg-title .load {
  margin-right: 10px;
  margin-left: 10px;
}

.chat-box .msg-box .msg-item .msg-title .name {
  font-size: 12px;
  color: #9aa6b2;
  align-self: unset !important;
}

.chat-box .msg-box .msg-item .msg-title .message {
  font-size: 14px;
  color: #26303b;
  padding: 17px;
  background-color: #f5f7fa;
  border-radius: 8px;
  text-overflow: unset;
  white-space: unset;
  margin-top: 10px;
  flex: unset !important;
  width: fit-content;
  max-width: 650px;
}

.chat-box .msg-box .msg-item .msg-title .message .msg-img {
  max-width: 350px;
  max-height: 350px;
}

::v-deep.chat-box .msg-box .msg-item .msg-title .message .plyr {
  max-width: 350px;
  /* max-height: 350px; */
}

.chat-box .msg-box .msg-item .msg-title .message .rec-play {
  display: flex;
  width: 100px;
  align-items: center;
}

.chat-box .msg-box .msg-item .msg-title .message .file-down {
  display: flex;
  max-width: 250px;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  word-break: keep-all;
  white-space: nowrap;
}

::v-deep.chat-box .msg-box .msg-item .msg-title .message p {
  margin-bottom: 0 !important;
}

.divider-resize {
  cursor: n-resize;
  height: 5px;
}



.chat-box .input-box .quick {
  height: 40px !important;
  background-color: #f8f9fb;
}

::v-deep.chat-box .input-box .quick .v-toolbar__content {
  height: 40px !important;
  padding: 4px 26px !important;
}

.chat-box .input-box .send-box {
  display: flex;
  padding: 0 11px;
}

.emoji-box {
  display: flex;
  flex-wrap: wrap;
  width: 350px;
  height: 210px;
  overflow-y: auto;
}

.emoji-box .emoji-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: unset;
}

.chat-box .input-box .send-box .menu-item {
  margin-top: 8px;
}

.chat-box .input-box #toolbar {
  display: none;
  width: 0;
  height: 0;
}

.chat-box .input-box .editor {
  flex: 1;
  overflow: hidden;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
}

::v-deep.chat-box .input-box .editor .ql-container {
  border: unset !important;
}

/* 操作框 */
.action-box {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 320px;
  max-width: 320px;
  margin-left: 20px;
}

.action-box .info-box .tab-box {
  height: 91px;
  padding: 43px 31px 0 31px;
}

.action-box .info-box .tab-box .tab-item {
  margin-left: 40px;
  padding: 0 0 30px 0;
  min-width: unset;
}

.action-box .info-box .tab-box .tab-item-one {
  margin-left: 0;
}

.action-box .info-box .user-box {
  /* display: flex; */
  /* margin: 21px 21px 21px 21px; */
  height: 230px
}

.action-box .info-box .user-box .item-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 21px;
}

.action-box .info-box .user-box .item-box .info-item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 130px;
}

.action-box .info-box .user-box .item-box .w {
  flex: 1;
  margin-bottom: 0;
}

.action-box .info-box .user-box .item-box .info-item .r {
  display: flex;
  align-items: center;
}

::v-deep.action-box .info-box .user-box .item-box .info-item .v-btn {
  transform: translateY(-1px);
}

.action-box .info-box .user-box .item-box .info-item .right {
  padding-left: 28px;
  font-size: 14px;
  color: #333d46;
  height: 21px;
}

.action-box .info-box .user-box .item-box .info-item .right .text2 {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  word-break: keep-all;
  white-space: nowrap;
}

.action-box .info-box .user-box .item-box .info-item .right2 {
  height: 72px !important;
}

.action-box .info-box .user-box .item-box .info-item .r .title2 {
  font-size: 12px !important;
  color: #7d8e9d;
  margin-left: 12px;
}

.action-box .info-box .user-box .item-box .info-item .right .version {
  margin-left: 6px;
  font-size: 12px;
  padding: 3px 5px 3px 5px;
  color: #fff;
  height: 18px;
}

.action-box .info-box .user-box .item-box .info-item .right .mark-box {
  width: 100%;
  height: 72px;
  overflow-y: auto;
}

::v-deep.action-box .info-box .user-box .item-box .info-item .right .mark-box .v-input__control {
  min-height: 10px !important;
}

::v-deep.action-box .info-box .user-box .item-box .info-item .right .mark-box .v-input__slot {
  padding: 0 !important;
}


::v-deep.action-box .info-box .user-box .item-box .info-item .right .mark-box .v-text-field__slot {
  margin: 0 !important;
}

::v-deep.action-box .info-box .user-box .item-box .info-item .right .mark-box textarea {
  margin-top: 0 !important;
  padding-right: 0 !important;
  font-size: 14px;
  color: #333d46;
}

::v-deep.action-box .info-box .user-box .item-box .info-item .right .mark-box textarea::placeholder {
  color: #7d8e9d !important;
  font-size: 14px !important;
}

.action-box .history-box {
  margin-top: 20px;
  overflow-y: auto;
  border-radius: 4px;
}

.action-box .history-box .history-item {
  padding: 21px 31px 21px 31px;
  margin-bottom: 10px;
}

.action-box .history-box .history-item .item-box .r {
  display: flex;
  align-items: center;
}

::v-deep.action-box .history-box .history-item .item-box .r .v-responsive__content {
  font-size: 12px !important;
  color: #fff !important;
  text-align: center !important;
  line-height: 1.8 !important;
}

.action-box .history-box .history-item .item-box .r .time {
  font-size: 12px;
  color: #8b9dad;
  margin-left: 7px;
  transform: translateY(1px);
}

.action-box .history-box .history-item .item-box .r .type {
  font-size: 12px;
  padding: 3px 5px 3px 5px;
  color: #006c9c;
  height: 18px;
}

.action-box .history-box .history-item .item-box .r .name {
  margin-left: 6px;
  font-size: 12px;
  color: #333d46;
}

.action-box .history-box .history-item .item-box .history {
  margin-top: 21px;
  font-size: 12px;
  color: #7d8e9d;
}

.action-box .history-box .history-item .item-box .history .msg-box {
  display: flex;
  flex-wrap: wrap;
  padding-left: 16px;
}

.action-box .history-box .history-item .item-box .history .msg-box li {
  margin-right: 30px;
  cursor: pointer;
}

/* 资料库 */

.action-box .info-box .search-box {
  display: flex;
  align-items: center;
  border: #dce3e9 solid 1px;
  border-radius: 8px !important;
  margin: 21px 21px 0 21px;
  padding: 6px;
}

::v-deep.action-box .info-box .search-box .v-input__control {
  min-height: 10px !important;
}

::v-deep.action-box .info-box .search-box input {
  padding: 0 !important;
}

::v-deep.action-box .info-box .search-box .v-select__selection {
  margin: 0 !important;
}

::v-deep.action-box .info-box .search-box .v-select__selection--comma {
  text-overflow: unset !important;
}

::v-deep.action-box .info-box .search-box .v-input__slot {
  padding: 0 11px !important;
}

.action-box .info-box .search-box .select-type {
  font-size: 14px;
  width: 126px;
}

::v-deep.action-box .info-box .search-box .select-type input::placeholder {
  color: #637381 !important;
  font-size: 14px !important;
}

.action-box .info-box .search-box .input-name {
  font-size: 14px;
}

::v-deep.action-box .info-box .search-box .input-name input::placeholder {
  color: #a1aebc !important;
  font-size: 14px !important;
}

.action-box .info-box .search-box .divider-v {
  margin-left: 1px;
  margin-right: 1px;
}

.action-box .info-box .list-box {
  height: 160px;
  margin-top: 13px;
  overflow-y: auto;
}

.action-box .info-box .list-box .title2 {
  font-size: 14px;
  color: #333d46;
}
</style>