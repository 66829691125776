<template>
    <v-container class="com-box" fluid>
        <v-row align="center">
            <v-dialog v-model="isShow" max-width="600">
                <v-card class="dialog-box">
                    <v-card-title class="title2">快捷回复</v-card-title>
                    <v-container fluid>
                        <v-row align="center">
                            <v-col class="input-text" cols="12" sm="10">
                                <v-text-field v-model="quickKeyword" @keyup.enter="onClickSearch" placeholder="搜索关键字" solo
                                              flat hide-details outlined dense></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="1">
                                <v-btn @click="onClickSearch" icon>
                                    <v-img max-width="22" max-height="22"
                                           :src="`${$globalData.static_url}/home-search.png`">
                                    </v-img>
                                </v-btn>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="1">
                                <v-btn @click="onClickAdd" icon>
                                    <v-icon color="#b2bbc5">mdi-plus-box-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="input-text" cols="12" sm="12">
                                <v-list class="list-box" v-scroll.self="onScrollQuick" width="100%">
                                    <v-list-item-group v-model="quickIndex" mandatory>
                                        <v-list-item class="msg-item" v-for="item in quickList" @dblclick="onClickEdit">
                                            <v-list-item-avatar v-if="item.type == 2" width="48" height="48">
                                                <v-img max-width="48" max-height="48" :src="item.html"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="msg-title">
                                                <v-list-item-title class="content"
                                                                   v-text="item.content"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions class="btn-box">
                        <v-btn color="green" @click="onClickBtn('ok')" text>确定</v-btn>
                        <v-btn color="orange" @click="onClickBtn('cancel')" text>取消</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <DialogDataEdit ref="DialogDataEdit"></DialogDataEdit>
    </v-container>
</template>
<style scoped>
.com-box {
    position: absolute;
}

.select-item {
    color: #212b36;
    font-size: 14px;
}

.dialog-box {
    padding: 0 31px;
}

.dialog-box .title2 {
    display: flex;
    justify-content: center;
    font-size: 16px !important;
    color: #212b36 !important;
}

.dialog-box .input-text {
    display: flex;
}

.dialog-box .list-box {
    height: 400px;
    overflow-y: auto;
}

.dialog-box .input-text .msg-item .hover-box {
    height: 100%;
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    position: relative;
}


.dialog-box .input-text .msg-item .msg-title .content {
    font-size: 14px !important;
    color: #333d46;
}

::v-deep.dialog-box .input-text .v-input {
    font-size: 14px !important;
    color: #212b36 !important;
}

::v-deep.dialog-box .input-text input::placeholder {
    color: #637381 !important;
    font-size: 14px !important;
}

.dialog-box .btn-box {
    display: flex;
    justify-content: center;
}
</style>
<script>
import DialogDataEdit from '../DialogDataEdit/DialogDataEdit'
import { getQuickList } from '../../global/api'
import { mapState } from 'vuex'
export default {
    name: 'DialogQuickList',
    components: {
        DialogDataEdit
    },
    data: () => ({
        quickPage: 1,
        quickTotal: 0,
        quickKeyword: '',
        quickList: [],
        quickIndex: -1,
        ext: {},
        callback: '',
        isShow: false
    }),
    computed: {
        ...mapState([
            'userInfo'
        ])
    },
    methods: {
        onScrollQuick (e) {
            console.log('onScrollQuick', e.target.scrollHeight, e.target.scrollTop, e.target.clientHeight, e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop)
            if (Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) == 0) {
                if (this.quickList.length < this.quickTotal) {
                    this.quickPage++
                    this._getQuickList()
                }
            }
        },
        onClickBtn (e) {
            if (e == 'ok') {
                this.callback && this.callback('ok', this.quickList[this.quickIndex])
            } else {
                this.callback && this.callback(e, this.ext)
            }
        },
        onClickSearch () {
            this.quickPage = 1
            this._getQuickList()
        },
        _getQuickList () {
            this.$loading.show({ content: '加载中' })
            getQuickList('', 'content', this.quickKeyword, this.quickPage, (ok) => {
                if (ok.code == 0) {
                    let data = ok.data.data
                    data.forEach((item) => {
                        if (item.type == 2) {
                            item['html'] = this.$globalData.file_url + item.pic
                        }

                        item.content = item.content.replaceAll('<*>', this.userInfo.name)
                    })
                    if (this.quickPage != 1) {
                        data.unshift(...this.quickList)
                    } else {
                        this.quickTotal = ok.data.total
                    }
                    this.quickList = data.sort((a, b) => {
                        return b.sequence - a.sequence
                    })
                    this.$loading.close()
                } else {
                    this.$loading.close()
                    this.$toast().show({ content: ok.msg })
                }
            }, (err) => {
                console.log(err)
                this.$loading.close()
                this.$toast().show({ content: '获取快捷回复列表失败,请稍后再试' })
            })
        },
        onClickAdd () {
            this.$refs.DialogDataEdit.show({
                type: 1,
                callback: (r, ext) => {
                    if (r == 'ok') {
                        this.quickList.unshift(ext)
                        this.$set(this.quickList, 0, ext)
                        this.onClickSearch()
                    }
                    this.$refs.DialogDataEdit.close()
                }
            })
        },
        onClickEdit () {
            this.$refs.DialogDataEdit.show({
                type: 1,
                ext: this.$_.cloneDeep(this.quickList[this.quickIndex]),
                callback: (r, ext) => {
                    if (r == 'ok') {
                        let quickIndex = this.quickList.findIndex((item) => {
                            return item.id == ext.id
                        })
                        this.$set(this.quickList, quickIndex, ext)
                        this.quickList = this.quickList.sort((a, b) => {
                            return b.sequence - a.sequence
                        })
                        this.onClickSearch()
                    }
                    this.$refs.DialogDataEdit.close()
                }
            })
        },
        show (opt) {
            opt = opt || {}
            this.callback = opt['callback'] || ''
            if (opt.hasOwnProperty('ext')) {
                this.ext = opt['ext']
            } else {
                this.ext = {}
            }
            this.onClickSearch()
            this.isShow = true
        },
        close () {
            this.isShow = false
            this.callback = ''
            this.ext = {}
            this.quickKeyword = ''
            this.quickTotal = 0
            this.quickPage = 1
            this.quickList = []
            this.quickIndex = 0
        }
    }
}
</script>
  