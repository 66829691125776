import Vue from 'vue';
import LoadingMaskVue from './Loading.vue';
import { createComponent } from '..';

let LoadingMaskConstructor = Vue.extend(LoadingMaskVue);
let LoadingMaskIndex = 999;
let LoadingMask = () => {
  return createComponent(LoadingMaskConstructor, 'LoadingMask' + LoadingMaskIndex, LoadingMaskIndex)._vm;
}

export default {
  install: Vue => {
    Vue.prototype.$loading = LoadingMask();
  }
}