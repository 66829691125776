<template>
  <v-container class="container-box" fluid>
    <v-card class="main-box" flat>
      <v-card-actions>
        <v-card-title>交班记录</v-card-title>
      </v-card-actions>
      <div class="body-box">
        <v-data-table :headers="headers" :items="handoverlist" :server-items-length="countAll"
                      @update:options="onUpdateObj"
                      fixed-header
                      :footer-props="{
                        'itemsPerPageOptions': [10, 15, 20, 30]
                      }">
          <template v-slot:top>
            <v-toolbar class="action-box" flat>
              <v-menu v-model="isShowStartDate" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="startDate" prepend-icon="mdi-calendar" placeholder="开始时间" v-bind="attrs"
                                v-on="on" hide-details readonly solo flat></v-text-field>
                </template>
                <v-date-picker v-model="startDate" @input="isShowStartDate = false" no-title></v-date-picker>
              </v-menu>
              <v-menu v-model="isShowEndDate" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="endDate" prepend-icon="mdi-calendar" placeholder="结束时间" v-bind="attrs" v-on="on"
                                hide-details readonly solo flat></v-text-field>
                </template>
                <v-date-picker v-model="endDate" @input="isShowEndDate = false" no-title></v-date-picker>
              </v-menu>
              <v-select class="keyword-select" :menu-props="{ offsetY: true }" v-model="uid" placeholder="请选择交班人"
                        :items="uidList" item-text="name" item-value="id" hide-details solo flat>
                <template v-slot:item="{ item }">
                  <div class="select-item">{{ item.name }}</div>
                </template>
              </v-select>
              <v-btn color="primary" @click="onClickSearch">查询</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.uid="{ item }">
            {{ item.name.name }}
          </template>
          <template v-slot:item.join_uid="{ item }">
            {{ item.join_name.name }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn @click="onClickHistory(item)" icon>
              <v-icon>mdi-comment-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <DialogSwap ref="DialogSwap"></DialogSwap>
  </v-container>
</template>

<script>
import DialogSwap from '@/components/DialogSwap/DialogSwap.vue'

import { handoverRecordList } from '../global/api'

export default {
  name: 'handover-record',
  components: {
    DialogSwap
  },
  data: () => ({
    headers: [
      {
        text: '交班时间',
        value: 'shift_time',
        sortable: false
      },
      {
        text: '交班人',
        value: 'uid',
        sortable: false
      },
      {
        text: '接班人',
        value: 'join_uid',
        sortable: false
      },
      {
        text: '本班接入会话数',
        value: 'meeting_num',
        sortable: false
      },
      {
        text: '本班超时回复会话数',
        value: 'timeout_num',
        sortable: false
      },
      {
        text: '本班会话评分',
        value: 'score',
        sortable: false
      },
      {
        text: '本班差评会话数',
        value: 'negative_num',
        sortable: false
      }, {
        text: '本班待完成跟踪数',
        value: 'stay_track_num',
        sortable: false
      }, {
        text: '本班新增跟踪数',
        value: 'new_track_num',
        sortable: false
      }, {
        text: '本班完成跟踪数',
        value: 'use_track_num',
        sortable: false
      }, {
        text: '操作',
        value: 'actions',
        sortable: false
      }
    ],
    startDate: '',
    endDate: '',
    isShowEndDate: false,
    isShowStartDate: false,
    uid: '',
    handoverlist: [],
    uidList: [],
    countAll: 0,
    page: 1,
    size: 20
  }),
  created () {
    let curDay = this.$moment().format('YYYY-MM-DD')
    this.startDate = curDay
    this.endDate = curDay
  },
  methods: {
    onClickHistory (item) {
      this.$refs.DialogSwap.show({ ...item, users: this.uidList })
    },
    onClickSearch () {
      this.page = 1
      this.getDataList()
    },
    getDataList () {
      let from = {
        'page': this.page,
        'size': this.size,
        'start_time': this.startDate,
        'end_time': this.endDate,
        'uid': this.uid
      }
      handoverRecordList(from, res => {
        if (res.code == 0) {
          this.handoverlist = res.data.data
          this.countAll = res.data.total
          this.uidList = [{ id: '', name: '全部' }, ...res.data.users]
        } else {
          this.$toast().show({ content: res.msg })
        }
      }, err => {
        console.log(err)
        this.$toast().show({ content: '获取交班记录失败' })
      })
    },
    onUpdateObj (e) {
      console.log('交班数据变动e', e)
      if (this.size != e.itemsPerPage) {
        this.page = 1
      } else {
        this.page = e.page
      }
      this.size = e.itemsPerPage

      this.getDataList()
    },

  }
}
</script>
<style scoped lang="scss">
::v-deep.v-card {
  border-radius: 14px !important;
}

.container-box {
  padding: 12px 50px 50px 50px;
  height: calc(100vh - 64px) !important;
  overflow-y: auto;
}

.select-item {
  font-size: 14px;
}

.main-box .body-box {
  padding-left: 26px;
  padding-right: 26px;
}

::v-deep.main-box .body-box .v-data-table__wrapper {
  max-height: calc(100vh - 64px - 64px - 16px - 64px - 59px - 64px) !important;
  overflow-y: auto !important;
}

::v-deep.main-box .body-box .action-box .v-input__prepend-outer {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

::v-deep.main-box .body-box .action-box .v-select__selection {
  margin: 0 !important;
}

::v-deep.main-box .body-box .action-box .v-select__selection--comma {
  color: #333d46;
  font-size: 14px !important;
  text-overflow: unset !important;
}

::v-deep.main-box .body-box .action-box .v-input {
  align-items: center !important;
  flex: unset !important;
}

::v-deep.main-box .body-box .action-box .v-input__control {
  min-height: 10px !important;
}

::v-deep.main-box .body-box .action-box .v-input__slot {
  padding: 0 11px !important;
}

::v-deep.main-box .body-box .action-box input {
  color: #333d46;
  padding: 0 !important;
  font-size: 14px !important;
}

::v-deep.main-box .body-box .action-box input::placeholder {
  color: #a1aebc !important;
  font-size: 14px !important;
}

.main-box .body-box .record-remark {
  width: 100px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  word-break: keep-all;
  white-space: nowrap;
}

.main-box .body-box .record-red {
  color: red;
}

.main-box .body-box .action-box .date-select {
  width: 146px;
}

.main-box .body-box .action-box .keyword-select {
  width: 146px;
}
</style>