import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userInfo: '',
    permission: '',
  },
  getters: {
  },
  mutations: {
    token(state, v) {
      state.token = v;
      localStorage.setItem('token', JSON.stringify(v));
    },
    userInfo(state, v) {
      state.userInfo = v;
      localStorage.setItem('userInfo', JSON.stringify(v));
    },
    permission(state, v) {
      state.permission = v;
      localStorage.setItem('permission', JSON.stringify(v));
    },
    
  },
  actions: {
  },
  modules: {
  }
})
