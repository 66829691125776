export function createComponent(obj, name, zIndex, finish) {
    let ins = new obj();
    ins._vm = ins.$mount();
    ins._dom = ins._vm.$el;
    if (zIndex) {
        ins._dom.style.zIndex = zIndex;
    }
    ins._name = name;
    ins._showNum = 0;
    console.log('创建', ins._name);
    ins._create = (callback) => {
        ins._showNum++;
        document.getElementById('app').appendChild(ins._dom);
        console.log('加载', ins._name, ins._showNum);
        ins._vm.$nextTick(() => {
            callback && callback();
            console.log('显示', ins._name);
        });
    }
    ins._destory = (callback) => {
        ins._showNum--;
        if (ins._showNum < 0) {
            ins._showNum = 0;
        }
        console.log('隐藏', ins._name, ins._showNum,ins._dom,ins._dom.parentNode);
        ins._vm.$nextTick(() => {
            
            if (ins._showNum <= 0 && ins._dom['parentNode']) {
                ins._dom.parentNode.removeChild(ins._dom);
                console.log('移除', ins._name);
                if (finish) {
                    ins.$destroy();
                    finish();
                }
            }
            callback && callback();
        });
    }
    return ins;
}