import axiosIns from '../global/request'
import store from '../store'

let api_url = process.env.VUE_APP_API_URL

export const SYS_VERSION_YX = 1
export const SYS_VERSION_TM = 2

export const SYS_VERSION_LIST = [
    { id: 0, name: '未知', bg: '#ffab00', text: '#fff' },
    { id: 1, name: '银杏', bg: '#cfeee1', text: '#007b55' },
    { id: 2, name: 'TM', bg: '#cfeee1', text: '#007b55' },
    { id: 3, name: '台桌', bg: '#cfeee1', text: '#007b55' },
    { id: 4, name: '酒店', bg: '#cfeee1', text: '#007b55' },
]


export const QA_TYPE_LIST = [
    { id: 0, name: '未知', content: '未', bg: '#ffab00' },
    { id: 1, name: '售前咨询', content: '前', bg: '#36b37e' },
    { id: 2, name: '售后咨询', content: '后', bg: '#00b8d9' },
    { id: 3, name: '需求反馈', content: '求', bg: '#ff00cc' },
    { id: 4, name: '投诉', content: '诉', bg: '#ff0000' }
]

export const MSG_TYPE_TEXT = 'text'
export const MSG_TYPE_IMAGE = 'image'
export const MSG_TYPE_VOICE = 'voice'
export const MSG_TYPE_VIDEO = 'video'
export const MSG_TYPE_FILE = 'file'
export const MSG_TYPE_MERGED = 'merged_msg'

export const MSG_TYPE_LIST = [
    { id: 1, name: '文本' },
    { id: 2, name: '图片' },
    { id: 3, name: '语音' },
    { id: 4, name: '视频' },
    { id: 5, name: '文件' }
]

export const SESSION_STATUS_WAIT = 0
export const SESSION_STATUS_IN = 1
export const SESSION_STATUS_END = 2

export const SESSION_STATUS_LIST = [
    { id: 0, name: '待接入' },
    { id: 1, name: '会话中' },
    { id: 2, name: '已结束' }
]

export function checkWinVisible () {
    if (document.hidden || document.visibilityState == 'hidden') {
        return false
    }
    return true
}

export function notifyMe (msg, err) {
    if (!("Notification" in window)) {
        console.log('No Notification')
    } else if (Notification.permission === "granted") {
        let notification = new Notification(msg)
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                let notification = new Notification(msg)
            }
        })
    } else {
        err && err()
    }
}


export function tranformBytes (bytes, minUnit = 'B') {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    let minUnitIndex = units.findIndex(value => value === minUnit)
    let index = 0
    for (index = minUnitIndex; index <= units.length; index++) {
        if (index === units.length || bytes < Math.pow(1024, index + 1)) {
            break
        }
    }
    return `${(bytes / Math.pow(1024, index)).toFixed(2)} ${units[index]}`
}

export function getUrlFromFile (file) {
    let URL = window.URL || window.webkitURL
    return URL.createObjectURL(file)
}


export async function getBase64FromFile (file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            resolve(e.target.result)
        }
    })
}


export function getFileFromBase64 (base64, fileName) {
    let arr = base64.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    let imageBlob = new Blob([u8arr], { type: mime })
    return new File([imageBlob], fileName, { type: imageBlob.type })
}


export async function getFileFromUrl (url, fileName) {
    if (!fileName) {
        fileName = new URL(url).pathname.split('/').pop()
    }
    return new Promise((resolve, reject) => {
        axiosIns.get(url,
            {
                responseType: 'blob'
            })
            .then((response) => {
                resolve(new File([response.data], fileName, { type: response.data.type }))
            })
            .catch((error) => {
                reject(error)
            })
    })
}




export function clearToken () {
    delete axiosIns.defaults.headers.common['Authorization']
    localStorage.clear()
    store.commit('token', '')
    store.commit('userInfo', '')
    store.commit('permission', '')
}

export function syncChain (ok, err) {
    axiosIns.get(api_url + '/backend/sync/chain')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getSet (ok, err) {
    axiosIns.get(api_url + '/backend/settings/modify')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}


export function getAnalyseResponse (start_ymd, end_ymd, ok, err) {
    axiosIns.get(api_url + '/backend/analyse/response', {
        params: {
            start_ymd: start_ymd,
            end_ymd: end_ymd
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}


export function getAnalyseComment (start_ymd, end_ymd, ok, err) {
    axiosIns.get(api_url + '/backend/analyse/comment', {
        params: {
            start_ymd: start_ymd,
            end_ymd: end_ymd
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getAnalyseMeeting (start_ymd, end_ymd, ok, err) {
    axiosIns.get(api_url + '/backend/analyse/meeting', {
        params: {
            start_ymd: start_ymd,
            end_ymd: end_ymd
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getAnalyse (ok, err) {
    axiosIns.get(api_url + '/backend/analyse')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getHistoryList (type, info, ok, err) {
    if (type == 'meeting') {
        axiosIns.get(api_url + '/backend/meeting/detail', {
            params: info
        })
            .then((response) => {
                ok && ok(response.data)
            })
            .catch((error) => {
                err && err(error)
            })
    } else {
        axiosIns.get(api_url + '/backend/dialogue/get-history', {
            params: info
        })
            .then((response) => {
                ok && ok(response.data)
            })
            .catch((error) => {
                err && err(error)
            })
    }
}

export function getFiveHistoryList (external_userid, ok, err) {
    axiosIns.get(api_url + '/backend/dialogue/history', {
        params: {
            external_userid: external_userid
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getReportList (start_time, end_time, version_id, tag_id, is_track, key_type, keyword, page, ok, err) {
    axiosIns.get(api_url + '/backend/report', {
        params: {
            start_time: start_time,
            end_time: end_time,
            version_id: version_id,
            tag_id: tag_id,
            is_track: is_track,
            key_type: key_type,
            keyword: keyword,
            page: page
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getUserList (time_type, start_time, end_time, version_id, key_type, keyword, page, ok, err) {
    axiosIns.get(api_url + '/backend/customer', {
        params: {
            time_type: time_type,
            start_time: start_time,
            end_time: end_time,
            version_id: version_id,
            key_type: key_type,
            keyword: keyword,
            page: page
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getUserInfo (external_userid, ok, err) {
    axiosIns.get(api_url + '/backend/customer/modify', {
        params: {
            external_userid: external_userid
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getWaitSessionList (ok, err) {
    axiosIns.get(api_url + '/backend/meeting/queue')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getCurSessionList (ok, err) {
    axiosIns.get(api_url + '/backend/meeting/personal')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getQuickList (type, key_type, keyword, page, ok, err) {
    axiosIns.get(api_url + '/backend/answer', {
        params: {
            type: type,
            key_type: key_type,
            keyword: keyword,
            page: page
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getArticleInfo (id, ok, err) {
    axiosIns.get(api_url + '/backend/article/modify', {
        params: {
            id: id,
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getArticleList (version, key_type, keyword, page, ok, err) {
    axiosIns.get(api_url + '/backend/article', {
        params: {
            version: version,
            key_type: key_type,
            keyword: keyword,
            page: page
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getTagList (type, ok, err) {
    if (type == 2) {
        ok && ok({
            code: 0,
            data: {
                tags: [...SYS_VERSION_LIST]
            }
        })
        return
    }
    axiosIns.get(api_url + '/backend/tag', {
        params: {
            type: type
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}


export function getBarList (chain_id, ok, err) {
    axiosIns.get(api_url + '/backend/user/chainlist', {
        params: {
            chain_id: chain_id
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getChainList (ok, err) {
    axiosIns.get(api_url + '/backend/tag/chain')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getPermissionList (ok, err) {
    axiosIns.get(api_url + '/backend/permission')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getRoleList (ok, err) {
    axiosIns.get(api_url + '/backend/role')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getServiceAccountList (ok, err) {
    axiosIns.get(api_url + '/backend/account')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getRoleInfo (id, ok, err) {
    axiosIns.get(api_url + '/backend/role/modify', {
        params: {
            id: id
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function getServiceList (is_online, ok, err) {
    axiosIns.get(api_url + '/backend/user', {
        params: {
            is_online: is_online
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function login (username, password, ok, err) {
    axiosIns.post(api_url + '/backend/passport/login', {
        account: username,
        password: password
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function checkToken (ok, err) {
    axiosIns.get(api_url + '/backend/user/check')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function logout (ok, err) {
    axiosIns.post(api_url + '/backend/user/logout')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function deleteArticle (id, ok, err) {
    axiosIns.post(api_url + '/backend/article/destroy', {
        id: id,
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function deleteRole (id, ok, err) {
    axiosIns.post(api_url + '/backend/role/destroy', {
        id: id,
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function deleteTag (id, ok, err) {
    axiosIns.post(api_url + '/backend/tag/destroy', {
        id: id,
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function deleteQuick (id, ok, err) {
    axiosIns.post(api_url + '/backend/answer/destroy', {
        id: id,
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function uploadQuickFile (path, ok, err) {
    axiosIns.post(api_url + '/backend/quick', {
        path: path
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function uploadFile (file, type, ok, err) {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('type', type)
    axiosIns.post(api_url + '/backend/upload', formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function uploadMedia (type, media, ok, err) {
    let formData = new FormData()
    formData.append('type', type)
    formData.append('media', media)
    axiosIns.post(api_url + '/backend/media', formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function updateDeliver (meeting_id, uid, ok, err) {
    axiosIns.post(api_url + '/backend/meeting/deliver', {
        meeting_id: meeting_id,
        uid: uid
    },
    )
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}


export function updateSessionReport (info, ok, err) {
    axiosIns.post(api_url + '/backend/dialogue/modify', info
    )
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}


export function updateUserInfo (info, ok, err) {
    axiosIns.post(api_url + '/backend/customer/modify', info
    )
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}


export function updateSet (id, auto_insert, repeat_bypass, welcome_msg, offline_msg, send_cmt_form, group, ok, err) {
    axiosIns.post(api_url + '/backend/settings/modify', {
        id: id,
        group,
        auto_insert: auto_insert,
        repeat_bypass: repeat_bypass,
        welcome_msg: welcome_msg,
        offline_msg: offline_msg,
        send_cmt_form: send_cmt_form
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function updateQuickInfo (id, type, pic, content, sequence, ok, err) {
    axiosIns.post(api_url + '/backend/answer/modify', {
        id: id,
        type: type,
        pic: pic,
        content: content,
        sequence: sequence
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function updateArticleInfo (id, sort, title, content, version_id, ok, err) {
    axiosIns.post(api_url + '/backend/article/modify', {
        id: id,
        sort: sort,
        title: title,
        content: content,
        version_id: version_id
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function updateTag (id, name, type, ok, err) {
    axiosIns.post(api_url + '/backend/tag/modify', {
        id: id,
        name: name,
        type: type,
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function updateRoleInfo (id, name, permissions, ok, err) {
    axiosIns.post(api_url + '/backend/role/modify', {
        id: id,
        name: name,
        permissions: permissions
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function updateServiceInfo (id, name, mobile, account, password, account_id, role_id, avatar, ok, err) {
    axiosIns.post(api_url + '/backend/user/modify', {
        id: id,
        name: name,
        mobile: mobile,
        account: account,
        password: password,
        account_id: account_id,
        role_id: role_id,
        avatar: avatar
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function updateServiceStatus (status, ok, err) {
    axiosIns.post(api_url + '/backend/profile/status', {
        is_online: status
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function updateServiceIsEnable (id, is_enable, ok, err) {
    axiosIns.post(api_url + '/backend/user/enable', {
        id: id,
        is_enable: is_enable
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

export function updateArticleIsEnable (id, is_enable, ok, err) {
    axiosIns.post(api_url + '/backend/article/enable', {
        id: id,
        is_enable: is_enable
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

//获取当前用户交班信息
export function swapData (ok, err) {
    axiosIns.get(api_url + '/backend/shift/store')
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}
//提交交班
export function swapDataSubmit (id, track_ids, ok, err) {
    axiosIns.post(api_url + '/backend/shift/store', {
        join_uid: id,
        track_ids: track_ids || ''
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

//交班详情
export function getItemSwapInfo (id, ok, err) {
    axiosIns.post(api_url + '/backend/shift/track', {
        shift_id: id
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}


//获取历史交班记录
export function handoverRecordList (params, ok, err) {
    axiosIns.get(api_url + '/backend/shift', { params })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}


//获取会话记录
export function getReportIngList (params, ok, err) {
    axiosIns.get(api_url + '/backend/dialogue/search', { params })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}

//获取会话记录
export function setMsgLock (params, ok, err) {
    axiosIns.post(api_url + '/backend/meeting/pause', {
        ...params
    })
        .then((response) => {
            ok && ok(response.data)
        })
        .catch((error) => {
            err && err(error)
        })
}