<template>
  <div>
    <v-app-bar color="transparent" flat app>
      <v-spacer></v-spacer>
      <v-avatar class="avatar-box" @click="showSetHead">
        <img :src="userInfo.avatar">
      </v-avatar>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div class="menu-item action-btn" v-bind="attrs" v-on="on">
            <v-img max-width="20" max-height="18" :src="`${$globalData.static_url}/${curStatus.icon}.png`"></v-img>
            <span>{{ curStatus.title }}</span>
          </div>
        </template>
        <v-list>
          <v-list-item class="action-item" v-for="item, index in actionList" @click="onClickMenu(index, item)">
            <v-list-item-icon class="mr-0">
              <v-img max-width="20" max-height="18" :src="`${$globalData.static_url}/${item.icon}.png`"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="title2" v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="menu-item" @click="openDialogSwap">
        <v-icon style="font-size: 20px;">mdi-account-convert</v-icon>
        <span>交班</span>
      </div>
      <div class="menu-item" @click="onClickLogout">
        <v-img max-width="20" max-height="18" :src="`${$globalData.static_url}/top-menu-item-exit.png`"></v-img>
        <span>注销</span>
      </div>
    </v-app-bar>
    <DialogOnlineService ref="DialogOnlineService"></DialogOnlineService>
    <DialogSwap ref="DialogSwap"></DialogSwap>
    <DialogHead ref="DialogHead"></DialogHead>
    
    <v-snackbar v-model="snackBar" :timeout="3000" right>
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackBar = false">
          关闭
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import DialogHead from '@/components/DialogHead/DialogHead'
import { mapState } from 'vuex'
import DialogOnlineService from '../components/DialogOnlineService/DialogOnlineService'
import { logout, updateServiceStatus, clearToken, checkToken, notifyMe, checkWinVisible } from '../global/api'
import DialogSwap from '@/components/DialogSwap/DialogSwap.vue'
import DialogWxMsg from '@/components/DialogWxMsg/DialogWxMsg'
export default {
  name: 'TopBar',
  components: {
    DialogOnlineService,DialogSwap,DialogHead,DialogWxMsg
  },
  data: () => ({
    snackBar: false,
    snackText: '',
    reTimer: 0,
    heartTimer: 0,
    curStatus: '',
    ding: '',
    du: '',
    actionList: [
      { status: 1, title: '在线', icon: 'top-menu-item-online' },
      { status: 0, title: '离线', icon: 'top-menu-item-offline' },
    ]
  }),
  computed: {
    ...mapState([
      'token',
      'userInfo'
    ])
  },
  beforeDestroy () {
    this.closeWS()
    if (this.ding) {
      this.ding.pause()
      this.ding.oncanplaythrough = null
    }
    if (this.du) {
      this.du.pause()
      this.du.oncanplaythrough = null
    }
  },
  mounted () {
    notifyMe('欢迎登录客服中心!', () => {
      // alert('浏览器桌面通知权限被禁止,您将无法接收到提醒通知')
    })
    this.initWS()
    this.curStatus = this.actionList.find((item) => item.status == this.userInfo.is_online)
    this.ding = new Audio(this.$globalData.static_url + '/ding.mp3')
    this.ding.oncanplaythrough = (event) => {
      this.playDing()
    }
    this.du = new Audio(this.$globalData.static_url + '/du.mp3')
    this.du.oncanplaythrough = (event) => {
      this.playDu()
    }
  },
  methods: {
    showSetHead(){
      this.$refs.DialogHead.show()
    },
    openDialogSwap(){
      this.$refs.DialogSwap.show()
    },
    playDing () {
      if (this.ding) {
        this.ding.play()
      }
    },
    playDu () {
      if (this.du) {
        this.du.play()
      }
    },
    initWS () {
      let url = `${this.$globalData.ws_url}?token=${this.token.access_token}`
      console.log('ws', url)
      this.$ws = new WebSocket(url)

      this.$bus.on(this, 'connection', (data, ext, status) => {
        this.$globalData.file_url = data.server_url
      })


      this.$bus.on(this, 'new_meeting', (data, ext, status) => {
        let path = this.$router.currentRoute.path
        console.log('new_meeting home', path, data)
        if (data['await']) {
          if (data.await.length > 0) {
            this.snackText = '请注意,有新的接入会话!'
            // this.snackBar = true;
            notifyMe(this.snackText)
          }
        }
        if (data['history']) {
          if (data.history.length > 0) {
            this.snackText = '请注意,有新的转交会话!'
            // this.snackBar = true;
            notifyMe(this.snackText)
          }
        }
        this.playDu()
      })

      this.$bus.on(this, 'logout', (data, ext, status) => {
        clearToken()
        this.$router.push({
          path: '/'
        })
      })

      this.$bus.on(this, 'new_msg', (data, ext, status) => {
        if (!data) {
          return
        }
        let path = this.$router.currentRoute.path
        console.log('new_msg home', path, data)
        if (data['serve_user_id'] == this.userInfo.id) {
          if (path != '/home' || !checkWinVisible()) {
            this.snackText = '请注意,有新的会话消息!'
            // this.snackBar = true;
            notifyMe(this.snackText)
          }
          this.playDing()
        }
      })


      this.$ws.onopen = (event) => {
        console.log("socket.onopen")
        this.$bus.on(this, '$ws_send', (type, data, ext) => {
          if (this.$ws) {
            let msg = {
              type: type
            }
            if (data) {
              msg['data'] = data
            }
            if (ext) {
              msg['ext'] = ext
            }
            console.log('$ws_send', msg)
            this.$ws.send(JSON.stringify(msg))
          } else {
            console.log('ws is null')
          }
        })
        this.$bus.emit('$onopen')
        this.$globalData['ws'] = 1
        this.heartTimer = setInterval(() => {
          this.$ws.send("p")
        }, 10000)
      }

      this.$ws.onmessage = (event) => {
        if (!event.data || event.data.length <= 1) {
          return
        }
        console.log("socket.onmessage", event.data)
        let data = JSON.parse(event.data)
        this.$bus.emit(data.type, data['data'], data['ext'], data['status'])
      }

      this.$ws.onclose = (event) => {
        this.$bus.emit('$onclose')
        this.$globalData['ws'] = 0
        console.log("socket.onclose", event)
        this.$bus.off(this, '$ws_send')
        clearInterval(this.heartTimer)
        this.heartTimer = 0
        this.reWS()
      }

      this.$ws.onerror = (error) => {
        console.log("socket.onerror", error)
        this.$bus.off(this, '$ws_send')
        clearInterval(this.heartTimer)
        this.heartTimer = 0
      }

    },
    closeWS () {
      this.$bus.off(this, 'new_meeting')
      this.$bus.off(this, 'new_msg')
      this.$bus.off(this, 'connection')
      this.$bus.off(this, '$ws_send')
      if (this.reTimer != 0) {
        clearTimeout(this.reTimer)
        this.reTimer = 0
      }
      if (this.heartTimer != 0) {
        clearInterval(this.heartTimer)
        this.heartTimer = 0
      }
      if (this.$ws) {
        this.$ws.onclose = null
        this.$ws.close()
        this.$ws = ''
      }
    },
    reWS () {
      console.log("socket.reconnect")
      this.closeWS()
      this.reTimer = setTimeout(() => {
        this.initWS()
      }, 15000)
    },
    onClickLogout () {
      this.$dialog().show({
        content: `是否确认注销?`, callback: (r) => {
          if (r == 'ok') {
            this.$loading.show({ content: '注销中' })
            logout((ok) => {
              if (ok.code == 0) {
                clearToken()
                this.$loading.close()
                this.$router.push({
                  path: '/'
                })
              } else {
                this.$loading.close()
                this.$toast().show({ content: ok.msg })
              }
            }, (err) => {
              console.log(err)
              this.$loading.close()
              this.$toast().show({ content: '注销失败,请稍后再试' })
            })
          }
          // this.$dialog().close()
        }
      })

    },
    onClickMenu (index, e) {
      if (e == undefined) {
        return
      }
      if (e.status == 0) {
        this.$refs.DialogOnlineService.show({
          callback: (r, ext) => {
            if (r == 'ok') {
              this.$loading.show()
              updateServiceStatus(e.status, (ok) => {
                if (ok.code == 0) {
                  this.curStatus = e
                  this.userInfo.is_online = 0
                  this.$store.commit('userInfo', this.userInfo)
                  this.$loading.close()
                } else {
                  this.$loading.close()
                  this.$toast().show({ content: ok.msg })
                }
              }, (err) => {
                console.log(err)
                this.$loading.close()
                this.$toast().show({ content: '修改状态失败,请稍后再试' })
              })
              this.$bus.emit('$deliver')
            }
            this.$refs.DialogOnlineService.close()
          }
        })
      } else {
        this.$loading.show()
        updateServiceStatus(e.status, (ok) => {
          if (ok.code == 0) {
            this.curStatus = e
            this.userInfo.is_online = 1
            this.$store.commit('userInfo', this.userInfo)
            this.$loading.close()
          } else {
            this.$loading.close()
            this.$toast().show({ content: ok.msg })
          }
        }, (err) => {
          console.log(err)
          this.$loading.close()
          this.$toast().show({ content: '修改状态失败,请稍后再试' })
        })
      }
    },
  }
}
</script>
<style scoped lang="scss">
.avatar-box {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.menu-item {
  display: flex;
  align-items: center;
  color: #333d46;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  &+.menu-item{
    margin-left: 15px;
  }
  span {
    margin-left: 5px;
  }

  .action-btn {
    margin-right: 30px;
  }

  .action-item {
    .title2 {
      color: #333d46;
      font-size: 12px !important;
    }
  }

}
</style>