<template>
    <v-container class="com-box" fluid>
        <v-row align="center">
            <v-dialog v-model="isShow" max-width="400" persistent>
                <v-card class="dialog-box">
                    <v-card-title class="title2">转交会话</v-card-title>
                    <v-container fluid>
                        <v-row align="center">
                            <v-col class="input-text" cols="12" sm="12">
                                <v-list width="100%">
                                    <v-list-item-group v-model="serviceIndex" mandatory>
                                        <v-list-item class="service-item" v-for="item in serviceList">
                                            <template v-slot:default="{ active }">
                                                <v-list-item-avatar width="48" height="48">
                                                    <v-img max-width="48" max-height="48"
                                                        :src="`${$globalData.static_url}/${item.avatar}.jpg`"></v-img>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="service-title">
                                                    <v-list-item-title class="name" v-text="item.name"></v-list-item-title>
                                                    <v-list-item-subtitle class="num"
                                                        v-text="`正在接待 ${item.meeting_num} 人`"></v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-checkbox :input-value="active"></v-checkbox>
                                                </v-list-item-action>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions class="btn-box">
                        <v-btn color="green" @click="onClickBtn('ok')" text>确定</v-btn>
                        <v-btn color="orange" @click="onClickBtn('cancel')" text>取消</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<style scoped>
.com-box {
    position: absolute;
}

.select-item {
    color: #212b36;
    font-size: 14px;
}

.dialog-box {
    padding: 0 31px;
}

.dialog-box .title2 {
    display: flex;
    justify-content: center;
    font-size: 16px !important;
    color: #212b36 !important;
}

.dialog-box .input-text {
    display: flex;
}

.dialog-box .input-text .service-item .service-title .name {
    margin-bottom: 4px;
    font-size: 14px !important;
    color: #333d46;
}


.dialog-box .input-text .service-item .service-title .num {
    font-size: 12px !important;
    color: #717f8c;
    margin-top: 4px;
}

::v-deep.dialog-box .input-text .v-input {
    font-size: 14px !important;
    color: #212b36 !important;
}

::v-deep.dialog-box .input-text input::placeholder {
    color: #637381 !important;
    font-size: 14px !important;
}

.dialog-box .btn-box {
    display: flex;
    justify-content: center;
}
</style>
<script>
import { mapState } from 'vuex'
import { getServiceList, updateDeliver } from '../../global/api'

export default {
    name: 'DialogOnlineService',
    data: () => ({
        serviceIndex: -1,
        serviceList: [],
        callback: '',
        ext: {},
        isShow: false
    }),
    computed: {
        ...mapState([
            'userInfo'
        ])
    },
    methods: {
        onClickBtn(e) {
            if (e == 'ok') {
                let service = this.serviceList[this.serviceIndex]
                if (service) {
                    this.$loading.show();
                    updateDeliver(this.ext['meeting_id'], service.id, (ok) => {
                        if (ok.code == 0) {
                            this.$loading.close();
                            this.callback && this.callback(e, this.ext);
                        } else {
                            this.$loading.close();
                            this.$toast().show({ content: ok.msg });
                        }
                    }, (err) => {
                        console.log(err);
                        this.$loading.close();
                        this.$toast().show({ content: '会话转交失败,请稍后再试' });
                    });
                } else {
                    this.callback && this.callback(e, this.ext);
                }
            } else {
                this.callback && this.callback(e, this.ext);
            }
        },
        show(opt) {
            opt = opt || {};
            this.callback = opt['callback'] || '';
            if (opt.hasOwnProperty('ext')) {
                this.ext = opt['ext'];
            } else {
                this.ext = {};
            }
            this.$loading.show({ content: '加载中' });
            getServiceList(1, (ok) => {
                if (ok.code == 0) {
                    let data = ok.data;
                    this.serviceList = data.filter(item => item.id != this.userInfo.id);
                    this.$loading.close();
                } else {
                    this.$loading.close();
                    this.$toast().show({ content: ok.msg });
                }
            }, (err) => {
                console.log(err);
                this.$loading.close();
                this.$toast().show({ content: '获取客服列表失败,请稍后再试' });
            });
            this.isShow = true;
        },
        close() {
            this.isShow = false;
            this.callback = '';
            this.ext = {};
            this.serviceList = [];
            this.serviceIndex = -1;
        }
    }
}
</script>
  