import { Quill } from 'vue-quill-editor';
const BlockEmbed = Quill.import('blots/block/embed');
import {
  tranformBytes
} from '../global/api'

// 视频
class VideoBlot extends BlockEmbed {
  static create(value) {
    let node = super.create(value);
    node.setAttribute('contenteditable', false);
    node.setAttribute('src', value.url || value);
    node.setAttribute('name', value.name);
    node.setAttribute('controls', true);
    node.setAttribute('controlsList', 'nodownload');
    node.setAttribute('id', 'VideoBlot');
    let divNode = document.createElement('span');
    divNode.setAttribute('contenteditable', false);
    divNode.setAttribute('style', 'width:50%;cursor:pointer;display:inline-block;');
    divNode.appendChild(node);
    // if (!value.url) {
    //   divNode.innerHTML = divNode.innerHTML.replace('<video', '<iframe class="ql-video" frameborder="0" allowfullscreen="true"').replace('</video', '</iframe')
    //   divNode.getElementsByTagName('iframe')[0].style = 'width:auto'
    // }
    return divNode;
  }
  static value(domNode) {
    const value = {
      url: '',
      name: '',
    };
    let dom = domNode.firstChild;
    if (dom.getAttribute('src')) {
      value.url = dom.getAttribute('src');
      value.name = dom.getAttribute('name');
    }
    return value;
  }
}
VideoBlot.blotName = 'video';
VideoBlot.tagName = 'video';
VideoBlot.className = 'quill-video';

// 图片
class ImgBlot extends BlockEmbed {
  static create(value) {
    let node = super.create(value);
    node.setAttribute('contenteditable', false);
    node.setAttribute('src', value.url || value);
    node.setAttribute('name', value.name);
    node.setAttribute('id', 'ImgBlot');
    let divNode = document.createElement('span');
    divNode.setAttribute('contenteditable', false);
    divNode.setAttribute('style', 'width:50%;cursor:pointer;display:inline-block;');
    divNode.appendChild(node);

    return divNode;
  }
  static value(domNode) {
    const value = {
      url: '',
      name: '',
    };
    let dom = domNode.firstChild;
    if (dom.getAttribute('src')) {
      value.url = dom.getAttribute('src');
      value.name = dom.getAttribute('name');
    }
    return value;
  }
}
ImgBlot.blotName = 'img';
ImgBlot.tagName = 'img';
ImgBlot.className = 'quill-img';

// 文件
class FileBlot extends BlockEmbed {
  static create(value) {
    let node = super.create(value);
    // console.log('FileBlotA',value);
    node.setAttribute('contenteditable', false);
    node.setAttribute('url', value.url || value);
    // if (process.env.NODE_ENV == "development") {
    //   node.setAttribute('src', process.env.VUE_APP_STATIC_URL + '/public/quill-file.png');
    // } else {
    node.setAttribute('src', process.env.VUE_APP_STATIC_URL + '/quill-file.png');
    // }
    node.setAttribute('name', value.name);
    node.setAttribute('id', 'FileBlot');
    node.setAttribute('style', 'width:270px;height:50px;background:#f0f0f0;');

    let divNode = document.createElement('span');
    divNode.setAttribute('contenteditable', false);
    divNode.setAttribute('style', 'width:270px;height:50px;position:relative;cursor:pointer;display:inline-block;');
    divNode.appendChild(node);
    let tempNode = document.createElement('span');
    tempNode.setAttribute('contenteditable', false);
    tempNode.setAttribute('style', 'font-size:14px;width:210px;height:25px;overflow:hidden;text-overflow:ellipsis;overflow-wrap:normal;word-break:keep-all;white-space:nowrap;color:#000;position:absolute;left:50px;top:10px;');
    tempNode.innerHTML = value.name;
    divNode.appendChild(tempNode);
    tempNode = document.createElement('span');
    tempNode.setAttribute('contenteditable', false);
    tempNode.setAttribute('style', 'font-size:12px;width:210px;height:25px;overflow:hidden;text-overflow:ellipsis;overflow-wrap:normal;word-break:keep-all;white-space:nowrap;color:#555;position:absolute;left:50px;top:30px;');
    tempNode.innerHTML = tranformBytes(value.file.size);
    divNode.appendChild(tempNode);

    return divNode;
  }
  static value(domNode) {
    // console.log('FileBlotB',domNode);
    const value = {
      url: '',
      name: '',
    };
    let dom = domNode.firstChild;
    if (dom.getAttribute('url')) {
      value.url = dom.getAttribute('url');
      value.name = dom.getAttribute('name');
    }
    return value;
  }
}
FileBlot.blotName = 'file';
FileBlot.tagName = 'img';
FileBlot.className = 'quill-file';

export default { VideoBlot, ImgBlot, FileBlot };