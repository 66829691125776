import Vue from 'vue'
import VueRouter from 'vue-router'
import TopBar from '../views/TopBar.vue'
import LeftBar from '../views/LeftBar.vue'
import EnterView from '../views/EnterView.vue'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import HandoverRecord from '@/views/HandoverRecord'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Enter',
    component: EnterView
    // component: () => import(/* webpackChunkName: "about" */ '../views/EnterView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
    // component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'Home',
    // component: HomeView
    components: {
      default: HomeView,
      leftBar: LeftBar,
      topBar: TopBar
    }
  },
  {
    path: '/record',
    name: 'Record',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/RecordView.vue'),
      leftBar: LeftBar,
      topBar: TopBar
    }
  },
  {
    path: '/handoverRecord',
    name: 'HandoverRecord',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/HandoverRecord.vue'),
      leftBar: LeftBar,
      topBar: TopBar
    }
  },
  {
    path: '/report',
    name: 'Report',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/ReportView.vue'),
      leftBar: LeftBar,
      topBar: TopBar
    }
  },
  {
    path: '/analysis',
    name: 'Analysis',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AnalysisView.vue'),
      leftBar: LeftBar,
      topBar: TopBar
    }
  },
  {
    path: '/manage',
    name: 'Manage',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/ManageView.vue'),
      leftBar: LeftBar,
      topBar: TopBar
    },
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "about" */ '../views/Manage/ManageMenuView.vue')
      },
      {
        path: 'service',
        component: () => import(/* webpackChunkName: "about" */ '../views/Manage/ServiceManagement.vue')
      },
      {
        path: 'role',
        component: () => import(/* webpackChunkName: "about" */ '../views/Manage/RoleManagement.vue')
      },
      {
        path: 'tag',
        component: () => import(/* webpackChunkName: "about" */ '../views/Manage/TagManagement.vue')
      },
      {
        path: 'data',
        component: () => import(/* webpackChunkName: "about" */ '../views/Manage/DataManagement.vue')
      },
      {
        path: 'set',
        component: () => import(/* webpackChunkName: "about" */ '../views/Manage/SetManagement.vue')
      }
    ],
  }
]

const router = new VueRouter({
  routes
})

export default router
